
import { Component, Prop, Vue } from "vue-property-decorator";
import { Clearing as clearing } from "@/models/RootObjects/Clearing";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import Auth from "@/store/modules/Auth";
import { io, Socket } from "socket.io-client";
import _ from "lodash";

interface ClearingSocketData {
  Bf: number;
  Brf: number;
  Cc: number;
  Cf: number;
  D: string;
  Df: number;
  Dp: number;
  Ff: number;
  Jf: number;
  Margin: number;
  Mc: number;
  Mf: number;
  Mtm: number;
  Nb: number;
  P: string;
  Pb: number;
  Pm: number;
  Tf: number;
  Wd: number;
  _F: number;
}

@Component
export default class ClearingTable extends Vue {
  /*  @Prop() readonly myTable!: clearing[]; */
  tableData: ClearingSocketData[] = [];
  expandedGroups: string[] = [];
  serverString = `${process.env.VUE_APP_SERVER_REALTIME}:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  socket: Socket | null = null;
  loading = false;
  numberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  headers = [
    {
      text: "Principle",
      align: "right",
      value: "P",
      groupable: true,
    },
    {
      text: "MTM",
      align: "right",
      value: "Mtm",
      groupable: false,
    },
    {
      text: "New Margin",
      align: "right",
      value: "Margin",
      groupable: false,
    },
    /*    {
      text: "Prev Margin",
      align: "right",
      value: "Pm",
      groupable: false,
    }, */
    {
      text: "Margin Call",
      align: "right",
      value: "Mc",
      groupable: false,
    },
    {
      text: "Total Fee",
      align: "right",
      value: "Bf",
      groupable: false,
    },
    /*   {
      text: "Deposits",
      align: "right",
      value: "Dp",
      groupable: false,
    }, */
    /*  {
      text: "Withdrawels",
      align: "right",
      value: "Wd",
      groupable: false,
    }, */
    /*  {
      text: "Prev Balance",
      align: "right",
      value: "Pb",
      groupable: false,
    }, */
    {
      text: "New Balance",
      align: "right",
      value: "Nb",
      groupable: false,
    },
  ];

  numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      //console.log("Mounted breakdown ");
      //console.log("AFTER SOCKET LISTEN MOUNTED");
      //const userRoleWs = new UserRoleWs();
      //const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
      // return Promise.reject(err);
      //});
      //this.userRole = userRoleResp;
      //console.log("User Roles : ", this.userRole);
      this.socketListen();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  socketListen(): void {
    if (this.getUserAuthResponse == null) {
      console.log("user auth null");
      return;
    }
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );
    this.subscribeSocketEvents();
    this.socket.connect();
  }

  fetchData() {
    if (this.socket) this.socket.emit("ClearingInit");
  }

  subscribeSocketEvents(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    console.log("Subscribe to on listen events");
    this.socket.on("ClearingInit", (message: ClearingSocketData[]) => {
      //this.updateData(message);
      //console.log("Message INIT data ", message);
      this.tableData = [];
      message.forEach((item: any) => this.tableData.push(item));
    });
    this.socket.on("ClearingUpdate", (message: ClearingSocketData[]) => {
      this.updateData(message);
      //console.log("Message Update data ", message);
    });
    this.socket.on("ready", () => {
      // console.log("Connecting clearing socket ");
      this.subscribeSocket();
    });
    this.socket.on("disconnect", (error) => {
      console.warn("clearing disconnect", error);
    });
    this.socket.on("connect_error", (err: Error) => {
      console.error("Error connecting socket.. ", err);
    });
  }

  subscribeSocket(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    //console.log("Subscribe Socket ");
    this.socket.emit("ClearingSubscribe");
    this.socket.emit("ClearingInit");
    //this.socket.emit("SubscribeGeneric", this.getUserAuthResponse.uniqueKey);
  }

  public updateData(data: ClearingSocketData[]): void {
    const arr = data;
    arr.forEach((row) => {
      var match = _.find(this.tableData, { P: row.P });
      if (match) {
        _.extend(match, row);
      } else {
        this.tableData.push(row);
      }
    });
  }
}
