import { Position } from "@/models/RootObjects/Position";
// import Dash from '@/store/modules/dash';
import { axiosInstance } from "@/plugins/axios";

export class PositionsWs {
  public async GetPositions(): Promise<Position[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/user/express/positions";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on positions", err);
      return Promise.reject(err);
    });
    const temp: Position[] = [];
    res.data.forEach((element: any) => {
      temp.push(new Position(element));
    });
    return Promise.resolve(temp);
  }
}
