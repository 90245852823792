
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserSubScriptionsWs } from "@/DataAccess/WebServices/AgriBase/UserSubscriptionsWs";

const us = new UserSubScriptionsWs();

@Component({})
export default class ClientPicker extends Vue {
  currentViewIsRetail = false;
  dealerClientCodes: any[] = [];
  retailClientCodes: any[] = [];
  loading = false;
  showClients = false;
  selectedAll = false;

  get getClientCodes() {
    if (this.currentViewIsRetail) {
      return this.retailClientCodes;
    } else {
      return this.dealerClientCodes;
    }
  }

  getSelectedIDs() {
    var ids: any[] = [];
    if (this.currentViewIsRetail == true) {
      this.retailClientCodes.forEach((item: any) => {
        if (item.selected) {
          ids.push(item.client);
        }
      });
    } else {
      this.dealerClientCodes.forEach((branch) => {
        if (branch.selected) {
          ids.push(branch.branch);
        }
        branch.allClients.forEach((client: any) => {
          if (client.selected) {
            ids.push(client.name);
          }
        });
      });
    }

    return ids;
  }

  selectAllOfBranch(item: any) {
    item.selected = true;

    item.allClients.forEach(
      (client: { selected: boolean }) => (client.selected = true)
    );

    this.selectedAll = true;
  }

  toggleAllBranches(item: any) {
    // Check if all clients are currently selected
    const allSelected = item.allClients.every(
      (client: { selected: boolean }) => client.selected
    );

    // Determine the new state based on whether all clients are currently selected
    const newState = !allSelected;

    // Set the branch's 'selected' property based on the new state
    item.selected = newState;

    // Update all clients to the new state
    item.allClients.forEach(
      (client: { selected: boolean }) => (client.selected = newState)
    );

    this.selectedAll = newState;
  }

  async toggleRetail() {
    //this.currentViewIsRetail = !this.currentViewIsRetail;
    if (this.currentViewIsRetail == true) {
      this.retailClientCodes.forEach((item) => {
        item.selected = true;
      });
    } else {
      this.dealerClientCodes.forEach((branch) =>
        this.selectAllOfBranch(branch)
      );
    }
    await this.acceptSelections();
  }

  /* async mounted(): Promise<void> {
    await this.loadCRMClients();
    await this.loadSubscriptions();
    // console.log("Currencies Resp ", dls);
  } */

  async loadCRMClients(): Promise<void> {
    try {
      this.loading = true;
      const resp = await us.GetCRMClients();
      console.log("CRM Clients ", resp);

      this.dealerClientCodes = resp.filteredClients
        .map((branch) => ({
          ...branch,
          selected: false,
          allClients: Array.from(
            new Set(
              branch.dealers.flatMap(
                (dealer) => dealer.clients.map((client) => client) // Map to client names first if they're unique identifiers
              )
            )
          )
            .map((client) => ({
              name: client, // Client name
              selected: false, // Checkbox property
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
          dealers: branch.dealers.map((dealer) => ({
            ...dealer,
            open: false, // For the dealer
            clients: dealer.clients
              .map((client) => ({
                name: client, // Assume clients are initially just a string array
                selected: false, // For the checkbox
              }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          })),
        }))
        .sort((a, b) => a.branch.localeCompare(b.branch));
      console.log("dealerClientCodes", this.dealerClientCodes);

      this.retailClientCodes = [];
      const clientCodeSet = new Set(this.retailClientCodes);
      resp.clientCodes.forEach((element) => {
        clientCodeSet.add({ client: String(element), selected: false });
      });
      this.retailClientCodes = Array.from(clientCodeSet).sort((a, b) =>
        a.client.localeCompare(b.client)
      );
      console.log("retailClientCodes", this.retailClientCodes);

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async loadSubscriptions() {
    try {
      this.loading = true;
      const response = await us.GetUserSubscribedClients();

      /* const countRetail = response.filter((code) =>
        this.retailClientCodes.includes(code)
      ).length; */
      let countRetail = 0;
      this.retailClientCodes.forEach((item) => {
        if (response.includes(item.client)) {
          countRetail++;
        }
      });
      let countDealer = 0;
      this.dealerClientCodes.forEach((branch) => {
        branch.allClients.forEach((client: any) => {
          if (response.includes(client.name)) {
            countDealer++;
          }
        });
      });

      this.currentViewIsRetail = countRetail > countDealer;
      if (this.currentViewIsRetail == true) {
        // false all dealer selecteds
        this.dealerClientCodes.forEach((branch, branchIndex) => {
          branch.allClients.forEach((client: any, clientIndex: number) => {
            Vue.set(this.dealerClientCodes[branchIndex], "selected", false);
            Vue.set(
              this.dealerClientCodes[branchIndex].allClients[clientIndex],
              "selected",
              false
            );
          });
        });
        if (response.length === 0) {
          //this.selected = this.retailClientCodes; // sub to all
          this.retailClientCodes.forEach((item) => {
            item.selected = true;
          });
          await this.acceptSelections();
        } else {
          this.retailClientCodes.forEach((item) => {
            if (response.includes(item.client)) {
              item.selected = true;
            }
          });
        }
      } else {
        this.dealerClientCodes.forEach((branch, branchIndex) => {
          branch.allClients.forEach((client: any, clientIndex: number) => {
            let isBranchSelected = response.includes(branch.branch);
            // Set branch as selected if its ID is in the response IDs
            Vue.set(
              this.dealerClientCodes[branchIndex],
              "selected",
              isBranchSelected
            );
            if (response.includes(client.name)) {
              // Using Vue.set to ensure reactivity
              Vue.set(
                this.dealerClientCodes[branchIndex].allClients[clientIndex],
                "selected",
                true
              );
            } else {
              Vue.set(
                this.dealerClientCodes[branchIndex].allClients[clientIndex],
                "selected",
                false
              );
            }
            if (
              branch.selected &&
              branch.allClients.every((client: any) => client.selected)
            ) {
              this.selectedAll = true;
            }
          });
        });
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async acceptSelections(close?: boolean): Promise<void> {
    try {
      this.loading = true;

      const resp = await us.SaveUserClients({ clients: this.getSelectedIDs() });
      this.$emit("selection-updated");
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
      if (close == true) {
        this.showClients = false;
      }
    }
  }

  @Watch("showClients", { immediate: true })
  async showClientsChanged() {
    if (this.showClients == true) {
      await this.loadCRMClients();
      await this.loadSubscriptions();
    }
  }
}
