import { UserRole } from "@/models/RootObjects/UserRole";
import { axiosInstance } from "@/plugins/axios";
import store from "@/store";

export class UserRoleWs {
  public async GetUserRoles(): Promise<UserRole[]> {
    const base = process.env["VUE_APP_SERVER_BASE"] + "/api/user/roles";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on user role", err);
      return Promise.reject(err);
    });
    console.log("Res for user role", res);
    // store.commit('User/setUserRoles', res.data);
    return Promise.resolve(res.data);
  }
}
