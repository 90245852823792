
import { Component, Prop, Vue } from "vue-property-decorator";
import { goToPage } from "@/scripts/navigation";

@Component({})
export default class AccountDeletionRequest extends Vue {
  method1 = [
    require("@/assets/account-deletion/DA_Method1_Step1.png"),
    require("@/assets/account-deletion/DA_Method1_Step2.png"),
    require("@/assets/account-deletion/DA_Method1_Step3.png"),
    // "@/assets/BVGGroup.png",
    // "@/assets/BVGGroup.png",
  ];
  model = 0;
  goBack() {
    goToPage("Login");
  }
  mounted() {
    console.log("Process ", process.env.BASE_URL);
  }
}
