import { AccountCreation } from "@/models/RootObjects/AccountCreation";
import { axiosInstance } from "@/plugins/axios";
import { AccountDetails } from "@/models/RootObjects/AccountDetails";

export class RegisterWs {
  public async RegisterAccount(
    firstName: string,
    lastName: string,
    cellNumber: string,
    email: string,
    password: string,
    confirm: string,
    organisation: string,
    occupation: string,
    province: string,
    existing_client: boolean
  ): Promise<AccountCreation> {
    const base = process.env["VUE_APP_SERVER_BASE"] + "/api/user/app/register";
    /* console.log(accountDetails); */
    const res = await axiosInstance
      .post(base, {
        firstName,
        lastName,
        cellNumber,
        email,
        password,
        confirm,
        organisation,
        occupation,
        province,
        existing_client,
      })
      .catch((err) => {
        console.log("Error on RegisterAccount", err);
        return Promise.reject(err);
      });
    console.log("Res for RegisterAccount", res);
    return Promise.resolve(res.data);
  }
}
