
import { Component, Prop, Vue } from "vue-property-decorator";
import { Breakdown as breakdown } from "@/models/RootObjects/Breakdown";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import Auth from "@/store/modules/Auth";
import { io, Socket } from "socket.io-client";
import _ from "lodash";
/* import { RealTimePrice as commodity } from "@/models/RootObjects/RealTimePricesData"; */

interface BreakDownSocketData {
  C: string;
  ContractDate: string;
  D: number;
  Dt: string;
  G: number;
  Instrument: string;
  M: number;
  P: string;
  T: number;
  V: number;
}

@Component
export default class BreakdownTable extends Vue {
  /* @Prop() readonly myTable!: breakdown[]; */
  /* @Prop() readonly lastTP!: commodity[]; */
  tableData: BreakDownSocketData[] = [];
  expandedGroups: string[] = [];
  serverString = `${process.env.VUE_APP_SERVER_REALTIME}:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  socket: Socket | null = null;
  loading = false;
  numberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  headers = [
    {
      text: "Principle",
      align: "right",
      value: "P",
      groupable: true,
    },
    {
      text: "Instrument",
      align: "start",
      value: "Instrument",
      groupable: false,
    },

    /* {
      text: "Last",
      align: "right",
      value: "LastTradePrice",
      groupable: true,
    }, */
    {
      text: "Contract Date",
      align: "right",
      value: "ContractDate",
      groupable: false,
    },
    {
      text: "Delta",
      align: "right",
      value: "D",
      groupable: false,
    },
    {
      text: "Gamma",
      align: "right",
      value: "G",
      groupable: false,
    },
    {
      text: "Theta",
      align: "right",
      value: "T",
      groupable: false,
    },
    {
      text: "MTM",
      align: "right",
      value: "M",
      groupable: false,
    },
  ];

  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }

  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      //console.log("Mounted breakdown ");
      // console.log("AFTER SOCKET LISTEN MOUNTED");
      //const userRoleWs = new UserRoleWs();
      //const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
      // return Promise.reject(err);
      //});
      //this.userRole = userRoleResp;
      //console.log("User Roles : ", this.userRole);
      this.socketListen();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  socketListen(): void {
    if (this.getUserAuthResponse == null) {
      console.error("user auth null");
      return;
    }
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );

    this.subscribeSocketEvents();
    this.socket.connect();
  }

  fetchData() {
    if (this.socket) this.socket.emit("BreakdownInit");
  }

  subscribeSocketEvents(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    //console.log("Subscribe to on listen events");
    this.socket.on("BreakdownInit", (message: BreakDownSocketData[]) => {
      //this.updateData(message);
      //console.log("Message INIT data ", message);
      this.tableData = [];
      message.forEach((item: any) => this.tableData.push(item));
    });
    this.socket.on("BreakdownUpdate", (message: BreakDownSocketData) => {
      this.updateData(message);
      //console.log("Message Update data ", message);
    });
    this.socket.on("ready", () => {
      console.log("Connecting breakdown socket ");
      this.subscribeSocket();
    });
    this.socket.on("disconnect", (error) => {
      console.warn("breakdown disconnect", error);
    });
    this.socket.on("connect_error", (err: Error) => {
      console.error("Error connecting socket.. ", err);
    });
  }

  subscribeSocket(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    // console.log("Subscribe Socket ");
    this.socket.emit("BreakdownSubscribe");
    this.socket.emit("BreakdownInit");
    //this.socket.emit("SubscribeGeneric", this.getUserAuthResponse.uniqueKey);
  }

  public updateData(data: BreakDownSocketData): void {
    // console.log("Updating ");
    //const newData = JSON.parse(data);
    const match = _.find(this.tableData, {
      Instrument: data.Instrument,
      ContractDate: data.ContractDate,
      P: data.P,
    });
    // console.log("Match ", match);
    if (match) {
      _.extend(match, data);
    } else {
      this.tableData.push(data);
    }
  }
}
