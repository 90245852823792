import Utils from "@/WebService/Rest/Utils";
import { axiosInstance } from "@/plugins/axios";
import { Notification } from "@/models/RootObjects/Notification";

const utils = new Utils();

export class NotificationWs {
  public async GetNotificationsForUser(): Promise<Notification[]> {
    const uri = "/api/notifications";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on GetNotificationsForUser", err);
      return Promise.reject(err);
    });
    console.log("Res for GetNotificationsForUser", res);
    return Promise.resolve(res.data);
  }

  public async RegisterNotificationTag(
    tag: string,
    os: string
  ): Promise<boolean> {
    const uri = utils.BuildEndPoint("/api/notification/create/tag", [
      { key: "tag", value: tag },
      { key: "os", value: os },
    ]);
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on RegisterNotificationTag", err);
      return Promise.reject(err);
    });
    console.log("Res for RegisterNotificationTag", res);
    return Promise.resolve(true);
  }
}
