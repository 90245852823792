
import { Component, Vue, PropSync, Prop } from "vue-property-decorator";
// import { RealTimePrice } from "@/models/RootObjects/RealTimePricesData";
import Graph from "@/components/CommodityPricing/Graph.vue";
import Depth from "@/components/CommodityPricing/Depth.vue";
import user from "@/store/modules/Auth";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { MarketData } from "./NewCommodityPricing.vue";

@Component({
  components: {
    Graph,
    Depth,
  },
})
export default class SelectedModalRow extends Vue {
  modalTab = "";
  @PropSync("open") openModal!: boolean;
  @Prop() activeModalItem!: { rtp: MarketData } | null;
  @Prop() closeMenu!: () => void;

  get getActiveModalItem(): { rtp: MarketData } | null {
    return this.activeModalItem;
  }

  get getUserAuth(): UserAuthentication | null {
    return user.getUserAuthResponse;
  }
  close() {
    this.closeMenu();
  }
}
