var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"Instrument","dense":"","disable-pagination":"","items-per-page":-1,"calculate-widths":"","mobile-breakpoint":"0","height":"70vh","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:'header-' + h.text,staticClass:"white--text primary lighten-1 py-4 text-left",class:h.value == 'P' ? 'text-left' : 'text-right'},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item.P",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-start text-caption"},[_vm._v(" "+_vm._s(item.P)+" ")])]}},{key:"item.Mtm",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-end text-caption",class:{
        pos: item.Mtm > 0 ? true : false,
        neg: item.Mtm < 0 ? true : false,
      }},[_vm._v(" R "+_vm._s(item.Mtm.toLocaleString("en-US", _vm.numberFormatOptions))+" ")])]}},{key:"item.Margin",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-end text-caption"},[_vm._v(" R "+_vm._s(item.Margin.toLocaleString("en-US", _vm.numberFormatOptions))+" ")])]}},{key:"item.Bf",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-end text-caption"},[_vm._v(" R "+_vm._s(item.Bf.toLocaleString("en-US", _vm.numberFormatOptions))+" ")])]}},{key:"item.Mc",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-end text-caption"},[_vm._v(" R "+_vm._s(item.Mc.toLocaleString("en-US", _vm.numberFormatOptions))+" ")])]}},{key:"item.Nb",fn:function({ item }){return [_c('td',{staticClass:"text-no-wrap text-end text-caption",class:{
        pos: item.Nb > 0 ? true : false,
        neg: item.Nb < 0 ? true : false,
      }},[_vm._v(" R "+_vm._s(item.Nb.toLocaleString("en-US", _vm.numberFormatOptions))+" ")])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }