
import { WeatherWs } from "@/DataAccess/WebServices/AgriBase/WeatherWs";
import { Component, Vue } from "vue-property-decorator";
import { Precipitation } from "@/models/RootObjects/Precipitation";
import precip from "@/components/Weather/Precipitation.vue";
import { DataTableHeader } from "vuetify";
import { UserRole } from "@/models/RootObjects/UserRole";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";
import { TemperatureData } from "@/models/RootObjects/TemperatureData";

const weather = new WeatherWs();

@Component
export default class TempDataTable extends Vue {
  loading = false;
  precipitation: Precipitation | null = null;
  provinceGroupTemp: {
    name: string;
    items: TemperatureData[];
  }[] = [];

  userRole: UserRole[] = [];
  headers: DataTableHeader[] = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Day1 Min",
      align: "start",
      value: "day_1_min",
    },
    {
      text: "Day1 Max",
      align: "start",
      value: "day_1_max",
    },
    {
      text: "Day2 Min",
      align: "start",
      value: "day_2_min",
    },
    {
      text: "Day2 Max",
      align: "start",
      value: "day_2_max",
    },
    {
      text: "Day3 Min",
      align: "start",
      value: "day_3_min",
    },
    {
      text: "Day3 Max",
      align: "start",
      value: "day_3_max",
    },
    {
      text: "Day4 Min",
      align: "start",
      value: "day_4_min",
    },
    {
      text: "Day4 Max",
      align: "start",
      value: "day_4_max",
    },

    {
      text: "Day5 Min",
      align: "start",
      value: "day_5_min",
    },
    {
      text: "Day5 Max",
      align: "start",
      value: "day_5_max",
    },

    {
      text: "Day6 Min",
      align: "start",
      value: "day_6_min",
    },
    {
      text: "Day6 Max",
      align: "start",
      value: "day_6_max",
    },
    {
      text: "Day7 Min",
      align: "start",
      value: "day_7_min",
    },
    {
      text: "Day7 Max",
      align: "start",
      value: "day_7_max",
    },
  ];
  expandedGroups: string[] = [];
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const precipitationResp = await weather
        .GetPrecipitation()
        .catch((err) => {
          return Promise.reject(err);
        });
      const userRoleWs = new UserRoleWs();
      const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
        return Promise.reject(err);
      });
      // User Roles
      this.userRole = userRoleResp;
      console.log("User Role Resp ", userRoleResp);
      // Precipitation
      this.precipitation = precipitationResp;
      console.log("Precipitation Resp ", precipitationResp);
    } catch (err) {
      console.log("Mounted Precipitation error ", err);
    } finally {
      this.loading = false;
    }
  }
  get getPrecipitation(): Precipitation | null {
    return this.precipitation;
  }
  get getFilteredDataTemp(): {
    name: string;
    items: TemperatureData[];
  }[] {
    this.getPrecipitation?.temperatureData.forEach((elem) => {
      const arr = this.provinceGroupTemp.find(
        (item) => item.name == elem.province
      );
      if (arr) {
        arr.items.push(elem);
      } else {
        this.provinceGroupTemp.push({
          name: elem.province,
          items: [elem],
        });
      }
    });
    return this.provinceGroupTemp;
  }

  get getUserRole(): UserRole[] {
    return this.userRole;
  }
  checkWeatherData(): boolean {
    return precip.checkWeatherData();
  }
  checkWeatherTier4(): boolean {
    return precip.checkWeatherTier4();
  }
  checkWeatherTier2(): boolean {
    return precip.checkWeatherTier2();
  }
  checkWeatherTier3(): boolean {
    return precip.checkWeatherTier3();
  }
}
