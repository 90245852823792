import { Contract } from "./Contract";

export class Position {
  constructor(pos: {
    bought: string;
    close: string;
    contractDate: string;
    date: string;
    deliveries: string;
    flag: string;
    instrument: string;
    member: string;
    open: string;
    phyPosition: string;
    principle: string;
    sold: string;
    strike: string;
  }) {
    this.bought = pos.bought;
    this.close = pos.close;
    this.contractDate = pos.contractDate;
    this.date = pos.date;
    this.deliveries = pos.deliveries;
    this.flag = pos.flag;
    this.instrument = pos.instrument;
    this.member = pos.member;
    this.open = pos.open;
    this.phyPosition = pos.phyPosition;
    this.principle = pos.principle;
    this.sold = pos.sold;
    this.strike = pos.strike;
  }

  public bought: string;
  public close: string;
  public contractDate: string;
  public date: string;
  public deliveries: string;
  public flag: string;
  public instrument: string;
  public member: string;
  public open: string;
  public phyPosition: string;
  public principle: string;
  public sold: string;
  public strike: string;
}
