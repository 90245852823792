import Utils from "@/WebService/Rest/Utils";
import { axiosInstance } from "@/plugins/axios";
import { Currency } from "@/models/RootObjects/Currency";

const utils = new Utils();
class CurrencyPrice {
  public price = 0.0;
  constructor(price: number) {
    this.price = price;
  }
}

export class CurrencyWs {
  public async GetCurrencyForUser(): Promise<Currency[]> {
    const uri = "/api/currency/realtime/prices";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on GetCurrencyForUser", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetCurrencyForUser", res);
    return res.data;
  }
  public async GetCurrencyPrice(currency: Currency): Promise<number> {
    const uri = utils.BuildEndPoint("/api/currency/realtime/price", [
      { key: "instrument", value: currency.code },
    ]);
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on GetCurrencyPrice", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetCurrencyPrice", res);
    return Promise.resolve(new CurrencyPrice(res.data).price);
  }
}
