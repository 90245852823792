export class OptionCalculatorRequest {
  constructor(
    futurePrice: number,
    strike: number,
    quantity: number,
    volatility: number,
    instrument: string,
    contract: string,
    type: string,
    instruction: string
  ) {
    this.futurePrice = futurePrice;
    this.strike = strike;
    this.quantity = quantity;
    this.volatility = volatility;
    this.instrument = instrument;
    this.contract = contract;
    this.type = type;
    this.instruction = instruction;
  }
  public futurePrice: number;
  public strike: number;
  public quantity: number;
  public volatility: number;
  public instrument: string;
  public contract: string;
  public type: string;
  public instruction: string;
}
