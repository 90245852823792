import { Clearing } from "@/models/RootObjects/Clearing";
import { axiosInstance } from "@/plugins/axios";

export class ClearingWs {
  public async GetClearingForUser(): Promise<Clearing[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/user/express/clearing";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on Clearing for user", err);
      return Promise.reject(err);
    });
    // console.log("Res for clearing for user", res);
    return Promise.resolve(res.data);
  }
}
