export class Report {
  constructor(res: {
    id: number;
    name: string;
    description: string;
    filename: null;
    content: string;
    url: string;
    category_id: number;
    createdby_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: null;
  }) {
    this.id = res.id;
    this.category_id = res.category_id;
    this.content = res.content;
    this.createdby_id = res.createdby_id;
    this.created_at = res.created_at;
    this.deleted_at = res.deleted_at;
    this.description = res.description;
    this.filename = res.filename;
    this.name = res.name;
    this.updated_at = res.updated_at;
    this.url = res.url;
  }
  public id: number;
  public name: string;
  public description: string;
  public filename: null;
  public content: string;
  public url: string;
  public category_id: number;
  public createdby_id: number;
  public created_at: string;
  public updated_at: string;
  public deleted_at: null;
}
export class ReportCategory {
  constructor(res: {
    created_at: string;
    description: string;
    displayUrl: string;
    id: number;
    marketreport: boolean;
    pivot: {
      group_id: string;
      category_id: string;
    };
  }) {
    this.created_at = res.created_at;
    this.description = res.description;
    this.displayUrl = res.displayUrl;
    this.id = res.id;
    this.marketreport = res.marketreport;
    this.pivot = res.pivot;
  }
  public created_at: string;
  public description: string;
  public displayUrl: string;
  public id: number;
  public marketreport: boolean;
  public pivot: {
    group_id: string;
    category_id: string;
  };
}

export class ReportGroup {
  constructor(res: {
    categories: ReportCategory[];
    children: [];
    created_at: string;
    deleted_at: string;
    name: string;
    parent_id: number;
    updated_at: string;
  }) {
    this.categories = res.categories;
    this.children = res.children;
    this.created_at = res.created_at;
    this.deleted_at = res.deleted_at;
    this.name = res.name;
    this.parent_id = res.parent_id;
    this.updated_at = res.updated_at;
  }
  public categories: ReportCategory[];
  public children: [];
  public created_at: string;
  public deleted_at: string;
  public name: string;
  public parent_id: number;
  public updated_at: string;
}
