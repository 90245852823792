
import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import Auth from "@/store/modules/Auth";
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";

@Component
export default class Home extends Vue {
  Dayjs = dayjs;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  slides = ["First", "Second", "Third"];
  get getLoginResponse(): OauthTokenResponseRoot | null {
    return Auth.getLoginResponse;
  }
}
