var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-iterator',{attrs:{"items":_vm.myTable,"disable-filtering":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(c){return _c('v-col',{key:c.principle,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"color clearingbg",attrs:{"elevation":"0","outlined":""}},[_c('v-row',{staticClass:"pl-3 pt-2 pb-2"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold text-no-wrap"},[_vm._v(" Principle: "+_vm._s(c.principle)+" ")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v("Margin")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v("M-Call")])]),_c('v-col',{staticClass:"pl-3 pt-10 text-left",attrs:{"cols":"4"}},[_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v(" R "+_vm._s(_vm.numberWithCommas(parseFloat(c.newMargin).toFixed(2)))+" ")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v(" R "+_vm._s(_vm.numberWithCommas(parseFloat(c.marginCall).toFixed(2)))+" ")])]),_c('v-col',{staticClass:"text-left pl-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"text-subtitle-1 text-no-wrap font-weight-bold"},[_vm._v("MTM")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v("Fees")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v("Balance")])]),_c('v-col',{staticClass:"text-left pl-0",attrs:{"cols":"4"}},[_c('span',{staticClass:"text-subtitle-1 text-no-wrap font-weight-bold",class:{
                  up: c.mtm > 0 ? true : false,
                  down: c.mtm < 0 ? true : false,
                }},[_vm._v(" R "+_vm._s(_vm.numberWithCommas( parseFloat(c.mtm).toFixed(2).toLocaleString() ))+" ")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap"},[_vm._v(" R "+_vm._s(_vm.numberWithCommas(parseFloat(c.totalFee).toFixed(2)))+" ")]),_c('br'),_c('span',{staticClass:"text-subtitle-2 text-no-wrap pl-0",class:{
                  up: c.newBalance > 0 ? true : false,
                  down: c.newBalance < 0 ? true : false,
                }},[_vm._v(" R "+_vm._s(_vm.numberWithCommas(parseFloat(c.newBalance).toFixed(2)))+" ")])])],1)],1)],1)}),1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }