import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import axios from "axios";
import Auth from "@/store/modules/Auth";

const axiosNoAuth = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
//only after signed in
const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const axiosInstanceNew = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const axiosInstanceITAC = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((restConfig) => {
  //get login credentials

  const newUser: OauthTokenResponseRoot | null = Auth.getLoginResponse;
  if (newUser == null) {
    console.log("No login response");
    return restConfig;
  }
  const config = restConfig;
  const loginRes = newUser.accessToken;
  if (config && config.headers && !config.headers.Authorization) {
    config.headers.Authorization = newUser.tokenType + " " + loginRes;
  }

  config.data = {
    client_secret: process.env.VUE_APP_CLIENT_SECRET,
    client_id: process.env.VUE_APP_CLIENT_ID,
    scope: process.env.VUE_APP_SCOPE,
    ...config.data,
  };
  return config;
});
axiosInstanceNew.interceptors.request.use((restConfig) => {
  //get login credentials

  const newUser: OauthTokenResponseRoot | null = Auth.getLoginResponse;
  if (newUser == null) {
    console.log("No login response");
    return restConfig;
  }
  const config = restConfig;
  const loginRes = newUser.accessToken;
  if (config && config.headers && !config.headers.Authorization) {
    config.headers.Authorization = newUser.tokenType + " " + loginRes;
  }
  return config;
});

axiosInstanceITAC.interceptors.request.use((restConfig) => {
  const newUser: OauthTokenResponseRoot | null = Auth.getLoginResponseITAC;
  if (newUser == null) {
    console.log("No login Itag response");
    return restConfig;
  }
  const config = restConfig;
  const loginRes = newUser.accessToken;
  if (config && config.headers && !config.headers.Authorization) {
    config.headers.Authorization = newUser.tokenType + " " + loginRes;
  }
  config.data = {
    client_secret: process.env.VUE_APP_CLIENT_SECRET_ITAC,
    client_id: process.env.VUE_APP_CLIENT_ID_ITAC,
    scope: process.env.VUE_APP_SCOPE,
    ...config.data,
  };
  return config;
});

export { axiosInstance, axiosInstanceITAC, axiosNoAuth, axiosInstanceNew };
