import Utils from "@/WebService/Rest/Utils";
import {
  RealTimePrice,
  PricesReceived,
} from "@/models/RootObjects/RealTimePricesData";
import { axiosInstance } from "@/plugins/axios";

const utils = new Utils();

export class CommodityPricingWs {
  public async GetRealTimePricesForUser(): Promise<RealTimePrice[]> {
    const base = process.env["VUE_APP_SERVER_BASE"] + "/api/realtimeprices";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on commodity price", err);
      return Promise.reject(err);
    });
    const temp: RealTimePrice[] = [];
    // console.log("Res for commodity price", res);
    res.data.forEach((element: PricesReceived) => {
      temp.push(new RealTimePrice(element));
    });
    return Promise.resolve(temp);
  }

  public async GetRealTimePrice(
    instrument: string,
    contract: string
  ): Promise<number> {
    const uri = utils.BuildEndPoint("/api/commodity/realtime/price", [
      { key: "instrument", value: instrument },
      { key: "contract", value: contract },
    ]);
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on commodity price instrument", err);
      return Promise.reject(err);
    });
    // console.log("Res for commodity price instrument", res);
    return Promise.resolve(res.data.price);
  }
}
