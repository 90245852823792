export class itacInstruments {
  constructor({
    ExpiryDATE,
    InstrumentCategory,
    InstrumentID,
    InstrumentType,
    MarketID,
    SecurityDescription,
    Symbol,
    UnderlyingInstrument,
  }: {
    ExpiryDATE: string;
    InstrumentCategory: string;
    InstrumentID: string;
    InstrumentType: string;
    MarketID: string;
    SecurityDescription: string;
    Symbol: string;
    UnderlyingInstrument: string;
  }) {
    this.expiryDATE = ExpiryDATE;
    this.instrumentCategory = InstrumentCategory;
    this.instrumentID = InstrumentID;
    this.instrumentType = InstrumentType;
    this.marketID = MarketID;
    this.securityDescription = SecurityDescription;
    this.symbol = Symbol;
    this.underlyingInstrument = UnderlyingInstrument;
  }
  public expiryDATE: string;
  public instrumentCategory: string;
  public instrumentID: string;
  public instrumentType: string;
  public marketID: string;
  public securityDescription: string;
  public symbol: string;
  public underlyingInstrument: string;
}

export class itacSocketResponse {
  constructor(res: {
    BestBidPrice: number;
    BestOfferPrice: number;
    CumulativeBestBidQty: number;
    CumulativeBestOfferQty: number;
    CumulativeMarketOrderBidQty: number;
    CumulativeMarketOrderOfferQty: number;
    DisplayBestBidPrice: number;
    DisplayBestOfferPrice: number;
    DisplayHigh: number;
    DisplayLastTradedPrice: number;
    DisplayLow: number;
    DisplayNotionalExposure: number;
    DisplayNotionalExposureDelta: number;
    DisplayOpenInterest: number;
    DisplayTurnover: number;
    DisplayVWAP: number;
    High: number;
    InstrumentID: number;
    LastTradedPrice: number;
    LastTradedTime: string;
    Low: number;
    NoOrders: boolean;
    NotionalExposure: number;
    NotionalExposureDelta: number;
    NumberOfTrades: number;
    OpenInterest: number;
    Turnover: number;
    VWAP: number;
    VolumeTraded: number;
  }) {
    this.bestBidPrice = res.BestBidPrice;
    this.bestOfferPrice = res.BestOfferPrice;
    this.cumulativeBestBidQty = res.CumulativeBestBidQty;
    this.cumulativeBestOfferQty = res.CumulativeBestOfferQty;
    this.cumulativeMarketOrderBidQty = res.CumulativeMarketOrderBidQty;
    this.cumulativeMarketOrderOfferQty = res.CumulativeMarketOrderOfferQty;
    this.displayBestBidPrice = res.DisplayBestBidPrice;
    this.displayBestOfferPrice = res.DisplayBestOfferPrice;
    this.displayHigh = res.DisplayHigh;
    this.displayLastTradedPrice = res.DisplayLastTradedPrice;
    this.displayLow = res.DisplayLow;
    this.displayNotionalExposure = res.DisplayNotionalExposure;
    this.displayNotionalExposureDelta = res.DisplayNotionalExposureDelta;
    this.displayOpenInterest = res.DisplayOpenInterest;
    this.displayTurnover = res.DisplayTurnover;
    this.displayVWAP = res.DisplayVWAP;
    this.high = res.High;
    this.instrumentID = res.InstrumentID;
    this.lastTradedPrice = res.LastTradedPrice;
    this.lastTradedTime = res.LastTradedTime;
    this.low = res.Low;
    this.noOrders = res.NoOrders;
    this.notionalExposure = res.NotionalExposure;
    this.notionalExposureDelta = res.NotionalExposureDelta;
    this.numberOfTrades = res.NumberOfTrades;
    this.openInterest = res.OpenInterest;
    this.turnover = res.Turnover;
    this.VWAP = res.VWAP;
    this.volumeTraded = res.VolumeTraded;
    // console.log("Created response: ", this.instrumentID);
  }
  public bestBidPrice: number;
  public bestOfferPrice: number;
  public cumulativeBestBidQty: number;
  public cumulativeBestOfferQty: number;
  public cumulativeMarketOrderBidQty: number;
  public cumulativeMarketOrderOfferQty: number;
  public displayBestBidPrice: number;
  public displayBestOfferPrice: number;
  public displayHigh: number;
  public displayLastTradedPrice: number;
  public displayLow: number;
  public displayNotionalExposure: number;
  public displayNotionalExposureDelta: number;
  public displayOpenInterest: number;
  public displayTurnover: number;
  public displayVWAP: number;
  public high: number;
  public instrumentID: number;
  public lastTradedPrice: number;
  public lastTradedTime: string;
  public low: number;
  public noOrders: boolean;
  public notionalExposure: number;
  public notionalExposureDelta: number;
  public numberOfTrades: number;
  public openInterest: number;
  public turnover: number;
  public VWAP: number;
  public volumeTraded: number;
}
export class combinedInstrument {
  public instrument: itacInstruments;
  public socket: itacSocketResponse | null;

  constructor({
    instrument,
    socket,
  }: {
    instrument: itacInstruments;
    socket: itacSocketResponse | null;
  }) {
    this.instrument = instrument;
    this.socket = socket;
  }
}
