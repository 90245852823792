
import _ from "lodash";
import io, { Socket } from "socket.io-client";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { Component, Vue, Watch, PropSync } from "vue-property-decorator";
import { CommodityPricingWs } from "@/DataAccess/WebServices/AgriBase/CommodityPricingWs";
import Pricing from "@/components/CommodityPricing/NewPricing.vue";
import PricingTab from "@/components/CommodityPricing/tabs/NewPricing.vue";
// import SettingsTab from "@/components/CommodityPricing/tabs/Settings.vue";

import MoreInfo from "@/components/CommodityPricing/MoreInfo.vue";
import Alerts from "@/components/CommodityPricing/Alerts.vue";

import {
  RealTimePrice as commodity,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  PricesReceived,
  RealTimePrice,
} from "@/models/RootObjects/RealTimePricesData";
import { UserAlert as alert } from "@/models/RootObjects/UserAlert";
// import { UserAlertsWs } from "@/DataAccess/WebServices/AgriBase/UserAlertsWs";
import Auth from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { DataTableHeader } from "vuetify";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";
import { UserRole } from "@/models/RootObjects/UserRole";
import { DefaultEventsMap } from "socket.io-client/build/typed-events";

// const cp = new CommodityPricingWs();
// const ua = new UserAlertsWs();
export interface MarketData {
  DisplaySeq: number;
  Contract: string;
  QtyBid: number;
  Bid: number;
  Offer: number;
  QtyOffer: number;
  Change: number;
  Time: string;
  Last: number;
  Hi: number;
  Lo: number;
  Volume: number;
  OpenPrice: number;
  ContractSeq: number;
  DateSeq: number;
  StrikeSeq: number;
  SecondContractSeq: number;
  SecondDateSeq: number;
  OpenInterest: number;
  Instrument: string;
  ContractDate: string;
  Strike: number;
  Flag: string;
  CONTRACT_TYPE: number;
  ContractDisplay: string;
}
// use components in lower case if you want. camelcase gets converted to snake case
@Component({
  components: {
    Pricing,
    MoreInfo,
    Alerts,
    PricingTab,
    // SettingsTab,
  },
})
export default class CommodityPricing extends Vue {
  //Pass data to Pricing, More info and Alerts in their props
  //Look at Express.vue
  //Get Data onMounted. Pricing and More Info should share the same data
  originalCommodities: MarketData[] = [];
  tab = "";
  chooseHeaders = false;

  // selectedHeaders: string[] = ["ContractDate"];
  selectedTableHeaders: DataTableHeader[] = [];

  // commodities: commodity[] = [];
  alerts: alert[] = [];
  serverString = `${process.env.VUE_APP_SERVER_REALTIME}:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  // serverString = `http://localhost:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;
  openModal = false;
  loadingCommodities = false;
  // get getHeaders(): string[] {
  //   return this.selectedHeaders;
  // }

  get getDeletedRealTimePrices(): commodity[] {
    return Dash.getDeletedRealTimePrices;
  }
  /*  readdSingleItem(name: string): void {
    Dash.removeSingleDeletedPricingGroup(name);
  } */

  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }
  get getSelectedHeaders(): DataTableHeader[] {
    return Dash.getCommodityHeaders;
  }
  updatedSubscribe() {
    console.error("Update succesful", this.socket);
    this.socket?.emit("updateSubscribe");
  }
  // deleteRow(e: commodity): void {
  //   console.log("Deleted Row in parent ", e);
  //   // add com to removedCommidity in Dash
  //   // remove com from actual table data
  //   Dash.addDeletedExpiryInstrument(e);
  // }
  // deleteGroup(e: string): void {
  //   console.log("Deleted Group in parent ", e);
  //   Dash.addDeletedPricingGroup(e);
  //   // this.commodities = this.commodities.filter(
  //   //   (val) => e != val.InstrumentName
  //   // );
  // }
  setSelectedHeaders(arr: DataTableHeader[]): void {
    Dash.setCommoditySelectedHeaders(arr);
  }

  closeChooseHeader(): void {
    this.chooseHeaders = false;
  }
  socketListen(): void {
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse?.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );
    this.socket.on("ready", () => {
      this.socket?.emit("SubscribeContract");
    });
    this.socket.on("GenericMarketUpdate", (msg: any) => {
      debugger;
      this.updateData(msg);
    });
    this.socket.on("RealTimeInitSubscribeData", (msg: MarketData[]) => {
      debugger;
      this.originalCommodities = msg;
    });
    this.socket.open();

    // this.socket.emit("subscribe", "TOB");
    // this.socket.emit("SubscribeGeneric", this.getUserAuthResponse?.uniqueKey);
    // setTimeout(() => {
    //   this.socket.close();
    // }, 1000);
  }

  updateData(data: MarketData): void {
    // console.log("Updating pricing socket  ");
    // const newData = JSON.parse(data);
    const newData = data;
    const match = _.find(this.originalCommodities, {
      Instrument: newData.Instrument,
      ContractDate: newData.ContractDate,
    });
    if (match) {
      // console.log("Difference : ", match, newData);
      enum direction {
        UP = "UP",
        DWN = "DOWN",
      }
      const key: {
        name: string;
        dir: direction;
      }[] = [];

      if (match.Last !== newData.Last) {
        // console.log("LastTradePrice diff", match.CustomID);
        if (match.Last < newData.Last) {
          key.push({
            name: "Last",
            dir: direction.UP,
          });
        } else {
          key.push({
            name: "Last",
            dir: direction.DWN,
          });
        }
      }
      if (match.Bid !== newData.Bid) {
        // console.log("LastBidPrice diff", match.CustomID);
        if (match.Bid < newData.Bid) {
          key.push({
            name: "Bid",
            dir: direction.UP,
          });
        } else {
          key.push({
            name: "Bid",
            dir: direction.DWN,
          });
        }
      }
      if (match.Offer !== newData.Offer) {
        // console.log("LastOfferPrice diff", match.CustomID);
        if (match.Offer < newData.Offer) {
          key.push({
            name: "Offer",
            dir: direction.UP,
          });
        } else {
          key.push({
            name: "Offer",
            dir: direction.DWN,
          });
        }
      }
      // console.log("Prices dont match: ", key);
      key.forEach((str) => {
        const cell = document.getElementById(match.Contract + "-" + str.name);
        if (str.dir == direction.UP) {
          cell?.classList.add("socketValueUpdateIncrease");
          setTimeout(() => {
            cell?.classList.remove("socketValueUpdateIncrease");
          }, 1000);
        } else {
          cell?.classList.add("socketValueUpdateDecrease");
          setTimeout(() => {
            cell?.classList.remove("socketValueUpdateDecrease");
          }, 1000);
        }
      });

      _.extend(match, newData);
    } else {
      this.originalCommodities.push(newData);
    }
    // console.log("Match ", dif);

    // } else {
    //   // const newItem = new commodity(newData);
    //   // this.commodities.push(newItem);
    //   // //should i be pushing???
    //   // console.error("UPDATING ITEM THAT DOESNT EXIST");
    // }
  }
  // difference(
  //   obj: Record<string, string | number>,
  //   base: Record<string, string | number>
  // ): Record<string, string | number> {
  //   return this.changes(obj, base);
  // }
  // changes(
  //   obj: Record<string, string | number>,
  //   base: Record<string, string | number>
  // ): Record<string, string | number> {
  //   return _.transform(
  //     obj,
  //     (
  //       result: Record<string, string | number>,
  //       value: number | string,
  //       key: string
  //     ) => {
  //       if (!_.isEqual(value, base[key])) {
  //         result[key] = value;
  //         // _.isObject(value) && _.isObject(base[key])
  //         //   ? this.changes(value, base[key])
  //         // : ;
  //       }
  //     }
  //   );
  // }

  // get getCommodities(): commodity[] {
  //   return this.commodities;
  // }
  get getOrgCommodities(): MarketData[] {
    return this.originalCommodities;
  }
  // get getFilteredCommodities(): commodity[] {
  //   return this.or
  // }

  @Watch("getSelectedHeaders", { immediate: true, deep: true })
  headersChanged(val: string[]): void {
    console.log("Changed headers ", val);
    this.selectedTableHeaders = [];
    for (let i = 0; i < this.getSelectedHeaders.length; i++) {
      this.selectedTableHeaders.push(this.getSelectedHeaders[i]);
    }
    this.selectedTableHeaders.push({
      text: "Actions",
      align: "center",
      value: "actions",
      groupable: false,
    });
    // if (this.selectedTableHeaders.length > 0) {
    //   this.selectedTableHeaders[0].align = "end";
    // }
  }
  get getTableHeaders(): DataTableHeader[] {
    const newArr: DataTableHeader[] = [];
    for (let i = 0; i < this.getSelectedHeaders.length; i++) {
      newArr.push(this.getSelectedHeaders[i]);
    }
    if (newArr.length > 0) {
      newArr[0].align = "start";
    }
    return newArr;
  }

  // @Watch("commodities", {
  //   immediate: true,
  //   deep: true
  // })
  // commoditiesChanged(newVal: string) {
  //   // console.log("New Val ", newVal);
  // }
  userRole: UserRole[] = [];

  async mounted(): Promise<void> {
    //Pure testing purposes
    const userRoleWs = new UserRoleWs();
    const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
      return Promise.reject();
    });
    this.userRole = userRoleResp;
    console.log("User Roles : ", this.userRole);

    this.loadingCommodities = true;
    // const cs = await cp.GetRealTimePricesForUser().catch((err) => {
    //   return Promise.reject(err);
    // });
    this.loadingCommodities = false;
    this.originalCommodities = [];

    this.socketListen();
    // const ur = await ua.GetUserAlerts();

    // this.alerts = ur;
    // console.log("User Alerts", ur);

    this.socket?.on("message", (message: string) => {
      const data = JSON.parse(message);
      // this.updateData(message);
      // console.log("Message data ", data );
    });
    this.socket?.on("connect", () => {
      console.log(
        "Commodity Pricing socket connect - ",
        this.getUserAuthResponse
      );
      // this.socket.emit("subscribe", "TOB");
      // this.socket.emit("SubscribeGeneric", this.getUserAuthResponse?.uniqueKey);
    });

    // const tempHeader = localStorage.getItem("selectedHeadersLS");
    // if (tempHeader != null) {
    //   const selectedHeadersLS = JSON.parse(tempHeader);
    //   this.selectedTableHeaders = selectedHeadersLS;
    //   console.log("SelectedHeadersLS :", selectedHeadersLS);
    // }
    // const tempGroup = localStorage.getItem("deletedGroupsLS");
    // if (tempGroup != null) {
    //   const deletedGroupsLS = JSON.parse(tempGroup);
    //   Dash.commodity.deletedGroups = deletedGroupsLS;
    //   console.log("DeletedGroupLS :", deletedGroupsLS);
    /* deletedGroupsLS.forEach((e: string) => {
        this.deleteGroup(e);
      }); */
    // }
    /* const tempRow = localStorage.getItem("deletedRowsLS");
    if (tempRow != null) {
      const deletedRowsLS = JSON.parse(tempRow);
      Dash.commodity.deletedRows = deletedRowsLS;
      console.log("DeletedRowLS :", deletedRowsLS);
      deletedRowsLS.forEach((e: commodity) => {
        this.deleteRow(e);
      });
    } */
  }

  // async created(): Promise<void> {
  //   window.addEventListener("beforeunload", this.commodityLS);
  // }

  // commodityLS(): void {
  //   localStorage.setItem(
  //     "selectedHeadersLS",
  //     JSON.stringify(this.selectedTableHeaders)
  //   );
  //   localStorage.setItem(
  //     "deletedGroupsLS",
  //     JSON.stringify(this.getDeletedGroups)
  //   );
  //   /* localStorage.setItem(
  //     "deletedRowsLS",
  //     JSON.stringify(this.getDeletedRealTimePrices)
  //   ); */
  // }

  // async beforeDestroy(): Promise<void> {
  //   localStorage.setItem(
  //     "selectedHeadersLS",
  //     JSON.stringify(this.selectedTableHeaders)
  //   );
  //   localStorage.setItem(
  //     "deletedGroupsLS",
  //     JSON.stringify(this.getDeletedGroups)
  //   );
  //   /* localStorage.setItem(
  //     "deletedRowsLS",
  //     JSON.stringify(this.getDeletedRealTimePrices)
  //   ); */
  // }
}
