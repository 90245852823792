import { axiosInstance } from "@/plugins/axios";
import { CommodityDepth } from "@/models/RootObjects/CommodityDepth";

class CommodityDepthRequest {
  public contract = "";
  public instrument = "";
}

export class CommodityDepthWs {
  public async GetCommodityDepth(
    contract: string,
    instrument: string
  ): Promise<CommodityDepth[]> {
    const commDepthRequest = new CommodityDepthRequest();
    commDepthRequest.contract = contract;
    commDepthRequest.instrument = instrument;

    const uri = "/api/commodity/realtime/depth";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance
      .post(base, {
        instrument: commDepthRequest.instrument,
        contract: commDepthRequest.contract,
      })
      .catch((err) => {
        console.log("Error on GetCommodityDepth", err.response);
        return Promise.reject(err);
      });
    // console.log("Res for GetCommodityDepth", res);
    return Promise.resolve(res.data);
  }
}
