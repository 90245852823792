
import { Component, Prop, Vue } from "vue-property-decorator";
import { Clearing as clearing } from "@/models/RootObjects/Clearing";

@Component
export default class ClearingTable extends Vue {
  @Prop() readonly myTable!: clearing[];
  numberWithCommas(x: string): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
