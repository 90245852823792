import dayjs from "dayjs";
export class OauthTokenResponseRoot {
  constructor(
    tokenType: string,
    expiresIn: number,
    accessToken: string,
    createdAt: number
  ) {
    this.tokenType = tokenType || "";
    this.expiresIn = expiresIn || 0;
    this.accessToken = accessToken || "";
    this.createdAt = createdAt;
  }

  //   dateExpires: Date;
  public createdAt: number;
  public tokenType: string;
  public expiresIn: number;
  public accessToken: string;

  public VerifyExpiryDate(): boolean {
    if (this != null) {
      if (this.accessToken.length !== 0) {
        const day = dayjs(this.createdAt);
        const test = day.valueOf() + this.expiresIn.valueOf() * 1000;
        if (dayjs(test).valueOf() > day.valueOf()) {
          console.log("Trust Token still valid");
          return true;
        }
      } else {
        console.log("No access token length on expiry check", this.accessToken);
      }
    } else {
      console.log("this == null on expiry check");
    }

    console.log("Trust Expired Token");
    return false;
  }
}
