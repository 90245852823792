import { UserAuthenticationWs } from "@/DataAccess/WebServices/AgriBase/UserAuthenticationWs";
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import axios from "axios";
import { axiosInstance } from "@/plugins/axios";
import dayjs from "dayjs";

const userAuthWs = new UserAuthenticationWs();

export const useLogin = () => {
  const grantType = "password";
  const scope = "*";
  // add these into config files for .envs
  const clientId = process.env.VUE_APP_CLIENT_ID;
  const clientIdITAC = process.env.VUE_APP_CLIENT_ID_ITAC;
  const clientSecret = process.env.VUE_APP_CLIENT_SECRET;
  const clientSecretITAC = process.env.VUE_APP_CLIENT_SECRET_ITAC;
  //
  const url = process.env.VUE_APP_SERVER_BASE + "/oauth/token";
  const urlITAC = process.env.VUE_APP_SERVER_ITAC + "/oauth/token";

  const userAuth = async (
    username: string,
    accessToken: string,
    itac = false
  ): Promise<UserAuthentication | null> => {
    try {
      const data = await userAuthWs
        .FindUser(username, accessToken, itac)
        .catch((err) => {
          return Promise.reject(err);
        });
      console.log("Data userauth ", data);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const login = async (
    username: string,
    password: string,
    rememberMe: boolean,
    itac = false
  ): Promise<OauthTokenResponseRoot | null> => {
    const body = {
      grant_type: grantType,
      scope,
      client_id: itac == false ? clientId : clientIdITAC,
      client_secret: itac == false ? clientSecret : clientSecretITAC,
      username,
      password,
      rememberMe,
    };
    const res = await axios
      .post(itac == false ? url : urlITAC, body)
      .catch((err) => {
        return Promise.reject(err);
      });
    const portalBVG = new OauthTokenResponseRoot(
      res.data.token_type,
      res.data.expires_in,
      res.data.access_token,
      dayjs().valueOf()
    );
    return Promise.resolve(portalBVG);
  };
  // api/entities/user/delete/{uniqueKey}
  const deleteAccount = async (): Promise<void> => {
    const base = process.env["VUE_APP_SERVER_BASE"] + "/api/user/delete";
    const res = await axiosInstance.get(base).catch((err) => {
      return Promise.reject(err);
    });
    console.log("Delete Res: ", res);
    return Promise.resolve();
  };
  return {
    deleteAccount,
    login,
    userAuth,
  };
};
