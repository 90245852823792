import { Data } from "./WeatherData";
import { Info } from "./Info";
import { WeatherStationData } from "./WeatherStationData";

export class Station {
  constructor(res: {
    Data: {
      humidity: any[];
      temperature: any[];
      radiation: any[];
      rainfall: any[];
      windDirection: any[];
      windSpeed: any[];
      pressure: any[];
    };
    active: number;
    canApi: boolean;
    canCompare: boolean;
    canExport: boolean;
    canHistory: boolean;
    canImage: boolean;
    canReport: boolean;
    canView: boolean;
    created_at: string;
    deleted_at: string;
    id: number;
    info: Info;
    isOwner: boolean;
    isUserSpecefic: boolean;
    station_id: string;
    uniqueKey: string;
    update_at: string;
  }) {
    this.data = new Data(res.Data);
    this.active = res.active;
    this.canApi = res.canApi;
    this.canCompare = res.canCompare;
    this.canExport = res.canExport;
    this.canHistory = res.canHistory;
    this.canImage = res.canImage;
    this.canReport = res.canReport;
    this.canView = res.canView;
    this.created_at = res.created_at;
    this.deleted_at = res.deleted_at;
    this.id = res.id;
    this.info = res.info;
    this.isOwner = res.isOwner;
    this.isUserSpecefic = res.isUserSpecefic;
    this.station_id = res.station_id;
    this.uniqueKey = res.uniqueKey;
    this.update_at = res.update_at;
  }

  public data: Data;
  public active: number;
  public canApi: boolean;
  public canCompare: boolean;
  public canExport: boolean;
  public canHistory: boolean;
  public canImage: boolean;
  public canReport: boolean;
  public canView: boolean;
  public created_at: string;
  public deleted_at: string;
  public id: number;
  public info: Info;
  public isOwner: boolean;
  public isUserSpecefic: boolean;
  public station_id: string;
  public uniqueKey: string;
  public update_at: string;
}
