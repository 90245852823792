export default class Utils {
  public BuildEndPoint(
    rawEndPoint: string,
    parameters: Record<string, string>[]
  ): string {
    let paramString = "";
    for (const item in parameters) {
      if (item == "0") {
        paramString = "?" + parameters[item].key + "=" + parameters[item].value;
      } else {
        paramString +=
          "&" + parameters[item].key + "=" + parameters[item].value;
      }
    }
    return rawEndPoint + paramString;
  }
}
