import { WeatherStationData } from "@/models/RootObjects/WeatherStationData";
import { Station } from "@/models/RootObjects/WeatherDataOverview";
import { WeatherDataInterval } from "@/models/RootObjects/Enums/WeatherDataInterval";
import { WeatherDataType } from "@/models/RootObjects/Enums/WeatherDataType";
import { axiosInstance } from "@/plugins/axios";
import { Precipitation } from "@/models/RootObjects/Precipitation";

export class WeatherWs {
  public async GetWeather(): Promise<Station[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/weather/stations/overview/abc";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on Weather", err);
      return Promise.reject(err);
    });
    const temp: Station[] = [];
    res.data.forEach((element: any) => {
      const newStation = new Station(element);
      if (newStation.info.type.id != 3) {
        temp.push(newStation);
      }
    });
    temp.sort((a, b) => {
      if (a.info.name.substr(0, 1) < b.info.name.substr(0, 1)) {
        return -1;
      } else if (a.info.name.substr(0, 1) > b.info.name.substr(0, 1)) {
        return 1;
      } else return 0;
    });
    console.log("Res for Weather", res);
    return Promise.resolve(temp);
  }

  public async GetPrecipitation(): Promise<Precipitation> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/weather/reports/precipitation";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on Precipitation", err);
      return Promise.reject(err);
    });
    console.log("Res for Precipitation", res);
    return Promise.resolve(res.data);
  }

  public async GetStationData(
    uniqueKey: string,
    weatherDataType: WeatherDataType,
    weatherDataInterval: WeatherDataInterval,
    startDate: Date,
    endDate: Date
  ): Promise<WeatherStationData[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] +
      "/api/weather/station/data/" +
      uniqueKey;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on station data", err);
      return Promise.reject(err);
    });
    console.log("Res for station data", res);
    return Promise.resolve(res.data);
  }
}
