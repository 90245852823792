var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"elevation-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-list',{staticClass:"reportsList",attrs:{"dense":""}},[_c('v-list-item-group',_vm._l((_vm.getCurrentReports),function(rep){return _c('v-list-item',{key:rep.id,on:{"click":function($event){return _vm.openPdf(rep)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(rep.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-body-2"},[_vm._v("Created: "+_vm._s(rep.created_at)+" ")])],1),_c('v-list-item-icon',{staticClass:"mr-1 my-auto"},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-download")])],1)],1)}),1)],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-toolbar',{staticClass:"white--text",attrs:{"color":"primary"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[(_vm.currentReport != null)?_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.currentReport.name)+" ")]):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.open = false}}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('VuePdfApp',{attrs:{"config":{
        secondaryToolbar: {
          secondaryDownload: false,
        },
        toolbar: {
          toolbarViewerRight: {
            download: false,
            print: false,
          },
        },
      },"pdf":_vm.pdfLink}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }