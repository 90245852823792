var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"ma-0 pa-0"},[(_vm.loading == false && _vm.checkRole == true)?[_c('v-data-table',{staticClass:"elevation-0 pt-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.tableData,"items-per-page":-1,"calculate-widths":"","mobile-breakpoint":"0","sort-by":"Sequence","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,staticClass:"white--text primary lighten-1 py-2 text-left",class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"item",fn:function({ item, headers }){return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:'item-' + header.value,staticClass:"text-caption",class:header.value == 'Indicator'
                ? 'text-left'
                : 'text-right text-no-wrap'},[(header.value == 'YesterdayClose')?_c('span',[(
                  item['Indicator'] == 'RAND / US $' ||
                  item['Indicator'] == 'EUR / US $' ||
                  item['Indicator'] == 'ZAR / EUR'
                )?_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(4))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")])]):(header.value == 'Live')?_c('span',[(
                  item['Indicator'] == 'RAND / US $' ||
                  item['Indicator'] == 'EUR / US $' ||
                  item['Indicator'] == 'ZAR / EUR'
                )?_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(4))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")])]):(header.value == 'Change')?_c('span',{class:{
                pos: item[header.value] > 0 ? true : false,
                neg: item[header.value] < 0 ? true : false,
              }},[(
                  item['Indicator'] == 'RAND / US $' ||
                  item['Indicator'] == 'EUR / US $' ||
                  item['Indicator'] == 'ZAR / EUR'
                )?_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(4))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]),(item[header.value] > 0)?_c('v-icon',{attrs:{"size":15,"color":"blue darken-4"}},[_vm._v("mdi-arrow-up-bold")]):(item[header.value] == 0)?_c('v-icon',{attrs:{"size":17}},[_vm._v("mdi-arrow-up-down-bold")]):_c('v-icon',{attrs:{"size":15,"color":"red darken-1"}},[_vm._v("mdi-arrow-down-bold")])],1):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)]}}],null,false,770852713)})]:(_vm.loading == true && _vm.checkRole == false)?[_c('p',{staticClass:"pa-5 text-body-2 textDMode--text"},[_vm._v("Checking Roles...")])]:[_c('p',{staticClass:"pa-5 text-body-2 textDMode--text"},[_vm._v(" It seems you do not have rights to view this page. Please contact BVG: 012 484 4000 ")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }