
import { Component, Vue, PropSync } from "vue-property-decorator";
import { ResetPasswordWs } from "@/DataAccess/WebServices/AgriBase/ResetPasswordWs";
const resetPasswordWs = new ResetPasswordWs();
@Component
export default class ResetPassModal extends Vue {
  @PropSync("open")
  openModalReset!: boolean;
  valid = false;
  rPassword = {
    email: "",
  };
  loading = false;
  async resetPassword(): Promise<void> {
    try {
      this.loading = true;
      const resetPassWordResp = await resetPasswordWs.ResetPassword(
        this.rPassword.email
      );
      this.openModalReset = false;
      if (resetPassWordResp == null) {
        return Promise.reject("HANDLE ResetPassword BETTER");
      }
    } catch (err) {
      console.log("ResetPassword CATCH: ", err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  closeReset(): void {
    this.openModalReset = false;
    this.rPassword.email = "";
  }
}
