import {
  itacFourEyesState,
  itacUnmatched,
} from "@/models/RootObjects/ItacUnmatched";
import { axiosInstanceITAC } from "@/plugins/axios";

export class ItacUnmatchedWs {
  public async GetItacUnmatched(date: string): Promise<itacUnmatched[]> {
    const uri = "/api/rtc/unmatched?date=" + date;
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacCommissions", err);
      return Promise.reject(err);
    });
    const arr: itacUnmatched[] = [];
    res.data.forEach((element: any) => {
      arr.push(new itacUnmatched(element));
    });
    console.log("Res for GetItacUnmatched", res.data);
    return Promise.resolve(arr);
  }
  public async GetItacFourEyesState(): Promise<itacFourEyesState[]> {
    const uri = "/api/rtc/unmatched/fourEyesStates";
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacCommissions", err);
      return Promise.reject(err);
    });
    const arr: itacFourEyesState[] = [];
    res.data.forEach((element: any) => {
      arr.push(new itacFourEyesState(element));
    });
    console.log("Res for GetItacStates", res.data);
    return Promise.resolve(arr);
  }
}
