interface PricesReceived {
  Change: number;
  Bid: number;
  ContractDate: string;
  ExpiryDate: string;
  High: number;
  Low: number;
  Last: number;
  InstrumentName: string;
  Movement: number;
  Offer: number;
  OpenInterest: number;
  OpenPrice: number;
  Volume: number;
}

class RealTimePrice {
  constructor({
    InstrumentName,
    Change,
    Bid,
    ContractDate,
    Volume,
    High,
    Low,
    Last,
    Movement,
    Offer,
    OpenInterest,
    OpenPrice,
  }: PricesReceived) {
    this.Change = Change;
    this.ContractDate = ContractDate;
    this.HighPrice = High;
    this.InstrumentName = InstrumentName;
    this.LastBidPrice = Bid;
    this.LastTradePrice = Last;
    this.LowPrice = Low;
    this.Movement = Movement;
    this.Volume = Volume;
    this.OpenPrice = OpenPrice;
    this.LastOfferPrice = Offer;
    this.CustomID = InstrumentName + "-" + ContractDate;
  }
  public CustomID: string;
  public LastBidPrice: number;
  public ContractDate: string;
  public HighPrice: number;
  public InstrumentName: string;
  public LastTradePrice: number;
  public LowPrice: number;
  public LastOfferPrice: number;
  public OpenPrice: number;
  public Volume: number;
  public Movement: number;
  public Change: number;

  toObject() {
    return {
      CustomID: this.CustomID,
      LastBidPrice: this.LastBidPrice,
      ContractDate: this.ContractDate,
      HighPrice: this.HighPrice,
      InstrumentName: this.InstrumentName,
      LastTradePrice: this.LastTradePrice,
      LowPrice: this.LowPrice,
      LastOfferPrice: this.LastOfferPrice,
      OpenPrice: this.OpenPrice,
      Volume: this.Volume,
      Movement: this.Movement,
      Change: this.Change,
    } as Record<string, string | number>;
  }
}

export { RealTimePrice, PricesReceived };
