
import { Component, Prop, Vue } from "vue-property-decorator";
import { Breakdown as breakdown } from "@/models/RootObjects/Breakdown";
/* import { RealTimePrice as commodity } from "@/models/RootObjects/RealTimePricesData"; */

@Component
export default class BreakdownTable extends Vue {
  @Prop() readonly myTable!: breakdown[];
  /* @Prop() readonly lastTP!: commodity[]; */
  expandedGroups: string[] = [];
  headers = [
    {
      text: "Instrument",
      align: "start",
      value: "instrument",
      groupable: false,
    },
    {
      text: "Principle",
      align: "right",
      value: "principle",
      groupable: true,
    },
    /* {
      text: "Last",
      align: "right",
      value: "LastTradePrice",
      groupable: true,
    }, */
    {
      text: "Expiry",
      align: "right",
      value: "contractDate",
      groupable: false,
    },
    {
      text: "Delta",
      align: "right",
      value: "delta",
      groupable: false,
    },
    {
      text: "MTM",
      align: "right",
      value: "mtm",
      groupable: false,
    },
  ];
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
}
