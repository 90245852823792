import { axiosInstance } from "@/plugins/axios";
import { MtmRootObject } from "@/models/RootObjects/MtmRootObject";
export class MtmClosingPricesWs {
  public async GetMTMClosingPrices(
    instruments: string[]
  ): Promise<MtmRootObject[]> {
    if (instruments.length == 0) {
      //return new List<MtmRootObject>();
    }
    // const formatYmd = (d: Date) => d.toISOString().slice(0, 10);

    // const fromDate = formatYmd(date);

    const uri = "/api/marketdata/commodity/closing";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    // const mtmPostObject = new MtmPostObject(instruments, date);
    const mtmPostObject = {
      instruments,
    };
    const res = await axiosInstance.post(base, mtmPostObject).catch((err) => {
      console.log("Error on GetMTMClosingPrices", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetMTMClosingPrices", res);
    return Promise.resolve(res.data);
  }

  public async GetMTMClosingPricesByDate(
    instruments: string[],
    date: string
  ): Promise<MtmRootObject[]> {
    if (instruments.length == 0) {
      //return new List<MtmRootObject>();
    }
    const uri = "/api/marketdata/commodity/closing/date?";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const mtmPostObject = {
      instruments,
      date,
    };
    const res = await axiosInstance.post(base, mtmPostObject).catch((err) => {
      console.log("Error on GetMTMClosingPricesByDate", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetMTMClosingPricesByDate", res);
    return Promise.resolve(res.data);
  }
}

// class MtmPostObject {
//   constructor(instruments: string[], date: Date) {
//     this.instruments = instruments;
//     this.date = date;
//   }
//   public instruments: string[];
//   public date: Date;
// }
