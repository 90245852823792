import { itacInstruments } from "@/models/RootObjects/ItacInstruments";
import { axiosInstanceITAC, axiosInstance } from "@/plugins/axios";

export class ItacInstrumentsWs {
  async GetItacSearchedInstrument(val: string): Promise<itacInstruments[]> {
    const uri = "/api/idp/instruments/search?search=" + val;
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacSearchedInstruments", err);
      return Promise.reject(err);
    });
    const arr: itacInstruments[] = [];
    res.data.forEach((element: any) => {
      arr.push(new itacInstruments(element));
    });
    console.log("Res for Get searched", res);
    return Promise.resolve(arr);
  }
}
