
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { MtmClosingPricesWs } from "@/DataAccess/WebServices/AgriBase/MtmClosingPricesWs";
import { MtmRootObject } from "@/models/RootObjects/MtmRootObject";
const mtmWs = new MtmClosingPricesWs();

@Component
export default class MTM extends Vue {
  loading = false;
  mtmDateMenu = false;
  mtmDate = this.getTodaysDate();
  expandedGroups: string[] = [];
  items: MtmRootObject[] = [];
  instrumentList = ["WMAZ", "YMAZ", "WEAT", "SUNS", "SOYA", "CORN"];
  selectedInstruments: string[] = [];
  myHeaders: DataTableHeader[] = [
    {
      value: "contract_date",
      text: "Contract Date",
      align: "start",
    },
    {
      value: "close",
      text: "Close",
      align: "start",
    },
    {
      value: "vol",
      text: "Vol",
      align: "start",
    },
    {
      value: "last_update",
      text: "Last Updated",
      align: "start",
    },
  ];

  getTodaysDate(): string {
    var date = new Date();
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  async loadMTM(): Promise<void> {
    try {
      this.loading = true;
      const resByDate = await mtmWs
        .GetMTMClosingPricesByDate(this.selectedInstruments, this.mtmDate)
        .catch((err) => Promise.reject(err));
      const res = await mtmWs
        .GetMTMClosingPrices(this.selectedInstruments)
        .catch((err) => Promise.reject(err));

      // const data = res;
      // const divided: {
      //   name: string;
      //   items: MtmRootObject[];
      // }[] = [];
      // res.forEach(e => {
      //   const found = divided.findIndex(a => e.instrument == a.name);
      //   if (found == -1) {
      //     divided.push({
      //       name: e.instrument,
      //       items: [e],
      //     })
      //   } else {
      //     divided[found].items.push(e);
      //   }
      // });
      if (resByDate.length == 0) {
        this.items = res;
      } else {
        this.items = resByDate;
      }

      console.log("Response: ", res);
    } catch (err) {
      console.log("Error: ", err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
