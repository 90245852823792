import { Deal } from "@/models/RootObjects/Deal";
import { axiosInstance } from "@/plugins/axios";

export class DealsWs {
  public async GetDealsForUser(): Promise<Deal[]> {
    const uri = "/api/user/express/deals";
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on GetDealsForUser", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetDealsForUser", res);
    return Promise.resolve(res.data);
  }
}
