import {
  itacOffBookTrades,
  offbookApiResponse,
} from "@/models/RootObjects/ItacOffBookTrades";
import { axiosInstanceITAC } from "@/plugins/axios";

export class ItacOffBookTradesWs {
  async GetItacOffBookTrades(
    startDate: string,
    endDate: string
  ): Promise<itacOffBookTrades[]> {
    const uri =
      "/api/rtc/offbooktrades?startDate=" + startDate + "&endDate=" + endDate;
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacDeals", err);
      return Promise.reject(err);
    });
    const arr: itacOffBookTrades[] = [];
    res.data.forEach((element: any) => {
      const data = new offbookApiResponse(element);
      arr.push(new itacOffBookTrades(data, null));
    });
    console.log("Res original offbooktrades", res.data);
    console.log("Res for OffBookTrades", arr);
    return Promise.resolve(arr);
  }
}
