import { itacDeals, dealReason } from "@/models/RootObjects/ItacDeals";
import { axiosInstanceITAC, axiosInstance } from "@/plugins/axios";

export class ItacDealsWs {
  async GetItacDeals(startDate: string, endDate: string): Promise<itacDeals[]> {
    const uri = "/api/rtc/deals?startDate=" + startDate + "&endDate=" + endDate;
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacDeals", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetItacDeals", res);
    return Promise.resolve(res.data);
  }
  async GetItacReasons(): Promise<dealReason[]> {
    const uri = "/api/rtc/positions/reasons";
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacReasons", err);
      return Promise.reject(err);
    });
    const arr: dealReason[] = [];
    res.data.forEach((element: any) => {
      arr.push(new dealReason(element));
    });
    // console.log("Res for GetItacReasons", arr);
    return Promise.resolve(arr);
  }
}
