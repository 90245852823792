
import { WeatherWs } from "@/DataAccess/WebServices/AgriBase/WeatherWs";
import { Component, Vue } from "vue-property-decorator";
import { Precipitation } from "@/models/RootObjects/Precipitation";
import { PrecipitationData } from "@/models/RootObjects/PrecipitationData";
import precip from "@/components/Weather/Precipitation.vue";
import { DataTableHeader } from "vuetify";
import { UserRole } from "@/models/RootObjects/UserRole";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";

const weather = new WeatherWs();

@Component
export default class HailDataTable extends Vue {
  loading = false;
  precipitation: Precipitation | null = null;
  provinceGroupHail: {
    name: string;
    items: PrecipitationData[];
  }[] = [];

  userRole: UserRole[] = [];

  headers: DataTableHeader[] = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Today",
      align: "start",
      value: "today",
    },
    {
      text: "Day 1",
      align: "start",
      value: "day1",
    },
    {
      text: "Day 2",
      align: "start",
      value: "day2",
    },
    {
      text: "Day 3",
      align: "start",
      value: "day3",
    },
    {
      text: "Day 4",
      align: "start",
      value: "day4",
    },
    {
      text: "Day 5",
      align: "start",
      value: "day5",
    },
    {
      text: "Day Total",
      align: "start",
      value: "dayTotal",
    },
    {
      text: "Day 14 Total",
      align: "start",
      value: "day14Total",
    },
    {
      text: "Day 30 Total",
      align: "start",
      value: "day30Total",
    },
    {
      text: "Season Total",
      align: "start",
      value: "seasonTotal",
    },
    {
      text: "Prev Season Total",
      align: "start",
      value: "prevSeasonTotal",
    },
    {
      text: "Prev Season Total To Date",
      align: "start",
      value: "prevSeasonTotalToDate",
    },
  ];
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const precipitationResp = await weather
        .GetPrecipitation()
        .catch((err) => {
          return Promise.reject(err);
        });
      const userRoleWs = new UserRoleWs();
      const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
        return Promise.reject(err);
      });
      // User Roles
      this.userRole = userRoleResp;
      console.log("User Role Resp ", userRoleResp);
      // Precipitation
      this.precipitation = precipitationResp;
      console.log("Precipitation Resp ", precipitationResp);
    } catch (err) {
      console.log("Mounted Precipitation error ", err);
    } finally {
      this.loading = false;
    }
  }
  get getPrecipitation(): Precipitation | null {
    return this.precipitation;
  }

  get getFilteredDataHail(): {
    name: string;
    items: PrecipitationData[];
  }[] {
    this.getPrecipitation?.hailData.forEach((elem) => {
      const arr = this.provinceGroupHail.find(
        (item) => item.name == elem.province
      );
      if (arr) {
        arr.items.push(elem);
      } else {
        this.provinceGroupHail.push({
          name: elem.province,
          items: [elem],
        });
      }
    });
    return this.provinceGroupHail;
  }

  get getUserRole(): UserRole[] {
    return this.userRole;
  }
  checkWeatherData(): boolean {
    return precip.checkWeatherData();
  }
  checkWeatherTier4(): boolean {
    return precip.checkWeatherTier4();
  }
  checkWeatherTier2(): boolean {
    return precip.checkWeatherTier2();
  }
  checkWeatherTier3(): boolean {
    return precip.checkWeatherTier3();
  }
}
