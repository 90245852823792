var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currencies.length != 0)?_c('v-data-iterator',{attrs:{"items":_vm.currencies,"disable-filtering":"","disable-pagination":"","sort-by":"code","sort-desc":true,"hide-default-footer":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items),function(c){return _c('v-col',{key:c.code,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"py-0",attrs:{"elevation":"0","outlined":""}},[_c('div',{staticClass:"text-left py-1 pl-3 cardbg"},[_c('v-row',[_c('v-col',{staticClass:"title",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(c.code)+" ")]),_c('v-col',{staticClass:"subtitle-2 pt-5",attrs:{"cols":"8"}},[_vm._v(" Current "+_vm._s(String.fromCharCode(parseInt(c.symbol, 16)))+_vm._s(c.prices[0].rate)+" ")])],1)],1),_c('v-divider'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"id":"opening","width":"32%"}},[_vm._v("Opening")]),_c('th',{staticClass:"text-left",attrs:{"id":"change","width":"30%"}},[_vm._v("Change")]),_c('th',{staticClass:"text-left",attrs:{"id":"change_pct","width":"33%"}},[_vm._v(" Change % ")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(parseFloat(c.prices[0].start_rate).toFixed(4))+" ")]),_c('td',{staticClass:"text-left text-no-wrap",class:{
                      up: c.prices[0].change > 0 ? true : false,
                      down: c.prices[0].change < 0 ? true : false,
                    }},[_vm._v(" "+_vm._s(c.prices[0].change)+" "),(c.prices[0].change > 0)?_c('v-icon',{staticClass:"pb-1",attrs:{"size":20,"color":"blue darken-4"}},[_vm._v("mdi-menu-up")]):_c('v-icon',{staticClass:"pb-1",attrs:{"size":20,"color":"red darken-1"}},[_vm._v("mdi-menu-down")])],1),_c('td',{staticClass:"text-left text-no-wrap",class:{
                      up: c.prices[0].change_pct > 0 ? true : false,
                      down: c.prices[0].change_pct < 0 ? true : false,
                    }},[_vm._v(" "+_vm._s(c.prices[0].change_pct)+" % "),(c.prices[0].change_pct > 0)?_c('v-icon',{staticClass:"pb-1",attrs:{"size":20,"color":"blue darken-4"}},[_vm._v("mdi-menu-up")]):_c('v-icon',{staticClass:"pb-1",attrs:{"size":20,"color":"red darken-1"}},[_vm._v("mdi-menu-down")])],1)])])]},proxy:true}],null,true)})],1)],1)}),1)]}}],null,false,1061392410)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }