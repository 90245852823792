
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import Graph from "@/components/CommodityPricing/Graph.vue";
import {
  itacInstruments as InstrumentType,
  itacSocketResponse,
  combinedInstrument,
} from "@/models/RootObjects/ItacInstruments";
import { ItacInstrumentsWs } from "@/DataAccess/WebServices/AgriBase/ItacInstrumentsWs";
import dayjs from "dayjs";
import { DataTableHeader } from "vuetify";
import Auth from "@/store/modules/Auth";
import { io, Socket } from "socket.io-client";
import _ from "lodash";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
const itemHook = new ItacInstrumentsWs();

@Component({
  components: {
    Graph,
  },
})
export default class itacInstruments extends Vue {
  @Prop() marketSocket!: Socket | null;
  @Prop() instruments!: combinedInstrument[];
  expandedGroups: string[] = [];
  viewMore: {
    open: boolean;
    item: null | combinedInstrument;
    current: number;
  } = {
    current: 0,
    open: false,
    item: null,
  };
  serverString = process.env.VUE_APP_SERVER_ITAC_IO + ":3003";
  instrSetup = false;
  addedInstruments: number[] = [];
  availableInstruments: InstrumentType[] = [];
  searchedInstrument: string | null = null;
  // items: combinedInstrument[] = [];
  loading = false;
  itemsPerPage = 15;
  itemsPerPageArray = [10, 15, 30];
  search = "";
  itemsReadyForItac: InstrumentType[] = [];
  filter = {};
  sortDesc = false;
  sortBy = "expiryDATE";
  groupBy = "underlyingInstrument";
  page = 1;
  startDateMenu = false;
  startDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
  endDateMenu = false;
  endDate = dayjs().format("YYYY-MM-DD");
  openContract(item: combinedInstrument): void {
    console.log("Open this", item);
    this.viewMore.item = item;
    this.viewMore.open = true;
  }
  customGroup(
    items: combinedInstrument[],
    groupBy: string[],
    groupDesc: boolean[]
  ): {
    items: combinedInstrument[];
    name: string;
  }[] {
    let definedGroup: {
      items: combinedInstrument[];
      name: string;
    }[] = [];
    items.forEach((com: combinedInstrument) => {
      const group = definedGroup.find(
        (el) => el.name === com.instrument.underlyingInstrument
      );
      if (group != undefined) {
        console.log("Object is contained");
        group.items.push(com);
      } else {
        definedGroup.push({
          name: com.instrument.underlyingInstrument,
          items: [com],
        });
      }
    });
    definedGroup.forEach((el) => {
      el.items = this.customMainSort(el.items);
    });
    return definedGroup;
  }
  customMainSort(arr: combinedInstrument[]) {
    const month = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    arr.sort((a, b) => {
      const aDay = a.instrument.securityDescription.substring(0, 2);
      const aMonth = a.instrument.securityDescription.substring(2, 5);
      const aYear = a.instrument.securityDescription.substring(5, 7);

      const bDay = b.instrument.securityDescription.substring(0, 2);
      const bMonth = b.instrument.securityDescription.substring(2, 5);
      const bYear = b.instrument.securityDescription.substring(5, 7);

      if (Number(bYear) > Number(aYear)) {
        return -1;
      } else if (Number(bYear) < Number(aYear)) {
        return 1;
      } else {
        //same year
        if (Number(bMonth) > Number(aMonth)) {
          return -1;
        } else if (Number(bMonth) < Number(aMonth)) {
          return 1;
        } else {
          if (
            month.findIndex((e) => e == bDay) >
            month.findIndex((e) => e == aDay)
          ) {
            return 1;
          } else if (Number(bDay) < Number(aDay)) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    });
    return arr;
  }
  closeContract(): void {
    this.viewMore.open = false;
    this.viewMore.item = null;
  }
  // beforeDestroy() {
  //   const saveItems: combinedInstrument[] = [];
  //   this.items.forEach((element) => {
  //     saveItems.push(element);
  //   });
  //   localStorage.setItem("bvg-itac-instruments", JSON.stringify(saveItems));
  //   console.log("Unmounting instruments: ");
  // }
  itemHeaders: DataTableHeader[] = [
    {
      align: "start",
      value: "instrument.securityDescription",
      text: "Contract",
      // sort: (a: string, b: string): number => this.customDateSort(a, b),
    },
    {
      align: "start",
      value: "socket.cumulativeBestBidQty",
      text: "B-Q",
    },
    {
      align: "start",
      value: "socket.displayBestBidPrice",
      text: "B-P",
    },
    {
      align: "start",
      value: "socket.displayBestOfferPrice",
      text: "O-P",
    },
    {
      align: "start",
      value: "socket.cumulativeBestOfferQty",
      text: "O-Q",
    },
    {
      value: "actions",
      text: "",
      width: "1rem",
    },
  ];

  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  // mounted(): void {
  //   this.socketListen();
  // }
  deleteGroup(group: string) {
    console.log("Delete this row");
    const del = this.instruments.filter(
      (e) => e.instrument.underlyingInstrument == group
    );
    del.forEach((element) => {
      this.$emit(
        "unsubscribeSocketPerInstrument",
        element.instrument.instrumentID
      );
    });
  }
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  // subscribeSocket(): void {
  //   if (this.getUserAuthResponseITAC == null) return;
  //   if (this.marketSocket == null) return;
  //   console.log("Subscribe TOB and check localSTorage instruments");
  //   this.marketSocket.emit("subscribe", "TOB");
  //   const it = localStorage.getItem("bvg-itac-instruments");
  //   if (it != null) {
  //     this.items = JSON.parse(it);
  //     this.items.forEach((e) => {
  //       this.subscribeSocketPerInstrument(e.instrument.instrumentID);
  //     });
  //   }
  // }
  // subscribeSocketPerInstrument(id: string): void {
  //   // this.addedInstruments.forEach(elem)
  //   if (this.marketSocket != null) {
  //     if (this.getUserAuthResponseITAC != null) {
  //       console.log("Subscribe TOBSubAdd", id);
  //       this.marketSocket.emit("TOBSubAdd", id);
  //     } else {
  //       console.log("Authentication is null");
  //     }
  //   } else {
  //     console.log("Socket null");
  //   }
  // }
  // unsubscribeSocketPerInstrument(id: string): void {
  //   // this.addedInstruments.forEach(elem)
  //   if (this.marketSocket != null) {
  //     if (this.getUserAuthResponseITAC != null) {
  //       console.log("Unsubscribe TOBSubAdd", id);
  //       this.marketSocket.emit("TOBSubAdd", id);
  //     }
  //   }
  // }
  // @Watch("socket.connected", {
  //   immediate: true,
  // })
  // onSocketChanged(newVal: boolean | undefined, oldVal: boolean | undefined) {
  //   if (newVal != undefined) {
  //     if (newVal == true) {
  //       this.listenToSocket();
  //       this.subscribeSocket();
  //     }
  //   }
  // }
  // listenToSocket() {
  //   if (this.marketSocket == null) return;
  //   this.marketSocket.on("Instruments", (message: string) => {
  //     this.updateData(message);
  //     // console.log("Message data ", message);
  //   });
  // }

  // updateData(data: string): void {
  //   // console.log("Updating ");
  //   const newData = new itacSocketResponse(JSON.parse(data));
  //   // console.log("Data : ", newData);W
  //   const match = _.find(this.items, {
  //     instrument: {
  //       instrumentID: newData.instrumentID.toString(),
  //     },
  //   });
  //   // console.log("Match ", match);
  //   if (match) {
  //     if (match.socket == null) {
  //       match.socket = newData;
  //     } else {
  //       _.extend(match.socket, newData);
  //     }
  //   } else {
  //     console.log(
  //       "Shouldnt hit this as items should always be in when receiving update"
  //     );
  //   }
  // }
  deleteRow(item: combinedInstrument) {
    // if (item.socket == null) {
    //   if(item.instrument != null) {
    //     this
    //   }
    // } else {

    // }
    // const del = this.instruments.filter((e) => e.instrument != null && e.instrument.instrumentID == item.instrument.instrumentID);
    if (item.socket != null) {
      this.$emit("unsubscribeSocketPerInstrument", item.socket.instrumentID);
    } else {
      this.$emit(
        "unsubscribeSocketPerInstrument",
        item.instrument.instrumentID
      );
    }
  }
  get getGroups(): {
    group: string;
    items: combinedInstrument[];
  }[] {
    const groups: {
      group: string;
      items: combinedInstrument[];
    }[] = [];
    this.getItems.forEach((element) => {
      const found = groups.findIndex(
        (e) => e.group == element.instrument.underlyingInstrument
      );
      if (found == -1) {
        groups.push({
          group: element.instrument.underlyingInstrument,
          items: [element],
        });
      } else {
        groups[found].items.push(element);
      }
    });
    console.log("Groups: ", groups);
    return groups;
  }
  get getItems(): combinedInstrument[] {
    return this.instruments;
  }
  get numberOfPages() {
    return Math.ceil(this.getItems.length / this.itemsPerPage);
  }
  // get filteredKeys() {
  //   return this.keys.filter((key) => key !== "Name");
  // }
  nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }
  formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }
  updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }
  @Watch("startDate", {
    immediate: true,
  })
  onStartDateChanged(): void {
    /*  */
  }
  loadSelectedInstruments(): void {
    const toRemove: InstrumentType[] = [];
    this.addedInstruments.forEach((element: number) => {
      console.log("Removing added: ", element);
      this.$emit(
        "subscribeSocketPerInstrument",
        this.availableInstruments[element].instrumentID
      );
      const temp = new combinedInstrument({
        socket: null,
        instrument: this.availableInstruments[element],
      });
      this.instruments.push(temp);
      toRemove.push(this.availableInstruments[element]);
    });
    this.availableInstruments = this.availableInstruments.filter(
      (e) => toRemove.findIndex((ex) => ex.instrumentID == e.instrumentID) == -1
    );
    localStorage.setItem(
      "bvg-itac-instruments",
      JSON.stringify(this.instruments)
    );

    this.addedInstruments = [];
  }
  // beforeDestroy(): void {
  //   const saveItems: combinedInstrument[] = [];
  //   this.instruments.forEach((element) => {
  //     saveItems.push(element);
  //   });
  //   localStorage.setItem("bvg-itac-instruments", JSON.stringify(saveItems));
  //   console.log("Unmounting instruments: ");
  // }
  async loadItems(): Promise<void> {
    try {
      if (this.searchedInstrument != null) {
        this.loading = true;
        const dls = await itemHook
          .GetItacSearchedInstrument(this.searchedInstrument)
          .catch((err) => {
            return Promise.reject(err);
          });
        //check if not already added
        if (this.instruments.length == 0) {
          this.availableInstruments = dls;
        } else {
          let temp: InstrumentType[] = [];
          temp = dls.filter(
            (e) =>
              this.instruments.findIndex(
                (comb) =>
                  (comb.instrument != null &&
                    comb.instrument.instrumentID == e.instrumentID) ||
                  (comb.socket != null &&
                    comb.socket.instrumentID.toString() == e.instrumentID)
              ) == -1
          );
          // dls.forEach((type) => {
          //   const index = this.instruments.findIndex(
          //     (e) =>
          //       (e.instrument != null &&
          //         e.instrument.instrumentID == type.instrumentID) ||
          //       (e.socket != null &&
          //         e.socket.instrumentID.toString() == type.instrumentID)
          //   );
          //   if (index == -1) {
          //     temp.push(dls[index]);
          //   } else {
          //     console.log("Should not add at ", index);
          //   }
          // });
          this.availableInstruments = temp;
        }
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
