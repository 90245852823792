import { StorageUpdate, StoredAuthDetails } from "models";
import { OauthTokenPasswordRoot } from "@/models/OAuth/OauthTokenPasswordRoot";
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import Auth from "@/store/modules/Auth";
const baseKey = "com.bvg.app.auth";
//can check boolean status of updateStorage false then you know its broken
export const LS = () => {
  // const getLoginItac = (): string | null => {
  //   return localStorage.getItem(baseKey + itacAdd);
  // };
  const getLogin = (): string | null => {
    return localStorage.getItem(baseKey);
  };
  const clearStorage = () => {
    localStorage.removeItem(baseKey);
  };
  const updateStorage = ({ data, itac }: StorageUpdate): boolean => {
    try {
      console.log("Updating LS start");
      //without uuid to add itac bool before
      const raw = localStorage.getItem(baseKey);
      if (raw != null) {
        const parsed = JSON.parse(raw) as StoredAuthDetails;
        if (Auth.getLoginDetails != null) {
          if (Auth.getLoginDetails.userName != parsed.username) {
            console.log(
              "Clearing LS because of username mismatch ",
              Auth.getLoginDetails,
              parsed
            );
            Auth.clearLoggedIn();
            clearStorage();
          }
        }
      }
      //check if username matches;
      if (data instanceof UserAuthentication) {
        const fieldName = itac == true ? "authResponseItac" : "authResponse";
        if (raw != null) {
          const item = JSON.parse(raw) as StoredAuthDetails;
          localStorage.setItem(
            baseKey,
            JSON.stringify({ ...item, [fieldName]: data })
          );
        } else {
          if (Auth.getLoginDetails != null) {
            localStorage.setItem(
              baseKey,
              JSON.stringify({
                username: Auth.getLoginDetails.userName,
                [fieldName]: data,
              })
            );
          } else {
            console.error("Not writing to local storage");
          }
        }
        return true;
      } else if (data instanceof OauthTokenResponseRoot) {
        console.log("LoginResponse : ", itac);
        const fieldName = itac == true ? "loginResponseItac" : "loginResponse";
        if (raw != null) {
          const item = JSON.parse(raw) as StoredAuthDetails;
          localStorage.setItem(
            baseKey,
            JSON.stringify({ ...item, [fieldName]: data })
          );
        } else {
          if (Auth.getLoginDetails != null) {
            localStorage.setItem(
              baseKey,
              JSON.stringify({
                username: Auth.getLoginDetails.userName,
                [fieldName]: data,
              })
            );
          }
        }
        return true;
      } else if (data instanceof OauthTokenPasswordRoot) {
        if (raw != null) {
          const item = JSON.parse(raw) as StoredAuthDetails;
          localStorage.setItem(
            baseKey,
            JSON.stringify({ ...item, username: data.userName })
          );
        } else {
          localStorage.setItem(
            baseKey,
            JSON.stringify({ username: data.userName })
          );
        }
        return true;
      }
      return false;
    } catch (err) {
      //
      console.log("Error updating LS: ", err);
      return false;
    }
  };
  // const oldupdateStorage = ({ data, itac }: StorageUpdate): boolean => {
  //   try {
  //     if (itac == true) {
  //       const value = localStorage.getItem("bvg-app-details-itac");
  //       if (data instanceof UserAuthentication) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ ...item, authResponse: data })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ authResponse: data })
  //           );
  //           return true;
  //         }
  //       } else if (data instanceof OauthTokenResponseRoot) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ ...item, loginResponse: data })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ loginResponse: data })
  //           );
  //           return true;
  //         }
  //       } else if (data instanceof OauthTokenPasswordRoot) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ ...item, username: data.userName })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details-itac",
  //             JSON.stringify({ username: data.userName })
  //           );
  //           return true;
  //         }
  //       } else {
  //         console.error("UPDATE STORAGE FAILED: NOT OF REQUIRED TYPE", data);
  //         return false;
  //       }
  //     } else {
  //       const value = localStorage.getItem("bvg-app-details");
  //       if (data instanceof UserAuthentication) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ ...item, authResponse: data })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ authResponse: data })
  //           );
  //           return true;
  //         }
  //       } else if (data instanceof OauthTokenResponseRoot) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ ...item, loginResponse: data })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ loginResponse: data })
  //           );
  //           return true;
  //         }
  //       } else if (data instanceof OauthTokenPasswordRoot) {
  //         if (value != null) {
  //           const item = JSON.parse(value);
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ ...item, username: data.userName })
  //           );
  //           return true;
  //         } else {
  //           localStorage.setItem(
  //             "bvg-app-details",
  //             JSON.stringify({ username: data.userName })
  //           );
  //           return true;
  //         }
  //       } else {
  //         console.error("UPDATE STORAGE FAILED: NOT OF REQUIRED TYPE", data);
  //         return false;
  //       }
  //     }
  //   } catch (err) {
  //     console.error("UPDATE STORAGE FAILED: TRY/CATCH", err);
  //     return false;
  //   }
  // };

  //return hook items like functions or values
  return {
    updateStorage,
    getLogin,
    clearStorage,
  };
};
