import { axiosInstance, axiosInstanceNew } from "@/plugins/axios";

interface CRMClients {
  clientCodes: string[];
  filteredClients: {
    branch: string;
    dealers: { dealer: string; clients: string[] }[];
  }[];
  isRetailClient: boolean;
}

export class UserSubScriptionsWs {
  public async GetCRMClients(): Promise<CRMClients> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/usersubscriptions/getCRMClients";
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on Clearing for user", err);
      return Promise.reject(err);
    });
    // console.log("Res for clearing for user", res);
    return Promise.resolve(res.data);
  }

  public async GetUserSubscribedClients(): Promise<string[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] +
      "/api/usersubscriptions/getUserSubscribedClients";
    const res = await axiosInstance.get(base).catch((err) => {
      //console.log("Error on Clearing for user", err);
      return Promise.reject(err);
    });
    // console.log("Res for clearing for user", res);
    return Promise.resolve(res.data);
  }

  public async SaveUserClients(data: any): Promise<string[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] +
      "/api/usersubscriptions/saveUserClients";
    const res = await axiosInstance
      .post(base, data, { headers: { "Content-Type": "multipart/form-data" } })
      .catch((err) => {
        //console.log("Error on Clearing for user", err);
        return Promise.reject(err);
      });
    // console.log("Res for clearing for user", res);
    return Promise.resolve(res.data);
  }
}
