import { WeatherStationData } from "./WeatherStationData";

export class Data {
  constructor(res: {
    humidity: any[];
    pressure: any[];
    radiation: any[];
    rainfall: any[];
    temperature: any[];
    windDirection: any[];
    windSpeed: any[];
  }) {
    this.humidity = [];
    this.pressure = [];
    this.radiation = [];
    this.rainfall = [];
    this.temperature = [];
    this.windDirection = [];
    this.windSpeed = [];
    if (res.humidity) {
      res.humidity.forEach((element) => {
        this.humidity.push(new WeatherStationData(element));
      });
    }
    if (res.pressure) {
      res.pressure.forEach((element) => {
        this.pressure.push(new WeatherStationData(element));
      });
    }
    if (res.radiation) {
      res.radiation.forEach((element) => {
        this.radiation.push(new WeatherStationData(element));
      });
    }
    if (res.rainfall) {
      res.rainfall.forEach((element) => {
        this.rainfall.push(new WeatherStationData(element));
      });
    }
    if (res.temperature) {
      res.temperature.forEach((element) => {
        this.temperature.push(new WeatherStationData(element));
      });
    }
    if (res.windDirection) {
      res.windDirection.forEach((element) => {
        this.windDirection.push(new WeatherStationData(element));
      });
    }
    if (res.windSpeed) {
      res.windSpeed.forEach((element) => {
        this.windSpeed.push(new WeatherStationData(element));
      });
    }
  }

  public humidity: WeatherStationData[];
  public pressure: WeatherStationData[];
  public radiation: WeatherStationData[];
  public rainfall: WeatherStationData[];
  public temperature: WeatherStationData[];
  public windDirection: WeatherStationData[];
  public windSpeed: WeatherStationData[];
}
