import { ReportGroup, Report } from "@/models/RootObjects/Reports";
import { axiosInstance } from "@/plugins/axios";

export class ReportsWs {
  public async GetGroups(): Promise<ReportGroup[]> {
    const url =
      process.env.VUE_APP_SERVER_BASE + "/api/reports/category/getCategories";
    const res = await axiosInstance.get(url).catch((e) => Promise.reject(e));
    const data = res.data;
    return data;
  }

  public async GetReports(): Promise<Report[]> {
    const url = process.env.VUE_APP_SERVER_BASE + "/api/reports/marketReport";
    const res = await axiosInstance.get(url).catch((e) => Promise.reject(e));
    const data = res.data.marketreports;
    const temp: Report[] = [];
    for (const rep in data) {
      temp.push(new Report(data[rep]));
    }
    return temp;
  }
}
