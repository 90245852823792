import { Breakdown } from "@/models/RootObjects/Breakdown";
import { axiosInstance } from "@/plugins/axios";

export class BreakdownWs {
  public async GetBreakdownForUser(): Promise<Breakdown[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/user/express/breakdown";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on breakdown", err);
      return Promise.reject(err);
    });
    // console.log("Res for Breakdown", res);
    return Promise.resolve(res.data);
  }
}
