
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { dealReason, itacDeals } from "@/models/RootObjects/ItacDeals";
import { ItacDealsWs } from "@/DataAccess/WebServices/AgriBase/ItacDealsWs";
import dayjs from "dayjs";
import { DataTableHeader } from "vuetify";
import Auth from "@/store/modules/Auth";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import axios from "axios";

const dl = new ItacDealsWs();

// group.items.filter(
//   (e) =>
//     e.nextTradeIds == null &&
//     (e.activeQuantity != null ||
//       e.activeQuantity >= 1) &&
//     e.positionReason != 35
// ).length

@Component
export default class ItacDealsLive extends Vue {
  @Prop() deals!: itacDeals[];
  @Prop() dealReasons!: dealReason[];
  // items: itacDeals[] = [];
  // serverString = process.env.VUE_APP_SERVER_ITAC_IO + ":3001";
  loading = false;
  itemsPerPage = 15;
  itemsPerPageArray = [10, 15, 30];
  search = "";
  filter = {};
  sortDesc = false;
  sortBy = "accountId";
  groupBy = "externalPositionAccount";
  page = 1;
  startDateMenu = false;
  startDate = dayjs().format("YYYY-MM-DD");
  endDateMenu = false;
  endDate = dayjs().format("YYYY-MM-DD");
  headers: DataTableHeader[] = [
    {
      value: "shortName",
      text: "Instrument",
      width: "40%",
    },
    {
      value: "capacity",
      text: "QTY",
    },
    {
      value: "price",
      text: "Price",
    },
    {
      value: "tradeId",
      text: "Trade ID",
    },
  ];
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  get getItems(): itacDeals[] {
    return this.deals;
  }
  get numberOfPages(): number {
    return Math.ceil(this.getItems.length / this.itemsPerPage);
  }
  // get filteredKeys() {
  //   return this.keys.filter((key) => key !== "Name");
  // }
  nextPage(): void {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }
  formerPage(): void {
    if (this.page - 1 >= 1) this.page -= 1;
  }
  updateItemsPerPage(number: number): void {
    this.itemsPerPage = number;
  }

  // async mounted(): Promise<void> {
  //   try {
  //     if (this.getUserAuthResponseITAC == null) {
  //       return Promise.reject("Not logged in");
  //     }
  //     const temp = io(
  //       "http://localhost:3001?uuid=" + this.getUserAuthResponseITAC.uniqueKey,
  //       { autoConnect: false, transports: ["websocket"] }
  //     );
  //     temp.connect();
  //     console.log("Connected test: ", temp.connected);
  //     temp.on("connect", () => {
  //       temp.emit("subscribe", "TradeViewModelChannel");
  //       console.log("Connected to local");
  //       // setTimeout(
  //       //   () => temp.emit("unsubscribe", "TradeViewModelChannel"),
  //       //   1000
  //       // );
  //       // temp.emit("SubscribeGeneric", "supsup");
  //     });
  //     // this.reasons = await dl
  //     //   .GetItacReasons()
  //     //   .catch((err) => Promise.reject(err));
  //     // await this.loadDeals().catch((err) => Promise.reject(err));
  //     // this.socketListen();
  //   } catch (err) {
  //     return Promise.reject(err);
  //   }
  //   console.log("ITAC Deals ", this.deals);
  // }
}
