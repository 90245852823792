
import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import InstallBanner from "@/components/pwa/InstallBanner.vue";
import OfflineFallback from "@/components/pwa/OfflineFallback.vue";
import GlobalNotifications from "@/components/notifications/GlobalNotifications.vue";
import { Device } from "@capacitor/device";
import NotifModule from "@/store/modules/NotificationSystem";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
@Component({
  components: {
    updateNotif,
    InstallBanner,
    OfflineFallback,
    GlobalNotifications,
  },
})
export default class App extends Vue {
  Dayjs = dayjs;
  onLine = true;
  get isOnline(): boolean {
    return this.onLine;
  }
  get getVersion(): string {
    return process.env.VUE_APP_VERSION;
  }
  // get getUrl(): string {
  //   return process.env;
  // }
  created(): void {
    Device.getInfo().then((devInfo) => {
      NotifModule.setDeviceInfo(devInfo);
      if (devInfo.platform == "android" || devInfo.platform == "ios") {
        if (devInfo.platform == "ios") {
          // android always has permission
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive == "granted") {
              console.log("Push notification registration");
              PushNotifications.register().catch((err) => {
                return Promise.reject(err);
              });
            }
            if (res.receive == "denied") {
              this.$notifCreator.createWarningNotification(
                "You wont receive any price updates from BVG."
              );
            }
          });
        }

        // On success, we should be able to receive notifications
        PushNotifications.addListener("registration", (token: Token) => {
          console.log("Push notifications: ", token.value);
          NotifModule.setFirebaseToken(token.value);
          this.$notifCreator.createSuccessNotification(
            `Mobile Push notification registration successful. ${token.value}`
          );
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener("registrationError", (error: any) => {
          console.error("Push notifications: ", error);
          this.$notifCreator.createErrorNotification(
            "Mobile Push notification registration failed. Contact the dev team."
          );
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            this.$notifCreator.createSuccessNotification("New notifications.");
            console.log(
              "Notification in foreground: ",
              JSON.stringify(notification)
            );
          }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
            console.log("pushNotificationActionPerformed:", notification);
          }
        );
      }
    });
  }
}
