import { Instrument } from "@/models/RootObjects/Instrument";
// import Dash from '@/store/modules/dash';
import { axiosInstance } from "@/plugins/axios";

export class InstrumentWs {
  public async GetInstruments(): Promise<Instrument[]> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/option/calculator/instruments";
    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on instruments", err);
      return Promise.reject(err);
    });
    // console.log("Res for instruments", res);
    // Dash.state.instruments = res.data;
    return Promise.resolve(res.data);
  }
}
