import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "models";
import _Vue from "vue";

const delay = 100;

const Notifications = () => {
  const createSuccessNotification = (message: string): notificationType => {
    const temp = {
      color: "primary",
      message: message,
      icon: "mdi-check-all",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createErrorNotification = (message: string): notificationType => {
    const temp = {
      color: "error",
      message: message,
      icon: "mdi-alert-circle",
      timeout: -1,
      id: Math.ceil(Math.random() * 1000),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createWarningNotification = (message: string): notificationType => {
    const temp = {
      color: "warning",
      message: message,
      icon: "mdi-shield-alert",
      timeout: 3000,
      id: Math.ceil(Math.random() * 1000),
    };
    NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  return {
    createSuccessNotification,
    createWarningNotification,
    createErrorNotification,
  };
};
const notifications = Notifications();
export { notifications };
// export default Notifications;
export default {
  install(Vue: typeof _Vue) {
    Vue.prototype.$notifCreator = notifications;
    Vue.$notifCreator = notifications;
  },
};
