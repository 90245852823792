// import { OAuthTokenRequestRoot } from './OauthTokenRequestRoot';
export class OauthTokenPasswordRoot {
  constructor(username: string, password: string) {
    this.userName = username;
    this.password = password;
  }

  public userName: string;
  public password: string;
}
