import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import AccountDeletion from "../views/AccountDeletionRequest.vue";
import Home from "../components/Home.vue";
import Weather from "../components/Weather/Weather.vue";
import MarketIndexFeed from "../components/MarketIndexFeed.vue";
import Currencies from "../components/Currencies/Currencies.vue";
import Express from "../components/Agris/Express/Express.vue";
import ExpressNew from "../components/Agris/Express-New/Express.vue";
import ContactUs from "../components/ContactUs.vue";
import Calculator from "../components/Agris/OptionCalculator.vue";
import Notifications from "../components/Notifications.vue";
import Commodity from "../components/CommodityPricing/NewCommodityPricing.vue";
import Precipitation from "../components/Weather/Precipitation.vue";
import AppBar from "../components/Appbar.vue";
import NavDrawer from "../components/NavDrawer.vue";
import Auth from "@/store/modules/Auth";
import Itac from "@/components/Itac/Itac.vue";
import ItacExpress from "@/components/Itac/express/ItacExpress.vue";
import Positions from "@/components/Agris/Positions.vue";
import ItacExpressHist from "@/components/Itac/express/ItacExpressHist.vue";
import ItacDlrHist from "@/components/Itac/dlr/ItacDlrHist.vue";
import ItacInstruments from "@/components/Itac/ItacInstruments.vue";
import AfternoonMarketReports from "@/components/MarketReports/AfternoonMarketReports.vue";
import MorningMarketReports from "@/components/MarketReports/MorningMarketReports.vue";
import MTM from "@/components/Agris/MTM.vue";
import Reports from "@/components/Reports/Reports.vue";
import dayjs from "dayjs";
import { notifications } from "@/hooks/Notifications";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    components: {
      main: Login,
    },
  },
  {
    path: "/account-deletion",
    name: "AccountDeletion",
    components: {
      main: AccountDeletion,
    },
  },
  {
    path: "/dashboard",
    components: {
      main: Dashboard,
      nav: NavDrawer,
      appBar: AppBar,
    },
    children: [
      {
        path: "home",
        name: "dash.home",
        component: Home,
      },
      {
        path: "reports",
        component: Reports,
        name: "dash.reports",
      },

      {
        path: "weather",
        name: "dash.weather",
        component: Weather,
      },
      {
        path: "precipitation",
        name: "dash.precipitation",
        component: Precipitation,
      },
      {
        path: "express",
        name: "dash.express",
        component: Express,
      },
      {
        path: "express-new",
        name: "dash.express-new",
        component: ExpressNew,
      },
      {
        path: "positions",
        name: "dash.positions",
        component: Positions,
      },
      {
        path: "market-to-market",
        name: "dash.mtm",
        component: MTM,
      },
      {
        path: "currencies",
        name: "dash.currencies",
        component: Currencies,
      },
      {
        path: "commodity-pricing",
        name: "dash.commodity",
        component: Commodity,
        beforeEnter: (to, from, next) => {
          console.log("Checking if weekend? ");
          switch (dayjs().day()) {
            // Saturday(6), or Sunday (0)
            case 0:
            case 6:
              next({
                name: "dash.mtm",
              });

              notifications.createWarningNotification(
                "Market closed on weekends. Redirected to MTM..."
              );
              break;
            default:
              next();
              break;
          }
        },
      },
      {
        path: "market-index-feed",
        name: "dash.market-index",
        component: MarketIndexFeed,
      },
      {
        path: "option-calculator",
        name: "dash.calculator",
        component: Calculator,
      },
      {
        path: "notifications",
        name: "dash.notifications",
        component: Notifications,
      },
      {
        path: "contact-us",
        name: "dash.contact-us",
        component: ContactUs,
      },
      {
        path: "itac",
        component: Itac,
        name: "dash.itac",
        props: true,
        children: [
          {
            path: "express",
            name: "itac.express",
            component: ItacExpress,
          },
          // {
          //   path: "dlr",
          //   name: "itac.dlr",
          //   component: ItacDlr,
          // },
          {
            path: "express-historic",
            name: "itac.express-hist",
            component: ItacExpressHist,
          },
          // {
          //   path: "dlr-historic",
          //   name: "itac.dlr-hist",
          //   component: ItacDlrHist,
          // },
          {
            path: "instruments",
            name: "itac.instruments",
            component: ItacInstruments,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
  mode: "hash",
});

router.beforeEach((to, from, next) => {
  // if (AuthModule.isLoggedIn == false) {
  Auth.checkLocalStorage(
    Auth.getUserAuthResponse,
    Auth.getUserAuthResponseITAC
  );
  if (to.name == "AccountDeletion") {
    next();
  } else if (to.name !== "Login" && Auth.getValidLogin == false) {
    console.log("Not valid login");
    Auth.clearLoggedIn();
    next({
      name: "Login",
      params: {
        from: from == undefined ? "" : from.path,
      },
    });
  } else if (to.name == "Login" && Auth.getValidLogin == true) {
    next({
      name: "dash.home",
    });
  } else next();
});

export default router;
