
import { Component, Prop, Vue } from "vue-property-decorator";
import { UserAlert as alert } from "@/models/RootObjects/UserAlert";
import { RealTimePrice as price } from "@/models/RootObjects/RealTimePricesData";

@Component
export default class Alerts extends Vue {
  @Prop() readonly myTable!: alert[];
  headers = [
    {
      text: "Instrument",
      align: "start",
      value: "instrument",
      groupable: false,
    },
    {
      text: "Expiry",
      align: "right",
      value: "expiry",
      groupable: false,
    },
    {
      //There is no property for open price in UserAlert model for Open Price, but there is one in RealTimePricesData
      text: "Open Price",
      align: "right",
      value: "currentPrice",
      groupable: false,
    },
    {
      text: "Trigger Price",
      align: "right",
      value: "triggerPrice",
      groupable: false,
    },
    {
      text: "Difference",
      align: "right",
      value: "differencePrice",
      groupable: false,
    },
    {},
  ];
}
