import { axiosInstance } from "@/plugins/axios";
import { OptionCalculatorRequest } from "@/models/RootObjects/OptionCalculatorRequest";
import { OptionCalculatorResponse } from "@/models/RootObjects/OptionCalculatorResponse";

export class OptionCalculatorWs {
  public async GetCalculatedOption(
    optionCalculatorRequest: OptionCalculatorRequest
  ): Promise<OptionCalculatorResponse> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/option/calculator/calculate";
    const res = await axiosInstance
      .post(base, optionCalculatorRequest)
      .catch((err) => {
        return Promise.reject(err);
      });
    // console.log("Res for GetCalculatedOption", res);
    return Promise.resolve(res.data);
  }
  public async SendRequestToTrade(
    optionCalculatorRequest: OptionCalculatorRequest
  ): Promise<OptionCalculatorResponse> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] + "/api/option/calculator/send";
    const res = await axiosInstance
      .post(base, optionCalculatorRequest)
      .catch((err) => {
        return Promise.reject(err);
      });
    // console.log("Res for GetCalculatedOption", res);
    return Promise.resolve(res.data);
  }
}
