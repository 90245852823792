var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.tableData,"sort-by":"Instrument","group-by":"P","dense":"","disable-pagination":"","items-per-page":-1,"calculate-widths":"","mobile-breakpoint":"0","height":"70vh","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:'header-' + h.text,staticClass:"white--text primary lighten-1 py-4",class:h.value == 'Instrument' || h.value == 'ContractDate'
              ? 'text-left'
              : 'text-right'},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"group",fn:function(props){return [_c('tr',{key:'principle-' + props.group,staticClass:"primary",on:{"click":function($event){return _vm.clickedHeader(props.group)}}},[_c('td',{staticClass:"white--text text-left",attrs:{"colspan":"12"}},[(_vm.expandedGroups.find((e) => e == props.group) == undefined)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up ")]),_vm._v(" Principle: "+_vm._s(props.group)+" "),_c('v-spacer')],1)]),(
        _vm.expandedGroups.find((e) => e == props.group) == undefined
          ? false
          : true
      )?_vm._l((props.items),function(item){return _c('tr',{key:'row-' + item.P + '-' + item.Instrument + '-' + item.ContractDate},_vm._l((props.headers),function(header){return _c('td',{key:'item-' + header.value,staticClass:"text-caption",class:header.value == 'Instrument' || header.value == 'ContractDate'
              ? 'text-left '
              : 'text-right'},[(header.value == 'D')?_c('span',{staticClass:"text-no-wrap",class:{
              pos: item[header.value] > 0 ? true : false,
              neg: item[header.value] < 0 ? true : false,
            }},[_vm._v(" "+_vm._s(item[header.value].toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 4, }))+" ")]):(header.value == 'T')?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item[header.value].toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]):(header.value == 'G')?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item[header.value].toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 4, }))+" ")]):(header.value == 'M')?_c('span',{staticClass:"text-no-wrap",class:{
              pos: item[header.value] > 0 ? true : false,
              neg: item[header.value] < 0 ? true : false,
            }},[_vm._v(" R "+_vm._s(item[header.value].toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }