
import dayjs from "dayjs";
import { Component, Vue } from "vue-property-decorator";
import { Notification } from "@/models/RootObjects/Notification";
import { NotificationWs } from "@/DataAccess/WebServices/AgriBase/NotificationWs";

const notif = new NotificationWs();

@Component
export default class Notifications extends Vue {
  Dayjs = dayjs;
  loading = false;
  notification: Notification[] = [];
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const notificationsResp = await notif
        .GetNotificationsForUser()
        .catch((err) => {
          return Promise.reject(err);
        });
      // Weather
      this.notification = notificationsResp;
      console.log("Weather Resp ", notificationsResp);
    } catch (err) {
      console.log("Mounted weather error ", err);
    } finally {
      this.loading = false;
    }
  }
}
