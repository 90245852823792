
import { Component, Vue } from "vue-property-decorator";
import { ItacPositionsWs } from "@/DataAccess/WebServices/AgriBase/ItacPositionsWs";
import { itacPositions as position } from "@/models/RootObjects/ItacPositions";
import dayjs from "dayjs";
import { DataTableHeader } from "vuetify";
import Auth from "@/store/modules/Auth";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
const ps = new ItacPositionsWs();

@Component
export default class itacPositions extends Vue {
  Dayjs = dayjs;
  items: position[] = [];
  posTypes = ["Current", "Other"];
  posType = this.posTypes[0];
  loading = false;
  itemsPerPage = 15;
  itemsPerPageArray = [10, 15, 30];
  search = "";
  filter = {};
  sortDesc = false;
  sortBy = "id";
  groupBy = "clientCode";
  page = 1;
  startDateMenu = false;
  startDate = dayjs().format("YYYY-MM-DD");
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  get getItems(): position[] {
    return this.items;
  }
  get numberOfPages(): number {
    return Math.ceil(this.getItems.length / this.itemsPerPage);
  }
  // get filteredKeys() {
  //   return this.keys.filter((key) => key !== "Name");
  // }
  nextPage(): void {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }
  formerPage(): void {
    if (this.page - 1 >= 1) this.page -= 1;
  }
  updateItemsPerPage(number: number): void {
    this.itemsPerPage = number;
  }
  // @Watch("startDate", {
  //   immediate: true,
  // })
  // onStartDateChanged(): void {
  //   /*  */
  // }
  async loadItems(): Promise<void> {
    try {
      this.loading = true;
      const pss = await ps
        .GetItacPositions(this.startDate, this.posType)
        .catch((err) => {
          return Promise.reject(err);
        });
      this.items = pss.filter((e) => {
        if (e.externalInstrumentId != null && e.instrumentDisplayName != null) {
          return e;
        }
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
  async mounted(): Promise<void> {
    try {
      await this.loadItems().catch((err) => Promise.reject(err));
      // this.socketListen();
    } catch (err) {
      return Promise.reject(err);
    }
    // console.log("ITAC Positions ", this.items);
  }
}
