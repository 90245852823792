export class offbookApiResponse {
  constructor(res: {
    Account: string;
    ClientRef: string;
    CounterpartyFirmID: string;
    Date: string;
    InstrumentDisplayName: string;
    InternalCounterparty: boolean;
    OffBookTradeType: string;
    Price: string;
    Quantity: number;
    Status: string;
    Strike: string;
    TradeId: string;
    UnderlyingPrice: string;
    Volatility: string;
    isBuy: string;
    market: string;
    tradeTime: string;
  }) {
    this.account = res.Account;
    this.clientRef = res.ClientRef;
    this.counterpartyFirmID = res.CounterpartyFirmID;
    this.date = res.Date;
    this.instrumentDisplayName = res.InstrumentDisplayName;
    this.internalCounterparty = res.InternalCounterparty;
    this.isBuy = res.isBuy;
    this.market = res.market;
    this.offBookTradeType = res.OffBookTradeType;
    this.price = res.Price;
    this.quantity = res.Quantity;
    this.status = res.Status;
    this.strike = res.Strike;
    this.tradeId = res.TradeId;
    this.tradeTime = res.tradeTime;
    this.underlyingPrice = res.UnderlyingPrice;
    this.volatility = res.Volatility;
  }
  public account: string;
  public clientRef: string;
  public counterpartyFirmID: string;
  public date: string;
  public instrumentDisplayName: string;
  public internalCounterparty: boolean;
  public offBookTradeType: string;
  public price: string;
  public quantity: number;
  public status: string;
  public strike: string;
  public tradeId: string;
  public underlyingPrice: string;
  public volatility: string;
  public isBuy: string;
  public market: string;
  public tradeTime: string;
}
export class offbookSocketResponse {
  constructor(res: {
    Account: string;
    BenchmarkInstrumentID: number;
    BenchmarkPrice: number;
    BuySide: boolean;
    Capacity: number;
    CleanPrice: number;
    ClientRef: string;
    CounterPartyDeclineText: null | string;
    CounterpartyFirmID: string;
    CounterpartyRef: null | number | string;
    Delta: number;
    DoNotPublish: boolean;
    EndAccruedInterestAmount: string;
    EndCash: number;
    EndCleanPrice: number;
    EndSettlementDate: string;
    EndYield: number;
    FirmTradeID: string;
    InstrumentCurrency: null | string;
    InstrumentID: number;
    InternalCounterparty: boolean;
    IsMarketOpsRequest: boolean;
    Key: string;
    OffBookTradeAttribute: number;
    OffBookTradeType: number;
    OptionPrice: number;
    OriginalTradeDate: string;
    OriginalTradeID: number;
    ParPrice: number;
    Price: number;
    PriceType: number;
    Qty: number;
    Rate: number;
    Sender: boolean;
    SettlementCurrency: null | string;
    SettlementDate: string;
    Spread: number;
    StartCash: number;
    Status: number;
    StrategyLinkID: null | string;
    StrategyType: number;
    Time: string;
    TradeID: string;
    TraderGroupCode: string;
    TraderID: string;
    UnderlyingPrice: number;
    Volatility: number;
    Yield: number;
  }) {
    this.account = res.Account;
    this.benchmarkInstrumentID = res.BenchmarkInstrumentID;
    this.benchmarkPrice = res.BenchmarkPrice;
    this.buySide = res.BuySide;
    this.capacity = res.Capacity;
    this.cleanPrice = res.CleanPrice;
    this.clientRef = res.ClientRef;
    this.counterPartyDeclineText = res.CounterPartyDeclineText;
    this.counterpartyFirmID = res.CounterpartyFirmID;
    this.counterpartyRef = res.CounterpartyRef;
    this.delta = res.Delta;
    this.doNotPublish = res.DoNotPublish;
    this.endAccruedInterestAmount = res.EndAccruedInterestAmount;
    this.endCash = res.EndCash;
    this.endCleanPrice = res.EndCleanPrice;
    this.endSettlementDate = res.EndSettlementDate;
    this.endYield = res.EndYield;
    this.firmTradeID = res.FirmTradeID;
    this.instrumentCurrency = res.InstrumentCurrency;
    this.instrumentID = res.InstrumentID;
    this.internalCounterparty = res.InternalCounterparty;
    this.isMarketOpsRequest = res.IsMarketOpsRequest;
    this.key = res.Key;
    this.offBookTradeAttribute = res.OffBookTradeAttribute;
    this.offBookTradeType = res.OffBookTradeType;
    this.optionPrice = res.OptionPrice;
    this.originalTradeDate = res.OriginalTradeDate;
    this.originalTradeID = res.OriginalTradeID;
    this.parPrice = res.ParPrice;
    this.price = res.Price;
    this.priceType = res.PriceType;
    this.qty = res.Qty;
    this.rate = res.Rate;
    this.sender = res.Sender;
    this.settlementCurrency = res.SettlementCurrency;
    this.settlementDate = res.SettlementDate;
    this.spread = res.Spread;
    this.startCash = res.StartCash;
    this.status = res.Status;
    this.strategyLinkID = res.StrategyLinkID;
    this.strategyType = res.StrategyType;
    this.time = res.Time;
    this.tradeID = res.TradeID;
    this.traderGroupCode = res.TraderGroupCode;
    this.traderID = res.TraderID;
    this.underlyingPrice = res.UnderlyingPrice;
    this.volatility = res.Volatility;
    this.yield = res.Yield;
  }
  public account: string;
  public benchmarkInstrumentID: number;
  public benchmarkPrice: number;
  public buySide: boolean;
  public capacity: number;
  public cleanPrice: number;
  public clientRef: string;
  public counterPartyDeclineText: null | string;
  public counterpartyFirmID: string;
  public counterpartyRef: null | number | string;
  public delta: number;
  public doNotPublish: boolean;
  public endAccruedInterestAmount: string;
  public endCash: number;
  public endCleanPrice: number;
  public endSettlementDate: string;
  public endYield: number;
  public firmTradeID: string;
  public instrumentCurrency: null | string;
  public instrumentID: number;
  public internalCounterparty: boolean;
  public isMarketOpsRequest: boolean;
  public key: string;
  public offBookTradeAttribute: number;
  public offBookTradeType: number;
  public optionPrice: number;
  public originalTradeDate: string;
  public originalTradeID: number;
  public parPrice: number;
  public price: number;
  public priceType: number;
  public qty: number;
  public rate: number;
  public sender: boolean;
  public settlementCurrency: null | string;
  public settlementDate: string;
  public spread: number;
  public startCash: number;
  public status: number;
  public strategyLinkID: null | string;
  public strategyType: number;
  public time: string;
  public tradeID: string;
  public traderGroupCode: string;
  public traderID: string;
  public underlyingPrice: number;
  public volatility: number;
  public yield: number;
}
export class itacOffBookTrades {
  constructor(
    api: offbookApiResponse | null,
    socket: offbookSocketResponse | null
  ) {
    this.api = api;
    this.socket = socket;
  }
  public api: offbookApiResponse | null;
  public socket: offbookSocketResponse | null;
}
