
import { Component, Prop, Vue } from "vue-property-decorator";
import { Deal as deal } from "@/models/RootObjects/Deal";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import Auth from "@/store/modules/Auth";
import { io, Socket } from "socket.io-client";
import _ from "lodash";

interface DealsSocketData {
  BookingFeeFlag: string;
  BuySell: string;
  ClearingMember: string;
  ClearingMemberFees: number;
  Commited: string;
  Contract: string;
  ContractDisplay: {
    CONTRACT_TYPE: number;
    ContractDate: string;
    ContractDisplay: string;
    Flag: string;
    Instrument: string;
    Strike: number;
  };
  DealConsideration: number;
  DealSeq: number;
  DealTime: string;
  Dealer: string;
  DealtPrice: number;
  DealtRate: number;
  ExchangeRef: string;
  ExchangeSuffix: number;
  Fee: number;
  FeeDelta: number;
  MakerQty: number;
  MarkToMarket: number;
  Member: string;
  MemberFees: number;
  OrderSeq: number;
  Origin: string;
  Portfolio: string;
  Principal: string;
  PrincipleAgency: string;
  ProfitCentre: string;
  Quantity: number;
  Reason: string;
  ReportOnlyQty: number;
  Settled: string;
  SettlementDate: string;
  SubAccount: string;
  UserRef: string;
}

@Component
export default class DealsTable extends Vue {
  /* @Prop() readonly myTable!: deal[]; */
  expandedGroups: string[] = [];
  tableData: DealsSocketData[] = [];
  serverString = `${process.env.VUE_APP_SERVER_REALTIME}:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  socket: Socket | null = null;
  loading = false;
  numberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  headers = [
    {
      text: "Member",
      align: "start",
      value: "Member",
      groupable: false,
    },
    {
      text: "Principle",
      align: "start",
      value: "Principal",
      groupable: false,
    },
    {
      text: "Instrument",
      align: "start",
      value: "ContractDisplay.Instrument",
      groupable: false,
    },
    {
      text: "Contract Date",
      align: "start",
      value: "ContractDisplay.ContractDate",
      groupable: false,
    },
    {
      text: "Strike",
      align: "start",
      value: "ContractDisplay.Strike",
      groupable: false,
    },
    {
      text: "Sub Acc",
      align: "start",
      value: "SubAccount",
      groupable: false,
    },
    {
      text: "Exchange Ref",
      align: "start",
      value: "ExchangeRef",
      groupable: true,
    },
    {
      text: "Price",
      align: "start",
      value: "DealtPrice",
      groupable: true,
    },
    {
      text: "BuySell",
      align: "start",
      value: "BuySell",
      groupable: true,
    },
    {
      text: "Quantity",
      align: "start",
      value: "Quantity",
      groupable: true,
    },
    {
      text: "Time",
      align: "start",
      value: "DealTime",
      groupable: true,
    },
    {
      text: "User Ref",
      align: "start",
      value: "UserRef",
      groupable: true,
    },
    /* {
      text: "Zero Fees",
      align: "start",
      value: "BookingFeeFlag",
      groupable: true,
    }, */
  ];
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }

  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      //console.log("Mounted breakdown ");
      // console.log("AFTER SOCKET LISTEN MOUNTED");
      //const userRoleWs = new UserRoleWs();
      //const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
      // return Promise.reject(err);
      //});
      //this.userRole = userRoleResp;
      //console.log("User Roles : ", this.userRole);
      this.socketListen();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  socketListen(): void {
    if (this.getUserAuthResponse == null) {
      console.log("user auth null");
      return;
    }
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );
    this.subscribeSocketEvents();
    this.socket.connect();
  }

  fetchData() {
    if (this.socket) this.socket.emit("DealsInit");
  }

  subscribeSocketEvents(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    console.log("Subscribe to on listen events");
    this.socket.on("DealsInit", (message: DealsSocketData[]) => {
      //this.updateData(message);
      //console.log("Message INIT data ", message);
      this.tableData = [];
      message.forEach((e) => {
        this.tableData.push(e);
      });
    });
    this.socket.on("DealUpdate", (message: DealsSocketData) => {
      this.updateData(message);
      //console.log("Message Update data ", message);
    });
    this.socket.on("AccumulateDealDelete", (message: number[]) => {
      //this.updateData(message);
      //console.log("Message Update data ", message);
      this.tableData = _.reject(this.tableData, (item) =>
        message.includes(item.DealSeq)
      );
    });
    this.socket.on("DealsDelete", (message: number) => {
      //this.updateData(message);
      //console.log("Message Update data ", message);
      this.tableData = _.reject(
        this.tableData,
        (item) => item.DealSeq == message
      );
    });
    this.socket.on("ready", () => {
      console.log("Connecting deals socket ");
      this.subscribeSocket();
    });
    this.socket.on("disconnect", (error) => {
      console.warn("deals disconnect", error);
    });
    this.socket.on("connect_error", (err: Error) => {
      console.error("Error connecting socket.. ", err);
    });
  }

  subscribeSocket(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    //console.log("Subscribe Socket ");
    this.socket.emit("DealsSubscribe");
    this.socket.emit("DealsInit");
    //this.socket.emit("SubscribeGeneric", this.getUserAuthResponse.uniqueKey);
  }

  public updateData(data: DealsSocketData): void {
    // console.log("Updating ");
    //const newData = JSON.parse(data);
    const match = _.find(this.tableData, {
      DealSeq: data.DealSeq,
    });
    // console.log("Match ", match);
    if (match) {
      _.extend(match, data);
    } else {
      this.tableData.push(data);
    }
  }
}
