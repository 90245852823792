
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { CommodityDepthWs } from "@/DataAccess/WebServices/AgriBase/CommodityDepthWs";
import { CommodityDepth } from "@/models/RootObjects/CommodityDepth";
import CustomChart from "@/components/CommodityPricing/CustomChart.vue";
// import { RealTimePrice } from "@/models/RootObjects/RealTimePricesData";
import { Socket, io } from "socket.io-client";
import { DefaultEventsMap } from "socket.io-client/build/typed-events";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import Auth from "@/store/modules/Auth";
import { MarketData } from "./NewCommodityPricing.vue";

const cp = new CommodityDepthWs();
interface DepthRow {
  BidPrice: number;
  BidQty: number;
  Buyer: string;
  OfferQty: number;
  SellPrice: number;
  Seller: string;
}
export interface DepthRowUpdate {
  UpdateType: DepthRowUpdateInstruction;
  UpdateValue: string | number;
  RowNumber: number;
}
export enum DepthRowUpdateInstruction {
  CLEAR = 0,
  BUYER = 1,
  BID = 2,
  BID_QTY = 3,
  OFFER_QTY = 4,
  OFFER = 5,
  SELLER = 6,
}
const DepthRowUpdateMapping: Record<
  DepthRowUpdateInstruction,
  keyof DepthRow | null
> = {
  [DepthRowUpdateInstruction.CLEAR]: null, // Not needed as "CLEAR" is handled separately
  [DepthRowUpdateInstruction.BUYER]: "Buyer",
  [DepthRowUpdateInstruction.BID]: "BidPrice",
  [DepthRowUpdateInstruction.BID_QTY]: "BidQty",
  [DepthRowUpdateInstruction.OFFER_QTY]: "OfferQty",
  [DepthRowUpdateInstruction.OFFER]: "SellPrice",
  [DepthRowUpdateInstruction.SELLER]: "Seller",
};

@Component({
  components: {
    CustomChart,
  },
})
export default class Depth extends Vue {
  @Prop({
    default: null,
    required: true,
  })
  readonly activeObj!: { rtp: MarketData } | null;
  @Prop() readonly instrument!: string;
  @Prop() readonly contract!: string;
  myData: CommodityDepth[] = [];

  serverString = `${process.env.VUE_APP_SERVER_REALTIME}:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  // serverString = `http://localhost:${process.env.VUE_APP_SOCKET_REALTIME_PORT}`;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null = null;

  get getItems(): DepthRow[] {
    return this.originalDepth;
  }
  unmounted() {
    console.error("Disconnected comp");
  }

  @Watch("activeObj.rtp.DisplaySeq", {
    immediate: true,
  })
  activeObjChanged(
    newVal: number | undefined,
    oldVal: number | undefined
  ): void {
    console.log("New Val ", newVal);
    console.log("Old val", oldVal);

    if (newVal == null) {
      //unsub
      this.originalDepth.splice(0);
      if (oldVal) {
        this.unsubDepth(oldVal);
      }
    } else {
      if (oldVal && newVal != oldVal) {
        this.originalDepth.splice(0);
        this.unsubDepth(oldVal);
        this.subDepth(newVal);
      } else if (newVal) {
        this.subDepth(newVal);
        console.error("trust no new or old", newVal, oldVal);
      }
    }
    // this.pullData();
  }
  updateRow(update: DepthRowUpdate) {
    const fieldToUpdate = DepthRowUpdateMapping[update.UpdateType];
    if (update.UpdateType === DepthRowUpdateInstruction.CLEAR) {
      this.originalDepth.splice(update.RowNumber, 1);
    } else if (fieldToUpdate) {
      // check if the row exists, if not create an empty object for that row
      if (this.originalDepth[update.RowNumber] === undefined) {
        this.originalDepth[update.RowNumber] = {
          Buyer: "",
          BidPrice: 0,
          BidQty: 0,
          Seller: "",
          SellPrice: 0,
          OfferQty: 0,
        };
        //toastStore.addToast("warning", "Adding Row for depth update")
      }
      if (fieldToUpdate === "Buyer" || fieldToUpdate === "Seller") {
        this.originalDepth[update.RowNumber][fieldToUpdate] =
          update.UpdateValue as string;
      } else {
        this.originalDepth[update.RowNumber][fieldToUpdate] =
          update.UpdateValue as number;
      }
    } else {
      console.warn("Failed update - ", update);
    }
  }
  divideSmallLargeEqual(left: number, right: number, parts: number): number[] {
    const result: number[] = [];
    const delta: number = (right - left) / (parts - 1);
    while (left < right) {
      result.push(Math.floor(left));
      const dummy: number = +left + +delta;
      left = dummy;
    }
    result.push(Math.ceil(right));
    console.log("Splitted array ", result);
    return result;
  }
  get graphHeaders(): string[] {
    // if (this.myData.length > 0) {
    //   let small: number, large: number, i: number;
    //   small = large = this.myData[0].buy;

    //   //check buy small/large
    //   for (i = 1; i < this.myData.length; ++i) {
    //     if (this.myData[i].buy > large) {
    //       large = this.myData[i].buy;
    //     }
    //     if (this.myData[i].buy < small && this.myData[i].buy != 0) {
    //       small = this.myData[i].buy;
    //     }
    //   }
    //   //check sell small/large
    //   for (i = 1; i < this.myData.length; ++i) {
    //     if (this.myData[i].sell > large) {
    //       large = this.myData[i].sell;
    //     }
    //     if (this.myData[i].sell < small && this.myData[i].sell != 0) {
    //       small = this.myData[i].sell;
    //     }
    //   }
    //   const headers = this.divideSmallLargeEqual(small, large, 4);
    //   return headers;
    // }
    return [];
    // return [
    //   'Jan',
    //   'Feb',
    //   'Mar',
    //   'Apr',
    //   'May',
    //   'Jun',
    //   'Jul',
    //   'Aug',
    //   'Sep',
    //   'Oct',
    //   'Nov',
    //   'Dec'
    // ]
  }
  // get myDataset() {
  //   return [
  //     {
  //       label: "Stock A",
  //       fill: false,
  //       lineTension: 0.1,
  //       backgroundColor: "rgba(225,0,0,0.4)",
  //       borderColor: "red", // The main line color
  //       borderCapStyle: "",
  //       borderDash: [], // try [5, 15] for instance
  //       borderDashOffset: 0.0,
  //       borderJoinStyle: "miter",
  //       pointBorderColor: "black",
  //       pointBackgroundColor: "white",
  //       pointBorderWidth: 1,
  //       pointHoverRadius: 8,
  //       pointHoverBackgroundColor: "yellow",
  //       pointHoverBorderColor: "brown",
  //       pointHoverBorderWidth: 2,
  //       pointRadius: 4,
  //       pointHitRadius: 10,
  //       // notice the gap in the data and the spanGaps: true
  //       // eslint-disable-next-line no-sparse-arrays
  //       data: [65, 59, 80, 81, 56, 55, 40, , 60, 55, 30, 78],
  //       spanGaps: true
  //     },
  //     {
  //       label: "Stock B",
  //       fill: true,
  //       lineTension: 0.1,
  //       backgroundColor: "rgba(167,105,0,0.4)",
  //       borderColor: "rgb(167, 105, 0)",
  //       borderCapStyle: "butt",
  //       borderDash: [],
  //       borderDashOffset: 0.0,
  //       borderJoinStyle: "miter",
  //       pointBorderColor: "white",
  //       pointBackgroundColor: "black",
  //       pointBorderWidth: 1,
  //       pointHoverRadius: 8,
  //       pointHoverBackgroundColor: "brown",
  //       pointHoverBorderColor: "yellow",
  //       pointHoverBorderWidth: 2,
  //       pointRadius: 4,
  //       pointHitRadius: 10,
  //       // notice the gap in the data and the spanGaps: false
  //       // eslint-disable-next-line no-sparse-arrays
  //       data: [10, 20, 60, 95, 64, 78, 90, , 70, 40, 70, 89],
  //       spanGaps: false
  //     }
  //   ];
  // }
  headers = [
    {
      text: "Buy",
      align: "left",
      value: "buyWho",
      groupable: false,
      class: "depthHeader",
    },
    {
      text: "B-Q",
      align: "right",
      value: "buyQty",
      groupable: false,
      class: "depthHeader",
    },
    {
      text: "B-P",
      align: "right",
      value: "buy",
      groupable: false,
      class: "depthHeader",
    },
    {
      text: "S-P",
      align: "right",
      value: "sell",
      groupable: false,
      class: "depthHeader",
    },
    {
      text: "S-Q",
      align: "right",
      value: "sellQty",
      groupable: false,
      class: "depthHeader",
    },
    {
      text: "Sell",
      align: "right",
      value: "sellWho",
      groupable: false,
      class: "depthHeader",
    },
  ];
  async mounted() {
    await this.socketListen();
  }
  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }
  originalDepth: DepthRow[] = [];
  subDepth(id: number | undefined) {
    if (this.activeObj != undefined) {
      this.socket?.emit("DepthSubscribe", this.activeObj.rtp.DisplaySeq);
    } else if (id) {
      this.socket?.emit("DepthSubscribe", id);
    }
  }
  unsubDepth(id: number) {
    this.socket?.emit("DepthUnsubscribe", id);
  }

  async socketListen(): Promise<void> {
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse?.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );
    this.socket.on("ready", () => {
      this.subDepth(undefined);
    });
    this.socket.on("RealtimeDepthUpdate", (msg: DepthRowUpdate[]) => {
      msg.forEach((e) => this.updateRow(e));
    });
    this.socket.on("DepthInit", (msg: DepthRow[]) => {
      this.originalDepth = msg;
    });
    this.socket.open();
  }
}
