
import { Component, Vue } from "vue-property-decorator";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Dash from "@/store/modules/Dash";
import { Report } from "@/models/RootObjects/Reports";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import dayjs from "dayjs";

@Component({
  components: {
    VuePdfApp,
  },
})
export default class Reports extends Vue {
  open = false;
  pdfBuffer: ArrayBuffer | null = null;
  currentReport: Report | null = null;
  get getCurrentReports(): Report[] {
    return Dash.getReport
      .filter((e) => e.category_id == Number(this.$route.query.id as string))
      .sort((a, b) => {
        if (dayjs(a.created_at) > dayjs(b.created_at)) {
          return -1;
        } else if (dayjs(a.created_at) < dayjs(b.created_at)) {
          return 1;
        } else {
          return 0;
        }
      });
  }
  get pdfLink(): string {
    if (this.currentReport == null) {
      return "";
    } else {
      return process.env.VUE_APP_SERVER_BASE + this.currentReport.content;
    }
  }
  async openPdf(rep: Report): Promise<void> {
    // await this.loadPdf();
    this.currentReport = rep;
    this.open = true;
    console.log("Clicked on rep: ", rep);
  }
  async loadPdf(): Promise<void> {
    const res = await axiosNoAuth
      .get(this.pdfLink, {
        headers: {
          "Content-Type": "application/pdf",
        },
      })
      .catch((err) => {
        console.log("Cant download", err);
        return Promise.reject(err);
      });
    // const buffer = await (res.data as Blob).arrayBuffer().catch((err) => {
    //   console.log("Cant transform blob ", err);
    //   return Promise.reject(err);
    // });
    this.pdfBuffer = res.data;
    return Promise.resolve();
  }
}
