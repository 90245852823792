
import { Component, Vue } from "vue-property-decorator";
import Auth from "@/store/modules/Auth";
import { Socket, io } from "socket.io-client";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { dealReason, itacDeals } from "@/models/RootObjects/ItacDeals";
import { itacPositions } from "@/models/RootObjects/ItacPositions";
import {
  itacOffBookTrades,
  offbookSocketResponse,
} from "@/models/RootObjects/ItacOffBookTrades";
import {
  itacFourEyesState,
  itacUnmatched,
} from "@/models/RootObjects/ItacUnmatched";
import _ from "lodash";
import dayjs from "dayjs";
import { ItacDealsWs } from "@/DataAccess/WebServices/AgriBase/ItacDealsWs";
const dl = new ItacDealsWs();
import { ItacPositionsWs } from "@/DataAccess/WebServices/AgriBase/ItacPositionsWs";
const ps = new ItacPositionsWs();
import { ItacUnmatchedWs } from "@/DataAccess/WebServices/AgriBase/ItacUnmatchedWs";
const um = new ItacUnmatchedWs();
import { ItacOffBookTradesWs } from "@/DataAccess/WebServices/AgriBase/ItacOffBookTradesWs";
import {
  combinedInstrument,
  itacInstruments,
  itacSocketResponse,
} from "@/models/RootObjects/ItacInstruments";
const offbook = new ItacOffBookTradesWs();

@Component
export default class Itac extends Vue {
  portalSocket: Socket | null = null;
  marketSocket: Socket | null = null;
  portalServerString =
    process.env.VUE_APP_SERVER_ITAC_IO +
    ":" +
    process.env.VUE_APP_SOCKET_PORTAL_PORT;
  marketServerString =
    process.env.VUE_APP_SERVER_ITAC_IO +
    ":" +
    process.env.VUE_APP_SOCKET_MARKET_PORT;
  // portalServerString = "http://localhost" + ":3001";
  // marketServerString = "http://localhost" + ":3003";
  //socket data
  deals: itacDeals[] = [];
  dealReasons: dealReason[] = [];
  positions: itacPositions[] = [];
  offbook: itacOffBookTrades[] = [];
  unmatched: itacUnmatched[] = [];
  unmatchedStates: itacFourEyesState[] = [];
  instruments: combinedInstrument[] = [];

  get anyLoading(): boolean {
    if (
      this.loading.deals == true ||
      this.loading.positions == true ||
      this.loading.offbook == true ||
      this.loading.unmatched == true
    ) {
      return true;
    } else return false;
  }

  get getDealReasons(): dealReason[] {
    return this.dealReasons;
  }

  loading = {
    deals: false,
    positions: false,
    offbook: false,
    unmatched: false,
  };

  dealsChannel = "TradeViewModelChannel";
  positionsChannel = "PositionViewModelChannel";
  offbookChannel = "OffBookTradeChannel";
  unmatchedChannel = "GiveUpEventViewModelChannel";

  today = dayjs().format("YYYY-MM-DD");

  async loadDeals(): Promise<void> {
    try {
      this.loading.deals = true;
      const dls = await dl.GetItacDeals(this.today, this.today).catch((err) => {
        return Promise.reject(err);
      });
      this.deals = dls;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.deals = false;
    }
  }
  async loadPositions(): Promise<void> {
    try {
      this.loading.positions = true;
      const pss = await ps
        .GetItacPositions(this.today, "Current")
        .catch((err) => {
          return Promise.reject(err);
        });
      this.positions = pss.filter((e) => {
        if (e.externalInstrumentId != null && e.instrumentDisplayName != null) {
          return e;
        }
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.positions = false;
    }
  }
  async loadUnmatched(): Promise<void> {
    try {
      this.loading.unmatched = true;
      const pss = await um.GetItacUnmatched(this.today).catch((err) => {
        return Promise.reject(err);
      });
      this.unmatched = pss;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.unmatched = false;
    }
  }
  async loadDealReasons(): Promise<void> {
    try {
      this.dealReasons = await dl
        .GetItacReasons()
        .catch((err) => Promise.reject(err));
      console.log("Loaded Reasons");
    } catch (err) {
      console.error(err);
    }
  }

  async loadUnmatchedStates(): Promise<void> {
    try {
      this.loading.unmatched = true;
      this.unmatchedStates = await um.GetItacFourEyesState().catch((err) => {
        return Promise.reject(err);
      });
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.unmatched = false;
    }
  }
  async loadOffbook(): Promise<void> {
    try {
      this.loading.offbook = true;
      const dls = await offbook
        .GetItacOffBookTrades(this.today, this.today)
        .catch((err) => {
          return Promise.reject(err);
        });
      this.offbook = dls;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading.offbook = false;
    }
  }
  get tabVal(): string {
    console.log("Route get", this.$route.query.tab);
    const temp = this.$route.query.tab as string;
    return temp;
  }
  set tabVal(tab: string) {
    console.log("Route set: ", this.$route.query, tab);
    this.$router.replace({ query: { ...this.$route.query, tab } });
  }
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }
  async mounted(): Promise<void> {
    try {
      console.log(
        "Server strings: ",
        this.marketServerString,
        this.portalServerString
      );
      if (this.getUserAuthResponseITAC == null) {
        throw "Not logged in itac";
      }
      if (this.getUserAuthResponse == null) {
        throw "Not logged in";
      }
      await this.loadDealReasons().catch((err) => Promise.reject(err));
      await this.loadUnmatchedStates().catch((err) => Promise.reject(err));
      await this.loadDeals().catch((err) => Promise.reject(err));
      await this.loadPositions().catch((err) => Promise.reject(err));
      await this.loadUnmatched().catch((err) => Promise.reject(err));
      await this.loadOffbook().catch((err) => Promise.reject(err));

      this.portalSocket = io(
        this.portalServerString +
          "?uuid=" +
          this.getUserAuthResponseITAC.uniqueKey,
        {
          autoConnect: false,
          transports: ["websocket"],
          reconnectionAttempts: 20,
          reconnectionDelay: 5000,
          reconnectionDelayMax: 10000,
        }
      );
      this.marketSocket = io(
        this.marketServerString +
          "?uuid=" +
          this.getUserAuthResponseITAC.uniqueKey,
        {
          autoConnect: false,
          transports: ["websocket"],
          reconnectionAttempts: 20,
          reconnectionDelay: 5000,
          reconnectionDelayMax: 10000,
        }
      );
      this.subscribeMarketSocketBase();
      this.subscribePortalSocketBase();
      this.marketSocket.connect();
      this.portalSocket.connect();
    } catch (err) {
      console.error(err);
    }
  }
  beforeUnmount(): void {
    if (this.portalSocket != null) {
      this.portalSocket.close();
    }
    if (this.marketSocket != null) {
      this.marketSocket.close();
    }
  }
  updateDeals(message: string): void {
    console.log("DEAL JSON ", JSON.parse(message));
    const newData = new itacDeals(JSON.parse(message));
    console.log("DEAL SOCKET RES : ", newData);
    const match = _.find(this.deals, {
      id: newData.id,
    });

    if (match) {
      _.extend(match, newData);
    } else {
      this.deals.push(newData);
    }
  }
  updatePositions(message: string): void {
    const newData = new itacPositions(JSON.parse(message));
    console.log("POSITIONS SOCKET RES : ", newData);
    const match = _.find(this.positions, {
      id: newData.id,
    });

    if (match) {
      _.extend(match, newData);
    } else {
      this.positions.push(newData);
    }
  }
  updateOffbooks(message: string): void {
    console.log("Offbook socket info: ", JSON.parse(message));
    const newData = new offbookSocketResponse(JSON.parse(message));
    // console.log("OFFBOOK SOCKET RES : ", newData);
    let match = _.find(this.offbook, {
      api: {
        tradeId: newData.tradeID,
      },
    });
    if (match) {
      if (match instanceof itacOffBookTrades) {
        _.extend(match.socket, newData);
      } else {
        console.log("Help offbook!! ");
      }
    } else {
      match = _.find(this.offbook, {
        socket: {
          tradeID: newData.tradeID,
        },
      });
      if (match) {
        if (match instanceof itacOffBookTrades) {
          _.extend(match.socket, newData);
        } else {
          console.log("Help offbook!! ");
        }
      }
      this.offbook.push(new itacOffBookTrades(null, newData));
      // console.log("Didnt match which is problem because API needs to set them");
    }
  }
  updateInstruments(data: string): void {
    // console.log("Updating ");
    const newData = new itacSocketResponse(JSON.parse(data));
    // console.log("Data : ", newData);W
    const match = _.find(this.instruments, {
      instrument: {
        instrumentID: newData.instrumentID.toString(),
      },
    });
    // console.log("Match ", match);
    if (match) {
      if (match.socket == null) {
        match.socket = newData;
      } else {
        enum direction {
          UP = "UP",
          DWN = "DOWN",
        }
        const key: {
          name: string;
          dir: direction;
        }[] = [];
        if (
          match.socket.cumulativeBestBidQty !== newData.cumulativeBestBidQty
        ) {
          // console.log("LastTradePrice diff", match.CustomID);
          const name = "socket.cumulativeBestBidQty";
          if (
            match.socket.cumulativeBestBidQty < newData.cumulativeBestBidQty
          ) {
            key.push({
              name: name,
              dir: direction.UP,
            });
          } else {
            key.push({
              name: name,
              dir: direction.DWN,
            });
          }
        }
        if (match.socket.displayBestBidPrice !== newData.displayBestBidPrice) {
          // console.log("LastTradePrice diff", match.CustomID);
          const name = "socket.displayBestBidPrice";
          if (match.socket.displayBestBidPrice < newData.displayBestBidPrice) {
            key.push({
              name: name,
              dir: direction.UP,
            });
          } else {
            key.push({
              name: name,
              dir: direction.DWN,
            });
          }
        }
        if (
          match.socket.displayBestOfferPrice !== newData.displayBestOfferPrice
        ) {
          // console.log("LastTradePrice diff", match.CustomID);
          const name = "socket.displayBestOfferPrice";
          if (
            match.socket.displayBestOfferPrice < newData.displayBestOfferPrice
          ) {
            key.push({
              name: name,
              dir: direction.UP,
            });
          } else {
            key.push({
              name: name,
              dir: direction.DWN,
            });
          }
        }
        if (
          match.socket.cumulativeBestOfferQty !== newData.cumulativeBestOfferQty
        ) {
          // console.log("LastTradePrice diff", match.CustomID);
          const name = "socket.cumulativeBestOfferQty";
          if (
            match.socket.cumulativeBestOfferQty < newData.cumulativeBestOfferQty
          ) {
            key.push({
              name: name,
              dir: direction.UP,
            });
          } else {
            key.push({
              name: name,
              dir: direction.DWN,
            });
          }
        }

        key.forEach((str) => {
          const cell = document.getElementById(
            match.instrument.securityDescription + "-" + str.name
          );
          if (str.dir == direction.UP) {
            cell?.classList.add("socketValueUpdateIncrease");
            setTimeout(() => {
              cell?.classList.remove("socketValueUpdateIncrease");
            }, 1000);
          } else {
            cell?.classList.add("socketValueUpdateDecrease");
            setTimeout(() => {
              cell?.classList.remove("socketValueUpdateDecrease");
            }, 1000);
          }
        });
        _.extend(match.socket, newData);
      }
    } else {
      console.log(
        "Shouldnt hit this as items should always be in when receiving update"
      );
    }
  }
  updateUnmatched(message: string): void {
    const newData = new itacUnmatched(JSON.parse(message));
    console.log("UNMATCHED SOCKET RES : ", newData);
    const match = _.find(this.unmatched, {
      id: newData.id,
    });

    if (match) {
      _.extend(match, newData);
    } else {
      this.unmatched.push(newData);
    }
  }
  subsribeToDeals(): void {
    if (this.portalSocket == null) return;
    this.portalSocket.emit("subscribe", this.dealsChannel);
    this.portalSocket.on(this.dealsChannel, (message: string) => {
      this.updateDeals(message);
    });
  }
  subscribeToPositions(): void {
    if (this.portalSocket == null) return;
    this.portalSocket.emit("subscribe", this.positionsChannel);
    this.portalSocket.on(this.positionsChannel, (message: string) => {
      this.updatePositions(message);
    });
  }
  subscribeToInstruments(): void {
    if (this.getUserAuthResponseITAC == null) return;
    if (this.marketSocket == null) return;
    console.log("Subscribe TOB and check localSTorage instruments");
    // this.marketSocket.emit("subscribe", "TOB");
    const it = localStorage.getItem("bvg-itac-instruments");
    if (it != null) {
      console.log("Should parse local storage instruments");
      const mem = JSON.parse(it);
      mem.forEach(
        (element: {
          instrument: itacInstruments;
          socket: itacSocketResponse | null;
        }) => {
          this.instruments.push(new combinedInstrument(element));
        }
      );
      this.instruments.forEach((e) => {
        this.subscribeToSpecificInstrument(e.instrument.instrumentID);
      });
    } else {
      console.log("No local storage instruments");
    }
  }
  subscribeToSpecificInstrument(id: string): void {
    // this.addedInstruments.forEach(elem)
    if (this.marketSocket != null) {
      if (this.getUserAuthResponseITAC != null) {
        console.log("Subscribe TOBSubAdd", id);
        this.marketSocket.emit("TOBSubAdd", id);
        // this.marketSocket.on("TOB~" + id, (message) => {
        //   this.updateInstruments(message);
        // });
      } else {
        console.log("Authentication is null");
      }
    } else {
      console.log("Socket null");
    }
  }
  unsubscribeFromSpecificInstrument(id: string): void {
    // this.addedInstruments.forEach(elem)
    if (this.marketSocket != null) {
      if (this.getUserAuthResponseITAC != null) {
        this.instruments = this.instruments.filter(
          (e) =>
            (e.instrument != null &&
              e.instrument.instrumentID.toString() != id) ||
            (e.socket != null && e.socket.instrumentID.toString() != id)
        );
        console.log("Unsubscribe TOBSubAdd", id);
        this.marketSocket.emit("TOBSubRem", id);
        localStorage.setItem(
          "bvg-itac-instruments",
          JSON.stringify(this.instruments)
        );
      }
    }
  }
  subscribeToOffbook(): void {
    if (this.portalSocket == null) return;
    this.portalSocket.emit("subscribe", this.offbookChannel);
    this.portalSocket.on(this.offbookChannel, (message: string) => {
      this.updateOffbooks(message);
    });
  }
  subscribeToUnmatched(): void {
    if (this.portalSocket == null) return;
    this.portalSocket.emit("subscribe", this.unmatchedChannel);
    this.portalSocket.on(this.unmatchedChannel, (message: string) => {
      this.updateUnmatched(message);
    });
  }
  subscribeMarketSocketBase(): void {
    if (this.marketSocket == null) {
      console.error("Cant subscribe to null");
      return;
    }
    this.marketSocket.on("connect", () => {
      console.log("COnnected socket: Market ", this.marketSocket?.id);
      if (this.getUserAuthResponseITAC == null) return;
      if (this.marketSocket == null) return;
      console.log("Market socket made it here");
      this.marketSocket.on("ready", () => {
        console.log("MARKET SOCKET READY");
        if (this.marketSocket == null) return;
        this.subscribeToInstruments();
        // this.marketSocket.on("Instruments", (message: string) => {
        //   this.updateInstruments(message);
        //   // console.log("Message data ", message);
        // });
        this.marketSocket.on("update", (message: string) => {
          // console.log("Update data ", message);
          this.updateInstruments(message);
        });
      });
    });
    this.marketSocket.on("disconnect", () => {
      console.log("Disconnect socket: ITAC");
    });
    this.marketSocket.on("error", (err: Error) => {
      console.log("Error connecting socket.. ", Object.keys(err));
    });
  }
  subscribePortalSocketBase(): void {
    if (this.portalSocket == null) {
      console.error("Cant subscribe to null");
      return;
    }
    this.portalSocket.on("connect", () => {
      console.log("COnnected socket: Portal", this.portalSocket?.id);
      if (this.getUserAuthResponseITAC == null) return;
      this.subsribeToDeals();
      this.subscribeToPositions();
      this.subscribeToOffbook();
      this.subscribeToUnmatched();
    });
    this.portalSocket.on("disconnect", () => {
      console.log("Disconnect socket: ITAC");
    });
    this.portalSocket.on("error", (err: Error) => {
      console.log("Error connecting socket.. ", Object.keys(err));
    });
  }
  changeRoute(to: string): void {
    console.log("To: ", to);
  }
  beforeEnter(to: Location, from: Location): void {
    console.log("To: ", to);
    console.log("From: ", from);
    // console.log("To: ", to);
  }
  beforeDestroy(): void {
    this.marketSocket?.disconnect();
    this.portalSocket?.disconnect();
  }
}
