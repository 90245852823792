
import { Component, Vue } from "vue-property-decorator";
import { BreakdownWs } from "@/DataAccess/WebServices/AgriBase/BreakdownWs";
import { Breakdown as breakdown } from "@/models/RootObjects/Breakdown";
import BreakdownTable from "@/components/Agris/Express/BreakdownTable.vue";
import { ClearingWs } from "@/DataAccess/WebServices/AgriBase/ClearingWs";
import { Clearing as clearing } from "@/models/RootObjects/Clearing";
import Clearing from "@/components/Agris/Express/Clearing.vue";
import { DealsWs } from "@/DataAccess/WebServices/AgriBase/DealsWs";
import { Deal as deal } from "@/models/RootObjects/Deal";
import Deal from "@/components/Agris/Express/Deals.vue";

const bd = new BreakdownWs();
const cl = new ClearingWs();
const dl = new DealsWs();

@Component({
  components: {
    BreakdownTable,
    Clearing,
    Deal,
  },
})
export default class Express extends Vue {
  public tab = "";
  breakdowns: breakdown[] = [];
  clearings: clearing[] = [];
  dealst: deal[] = [];
  loading = false;
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const bk = await bd.GetBreakdownForUser().catch((err) => {
        return Promise.reject(err);
      });
      // Weather
      this.breakdowns = bk;
      // console.log("Currencies Resp ", bk);
      const cls = await cl.GetClearingForUser().catch((err) => {
        return Promise.reject(err);
      });
      // Weather
      this.clearings = cls;
      // console.log("Currencies Resp ", cls);
      const dls = await dl.GetDealsForUser().catch((err) => {
        return Promise.reject(err);
      });
      // Weather
      this.dealst = dls;
    } catch (err) {
      console.error(err);
    } finally {
      this.loading = false;
    }

    // console.log("Currencies Resp ", dls);
  }
}
