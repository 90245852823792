/* eslint-disable no-console */
import { Device } from "@capacitor/device";
import { register } from "register-service-worker";

const shouldReg = Device.getInfo().then((devInfo) => {
  if (
    devInfo.platform == "web" &&
    (devInfo.operatingSystem == "android" ||
      devInfo.operatingSystem == "windows")
  ) {
    console.log("Register PWA service worker");
    registerSW();
  }
});
const registerSW = () =>
  register(`${process.env.BASE_URL}service-worker.js`, {
    async ready(registration): Promise<void> {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    async registered(registration) {
      console.log("Service worker has been registered.");
      setInterval(() => {
        console.log("Checked for update in registered");
        registration.update();
      }, 1000 * 60); // e.g. 1000 * 60 * 60 hourly checks
      // registration.showNotification('Registered', {
      //   body: 'Registered here',
      //   // icon: '../images/touch/chrome-touch-icon-192x192.png',
      //   vibrate: [200, 100, 200, 100, 200, 100, 200],
      //   tag: 'registrations-sample'
      // });
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log("New content is available.");
      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
// console.log("Base url: ", process.env.BASE_URL);

// }
