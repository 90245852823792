
import { Component, Vue } from "vue-property-decorator";
import { io, Socket } from "socket.io-client";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { MarketIndex } from "@/models/RootObjects/MarketIndex";
import Auth from "@/store/modules/Auth";
import _ from "lodash";
import { UserRole } from "@/models/RootObjects/UserRole";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";

@Component
export default class MarketIndexFeed extends Vue {
  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }
  loading = false;
  serverString = "https://portal.bvg.net:3015";
  socket: Socket | null = null;
  userRole: UserRole[] = [];
  tableData: MarketIndex[] = [];
  headers = [
    {
      text: "Instrument",
      value: "Indicator",
      align: "start",
      groupable: false,
    },
    {
      text: "Yesterday Close",
      value: "YesterdayClose",
      groupable: false,
    },
    {
      text: "Live",
      value: "Live",
      groupable: false,
    },
    {
      text: "Change",
      value: "Change",
      groupable: false,
    },
  ];
  log(any: any) {
    console.log(any);
  }

  socketListen(): void {
    if (this.getUserAuthResponse == null) {
      console.log("user auth null");
      return;
    }
    this.socket = io(
      this.serverString + "?uuid=" + this.getUserAuthResponse.uniqueKey,
      {
        autoConnect: false,
        transports: ["websocket"],
        reconnectionAttempts: 20,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000,
      }
    );
    // console.log("Socket listen", this.socket.id);
    this.subscribeSocketEvents();
    this.socket.connect();
    this.socket.emit("subscribe", "ChicagoPriceFeed");
    // this.socket.io.connect();
    // console.log("connected? ", this.socket.connected);
  }
  subscribeSocket(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    console.log("Subscribe Socket ");
    this.socket.emit("subscribe", "ChicagoPriceFeed");
    this.socket.emit("SubscribeGeneric", this.getUserAuthResponse.uniqueKey);
  }
  subscribeSocketEvents(): void {
    if (this.getUserAuthResponse == null) return;
    if (this.socket == null) return;
    console.log("Subscribe to on listen events");
    this.socket.on("message", (message: string) => {
      this.updateData(message);
      // console.log("Message data ", message);
    });
    this.socket.on("connect", () => {
      console.log("Connecting market socket ");
      this.subscribeSocket();
    });
    this.socket.on("disconnect", (error) => {
      console.log("Market disconnect", error);
    });
    this.socket.on("connect_error", (err: Error) => {
      console.log("Error connecting socket.. ", err);
    });
  }
  public updateData(data: string): void {
    // console.log("Updating ");
    const newData = JSON.parse(data);
    const match = _.find(this.tableData, {
      Id: newData.Id,
    });
    // console.log("Match ", match);
    if (match) {
      _.extend(match, newData);
    } else {
      this.tableData.push(newData);
    }
  }
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      console.log("Mounted price feed ");
      console.log("AFTER SOCKET LISTEN MOUNTED");
      const userRoleWs = new UserRoleWs();
      const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
        return Promise.reject(err);
      });
      this.userRole = userRoleResp;
      console.log("User Roles : ", this.userRole);
      this.socketListen();
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
  get getUserRole(): UserRole[] {
    return this.userRole;
  }
  get checkRole(): boolean {
    var arr: UserRole | undefined;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (arr = this.getUserRole.find((elem) => elem.name == "marketindexprices"))
    ) {
      return true;
    } else {
      return false;
    }
  }
}
