export class itacUnmatched {
  constructor({
    AccountSubType,
    AccountType,
    Acknowledger,
    Client,
    ClientAccountDescription,
    ContractCode,
    commissionAmount,
    ExternalInstrumentId,
    FourEyesId,
    FourEyesState,
    FromAccountId,
    Id,
    Initiator,
    InstrumentBase,
    InstrumentDisplayName,
    InstrumentExpiry,
    InstrumentType,
    Market,
    OptionType,
    Price,
    Quantity,
    Reference,
    SequenceNumber,
    State,
    Strike,
    SubscriptionGroup,
    timeCancelled,
    timeComplete,
    timeInitiated,
    tradeId,
    created_at,
    deleted_at,
    fourEyesStateValue,
    isBuy,
    updated_at,
  }: {
    AccountSubType: string;
    AccountType: string;
    Acknowledger: string;
    Client: string;
    commissionAmount: string;
    ClientAccountDescription: string;
    ContractCode: string;
    ExternalInstrumentId: string;
    FourEyesId: number;
    FourEyesState: string;
    FromAccountId: number;
    Id: number;
    Initiator: string;
    InstrumentBase: string;
    InstrumentDisplayName: string;
    InstrumentExpiry: Date;
    InstrumentType: string;
    Market: string;
    OptionType: string;
    Price: string;
    Quantity: string;
    Reference: string;
    SequenceNumber: number;
    State: number;
    Strike: string;
    SubscriptionGroup: number;
    timeCancelled: Date;
    timeComplete: Date;
    timeInitiated: Date;
    tradeId: number;
    created_at: Date;
    deleted_at: Date;
    fourEyesStateValue: string;
    isBuy: boolean;
    updated_at: Date;
  }) {
    this.accountSubType = AccountSubType;
    this.accountType = AccountType;
    this.acknowledger = Acknowledger;
    this.client = Client;
    this.commissionAmount = commissionAmount;
    this.clientAccountDescription = ClientAccountDescription;
    this.contractCode = ContractCode;
    this.externalInstrumentId = ExternalInstrumentId;
    this.fourEyesId = FourEyesId;
    this.fourEyesStateValue = fourEyesStateValue;
    this.fourEyesState = FourEyesState;
    this.fromAccountId = FromAccountId;
    this.id = Id;
    this.initiator = Initiator;
    this.instrumentBase = InstrumentBase;
    this.instrumentDisplayName = InstrumentDisplayName;
    this.instrumentExpiry = InstrumentExpiry;
    this.instrumentType = InstrumentType;
    this.market = Market;
    this.optionType = OptionType;
    this.price = Price;
    this.quantity = Quantity;
    this.reference = Reference;
    this.sequenceNumber = SequenceNumber;
    this.state = State;
    this.strike = Strike;
    this.subscriptionGroup = SubscriptionGroup;
    this.timeCancelled = timeCancelled;
    this.timeComplete = timeComplete;
    this.timeInitiated = timeInitiated;
    this.tradeId = tradeId;
    this.created_at = created_at;
    this.deleted_at = deleted_at;
    this.isBuy = isBuy;
    this.updated_at = updated_at;
  }
  public accountSubType: string;
  public accountType: string;
  public acknowledger: string;
  public client: string;
  public commissionAmount: string;
  public clientAccountDescription: string;
  public contractCode: string;
  public externalInstrumentId: string;
  public fourEyesId: number;
  public fourEyesStateValue: string;
  public fourEyesState: string;
  public fromAccountId: number;
  public id: number;
  public initiator: string;
  public instrumentBase: string;
  public instrumentDisplayName: string;
  public instrumentExpiry: Date;
  public instrumentType: string;
  public market: string;
  public optionType: string;
  public price: string;
  public quantity: string;
  public reference: string;
  public sequenceNumber: number;
  public state: number;
  public strike: string;
  public subscriptionGroup: number;
  public timeCancelled: Date;
  public timeComplete: Date;
  public timeInitiated: Date;
  public tradeId: number;
  public created_at: Date;
  public deleted_at: Date;
  public isBuy: boolean;
  public updated_at: Date;
}

export class itacFourEyesState {
  constructor({
    comment,
    name,
    value,
  }: {
    comment: string;
    name: string;
    value: number;
  }) {
    this.comment = comment;
    this.value = value;
    this.name = name;
  }

  public comment: string;
  public value: number;
  public name: string;
}
