export class itacDeals {
  constructor({
    accountId,
    accountSubType,
    accountType,
    activeQuantity,
    address,
    aggressor,
    agreedTime,
    alphaCode,
    assetClass,
    assetSubClass,
    baseRate,
    bdaCode,
    branchName,
    businessDate,
    callPut,
    capacity,
    clOrdId,
    clearingMember,
    clearingMemberName,
    client,
    clientDealId,
    clientName,
    clientNominatedMemberId,
    clientPhone,
    clientType,
    companyRegistrationNumber,
    contractSize,
    country,
    created_at,
    currency,
    dealId,
    deleted_at,
    expirationDate,
    externalInstrumentId,
    externalPositionAccount,
    firmTradeId,
    fromAccount,
    fromAccountOwner,
    fromAccountSubType,
    fromAccountType,
    fromBdaCode,
    fromBranch,
    fromCM,
    fromClientName,
    fromClientType,
    fromExternalPositionAccount,
    fromIsBeneficial,
    fromIsDiscretionary,
    fromIsNonResident,
    fromIsProfessional,
    fromIsShariah,
    fromIsStaff,
    fromPositionReason,
    fromRemainingQuantity,
    fromTM,
    fromTradeId,
    fromTradeTime,
    fromTradingUser,
    id,
    idNumber,
    initialTradeId,
    initialValue,
    instrumentBase,
    instrumentCurrency,
    instrumentSubType,
    instrumentType,
    interestRateSpread,
    inwardListed,
    isBeneficial,
    isBuy,
    isDiscretionary,
    isNonResident,
    isProfessional,
    isShariah,
    isStaff,
    isin,
    lastMkt,
    market,
    marketList,
    marketSegment,
    nextTradeIds,
    nominatedMemberId,
    offBookQuantity,
    onBookQuantity,
    optionDelta,
    origTradeId,
    originalPrice,
    originalQuantity,
    participantUnitId,
    passportNumber,
    positionReason,
    previousTradeIds,
    price,
    reference,
    remainingQuantity,
    reportedTime,
    reservedQuantity,
    sequenceNumber,
    shortName,
    strike,
    subscriptionGroup,
    text,
    timeOfEntry,
    tmBranch,
    tradeDate,
    tradeId,
    tradeReportId,
    tradeTimestamp,
    tradeType,
    trader,
    tradingMember,
    tradingMemberName,
    tradingUser,
    tsTradingHalfId,
    tsTradingLinkId,
    tsTradingMatchId,
    underlyingSymbol,
    updated_at,
    vatRegistrationNumber,
    zeroFeeFlag,
  }: {
    accountId: number;
    accountSubType: string;
    accountType: string;
    activeQuantity: string;
    address: string;
    aggressor: boolean;
    agreedTime: Date;
    alphaCode: string;
    assetClass: string;
    assetSubClass: string;
    baseRate: string;
    bdaCode: string;
    branchName: string;
    businessDate: Date;
    callPut: string;
    capacity: number;
    clOrdId: string;
    clearingMember: string;
    clearingMemberName: string;
    client: string;
    clientDealId: string;
    clientName: string;
    clientNominatedMemberId: string;
    clientPhone: string;
    clientType: string;
    companyRegistrationNumber: string;
    contractSize: string;
    country: string;
    created_at: Date;
    currency: string;
    dealId: number;
    deleted_at: Date;
    expirationDate: Date;
    externalInstrumentId: string;
    externalPositionAccount: string;
    firmTradeId: string;
    fromAccount: string;
    fromAccountOwner: string;
    fromAccountSubType: string;
    fromAccountType: string;
    fromBdaCode: string;
    fromBranch: string;
    fromCM: string;
    fromClientName: string;
    fromClientType: string;
    fromExternalPositionAccount: string;
    fromIsBeneficial: string;
    fromIsDiscretionary: string;
    fromIsNonResident: string;
    fromIsProfessional: string;
    fromIsShariah: string;
    fromIsStaff: string;
    fromPositionReason: string;
    fromRemainingQuantity: string;
    fromTM: string;
    fromTradeId: string;
    fromTradeTime: string;
    fromTradingUser: string;
    id: number;
    idNumber: string;
    initialTradeId: string;
    initialValue: string;
    instrumentBase: string;
    instrumentCurrency: string;
    instrumentSubType: string;
    instrumentType: string;
    interestRateSpread: string;
    inwardListed: boolean;
    isBeneficial: string;
    isBuy: string;
    isDiscretionary: string;
    isNonResident: string;
    isProfessional: string;
    isShariah: string;
    isStaff: string;
    isin: string;
    lastMkt: string;
    market: string;
    marketList: string;
    marketSegment: string;
    nextTradeIds: string;
    nominatedMemberId: string;
    offBookQuantity: string;
    onBookQuantity: string;
    optionDelta: string;
    origTradeId: string;
    originalPrice: string;
    originalQuantity: string;
    participantUnitId: string;
    passportNumber: string;
    positionReason: number;
    previousTradeIds: string;
    price: string;
    reference: string;
    remainingQuantity: string;
    reportedTime: string;
    reservedQuantity: string;
    sequenceNumber: number;
    shortName: string;
    strike: string;
    subscriptionGroup: number;
    text: string;
    timeOfEntry: Date;
    tmBranch: string;
    tradeDate: Date;
    tradeId: number;
    tradeReportId: string;
    tradeTimestamp: Date;
    tradeType: string;
    trader: string;
    tradingMember: string;
    tradingMemberName: string;
    tradingUser: string;
    tsTradingHalfId: string;
    tsTradingLinkId: string;
    tsTradingMatchId: string;
    underlyingSymbol: string;
    updated_at: Date;
    vatRegistrationNumber: string;
    zeroFeeFlag: boolean;
  }) {
    this.accountId = accountId;
    this.accountSubType = accountSubType;
    this.accountType = accountType;
    this.activeQuantity = activeQuantity;
    this.address = address;
    this.aggressor = aggressor;
    this.agreedTime = agreedTime;
    this.alphaCode = alphaCode;
    this.assetClass = assetClass;
    this.assetSubClass = assetSubClass;
    this.baseRate = baseRate;
    this.bdaCode = bdaCode;
    this.branchName = branchName;
    this.businessDate = businessDate;
    this.callPut = callPut;
    this.capacity = capacity;
    this.clOrdId = clOrdId;
    this.clearingMember = clearingMember;
    this.clearingMemberName = clearingMemberName;
    this.client = client;
    this.clientDealId = clientDealId;
    this.clientName = clientName;
    this.clientNominatedMemberId = clientNominatedMemberId;
    this.clientPhone = clientPhone;
    this.clientType = clientType;
    this.companyRegistrationNumber = companyRegistrationNumber;
    this.contractSize = contractSize;
    this.country = country;
    this.created_at = created_at;
    this.currency = currency;
    this.dealId = dealId;
    this.deleted_at = deleted_at;
    this.expirationDate = expirationDate;
    this.externalInstrumentId = externalInstrumentId;
    this.externalPositionAccount = externalPositionAccount;
    this.firmTradeId = firmTradeId;
    this.fromAccount = fromAccount;
    this.fromAccountOwner = fromAccountOwner;
    this.fromAccountSubType = fromAccountSubType;
    this.fromAccountType = fromAccountType;
    this.fromBdaCode = fromBdaCode;
    this.fromBranch = fromBranch;
    this.fromCM = fromCM;
    this.fromClientName = fromClientName;
    this.fromClientType = fromClientType;
    this.fromExternalPositionAccount = fromExternalPositionAccount;
    this.fromIsBeneficial = fromIsBeneficial;
    this.fromIsDiscretionary = fromIsDiscretionary;
    this.fromIsNonResident = fromIsNonResident;
    this.fromIsProfessional = fromIsProfessional;
    this.fromIsShariah = fromIsShariah;
    this.fromIsStaff = fromIsStaff;
    this.fromPositionReason = fromPositionReason;
    this.fromRemainingQuantity = fromRemainingQuantity;
    this.fromTM = fromTM;
    this.fromTradeId = fromTradeId;
    this.fromTradeTime = fromTradeTime;
    this.fromTradingUser = fromTradingUser;
    this.id = id;
    this.idNumber = idNumber;
    this.initialTradeId = initialTradeId;
    this.initialValue = initialValue;
    this.instrumentBase = instrumentBase;
    this.instrumentCurrency = instrumentCurrency;
    this.instrumentSubType = instrumentSubType;
    this.instrumentType = instrumentType;
    this.interestRateSpread = interestRateSpread;
    this.inwardListed = inwardListed;
    this.isBeneficial = isBeneficial;
    this.isBuy = isBuy;
    this.isDiscretionary = isDiscretionary;
    this.isNonResident = isNonResident;
    this.isProfessional = isProfessional;
    this.isShariah = isShariah;
    this.isStaff = isStaff;
    this.isin = isin;
    this.lastMkt = lastMkt;
    this.market = market;
    this.marketList = marketList;
    this.marketSegment = marketSegment;
    this.nextTradeIds = nextTradeIds;
    this.nominatedMemberId = nominatedMemberId;
    this.offBookQuantity = offBookQuantity;
    this.onBookQuantity = onBookQuantity;
    this.optionDelta = optionDelta;
    this.origTradeId = origTradeId;
    this.originalPrice = originalPrice;
    this.originalQuantity = originalQuantity;
    this.participantUnitId = participantUnitId;
    this.passportNumber = passportNumber;
    this.positionReason = positionReason;
    this.previousTradeIds = previousTradeIds;
    this.price = price;
    this.reference = reference;
    this.remainingQuantity = remainingQuantity;
    this.reportedTime = reportedTime;
    this.reservedQuantity = reservedQuantity;
    this.sequenceNumber = sequenceNumber;
    this.shortName = shortName;
    this.strike = strike;
    this.subscriptionGroup = subscriptionGroup;
    this.text = text;
    this.timeOfEntry = timeOfEntry;
    this.tmBranch = tmBranch;
    this.tradeDate = tradeDate;
    this.tradeId = tradeId;
    this.tradeReportId = tradeReportId;
    this.tradeTimestamp = tradeTimestamp;
    this.tradeType = tradeType;
    this.trader = trader;
    this.tradingMember = tradingMember;
    this.tradingMemberName = tradingMemberName;
    this.tradingUser = tradingUser;
    this.tsTradingHalfId = tsTradingHalfId;
    this.tsTradingLinkId = tsTradingLinkId;
    this.tsTradingMatchId = tsTradingMatchId;
    this.underlyingSymbol = underlyingSymbol;
    this.updated_at = updated_at;
    this.vatRegistrationNumber = vatRegistrationNumber;
    this.zeroFeeFlag = zeroFeeFlag;
  }
  public accountId: number;
  public accountSubType: string;
  public accountType: string;
  public activeQuantity: string;
  public address: string;
  public aggressor: boolean;
  public agreedTime: Date;
  public alphaCode: string;
  public assetClass: string;
  public assetSubClass: string;
  public baseRate: string;
  public bdaCode: string;
  public branchName: string;
  public businessDate: Date;
  public callPut: string;
  public capacity: number;
  public clOrdId: string;
  public clearingMember: string;
  public clearingMemberName: string;
  public client: string;
  public clientDealId: string;
  public clientName: string;
  public clientNominatedMemberId: string;
  public clientPhone: string;
  public clientType: string;
  public companyRegistrationNumber: string;
  public contractSize: string;
  public country: string;
  public created_at: Date;
  public currency: string;
  public dealId: number;
  public deleted_at: Date;
  public expirationDate: Date;
  public externalInstrumentId: string;
  public externalPositionAccount: string;
  public firmTradeId: string;
  public fromAccount: string;
  public fromAccountOwner: string;
  public fromAccountSubType: string;
  public fromAccountType: string;
  public fromBdaCode: string;
  public fromBranch: string;
  public fromCM: string;
  public fromClientName: string;
  public fromClientType: string;
  public fromExternalPositionAccount: string;
  public fromIsBeneficial: string;
  public fromIsDiscretionary: string;
  public fromIsNonResident: string;
  public fromIsProfessional: string;
  public fromIsShariah: string;
  public fromIsStaff: string;
  public fromPositionReason: string;
  public fromRemainingQuantity: string;
  public fromTM: string;
  public fromTradeId: string;
  public fromTradeTime: string;
  public fromTradingUser: string;
  public id: number;
  public idNumber: string;
  public initialTradeId: string;
  public initialValue: string;
  public instrumentBase: string;
  public instrumentCurrency: string;
  public instrumentSubType: string;
  public instrumentType: string;
  public interestRateSpread: string;
  public inwardListed: boolean;
  public isBeneficial: string;
  public isBuy: string;
  public isDiscretionary: string;
  public isNonResident: string;
  public isProfessional: string;
  public isShariah: string;
  public isStaff: string;
  public isin: string;
  public lastMkt: string;
  public market: string;
  public marketList: string;
  public marketSegment: string;
  public nextTradeIds: string;
  public nominatedMemberId: string;
  public offBookQuantity: string;
  public onBookQuantity: string;
  public optionDelta: string;
  public origTradeId: string;
  public originalPrice: string;
  public originalQuantity: string;
  public participantUnitId: string;
  public passportNumber: string;
  public positionReason: number;
  public previousTradeIds: string;
  public price: string;
  public reference: string;
  public remainingQuantity: string;
  public reportedTime: string;
  public reservedQuantity: string;
  public sequenceNumber: number;
  public shortName: string;
  public strike: string;
  public subscriptionGroup: number;
  public text: string;
  public timeOfEntry: Date;
  public tmBranch: string;
  public tradeDate: Date;
  public tradeId: number;
  public tradeReportId: string;
  public tradeTimestamp: Date;
  public tradeType: string;
  public trader: string;
  public tradingMember: string;
  public tradingMemberName: string;
  public tradingUser: string;
  public tsTradingHalfId: string;
  public tsTradingLinkId: string;
  public tsTradingMatchId: string;
  public underlyingSymbol: string;
  public updated_at: Date;
  public vatRegistrationNumber: string;
  public zeroFeeFlag: boolean;
}
// export class combinedDeal {
//   public deal: itacDeals;
//   public socket:
// }
export class dealReason {
  constructor({
    comment,
    name,
    value,
  }: {
    comment: string;
    name: string;
    value: number;
  }) {
    this.name = name;
    this.comment = comment;
    this.value = value;
  }
  public name: string;
  public comment: string;
  public value: number;
}
