var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0 sortable searchable rounded-0",attrs:{"mobile-breakpoint":"0","headers":_vm.myHeaders,"calculate-widths":"","dense":"","fixed-header":"","height":"64vh","items":_vm.myTable,"loading":_vm.loading,"item-key":"Contract","item-class":"rowClass","group-desc":false,"custom-group":_vm.customGroupFunctionSelector,"group-by":"Instrument","hide-default-header":"","hide-default-footer":"","footer-props":{
    itemsPerPageOptions: [_vm.myTable.length],
  },"items-per-page":_vm.myTable.length},scopedSlots:_vm._u([{key:"footer",fn:function({ props }){return [_c('h6',{staticClass:"title primary lighten-1 white--text text-body-2 py-2"},[_vm._v(" "+_vm._s(props.options.itemsPerPage - _vm.getDeletedGroupsLength)+" items ")])]}},{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(header,i){return _c('th',{key:i,staticClass:"primary lighten-1 py-4 px-2 font-weight-bold text-right white--text",staticStyle:{"top":"-2px"},attrs:{"sorted":true}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},{key:"group",fn:function(props){return [(props.items.length > 0)?_c('tr',{key:`${props.group}-group`,staticClass:"header-commodities primary rounded-0",attrs:{"id":`${props.group}-group`},on:{"click":function($event){return _vm.clickedHeader(props.group)}}},[_c('td',{staticClass:"text-left white--text",attrs:{"colspan":props.headers.length}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(
                  _vm.expandedGroups.find((e) => e == props.group) == undefined
                )?_c('v-icon',{staticClass:"text-right",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{staticClass:"text-right",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Instrument: "+_vm._s(props.group)+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteGroup(props.group)}}},[_c('v-icon',{staticClass:"text-right",attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1)],1)],1)]):_vm._e(),(
        _vm.expandedGroups.find((e) => e == props.group) == undefined
          ? false
          : true
      )?_vm._l((props.items),function(item){return _c('tr',{key:`${item.Contract}-${item.DisplaySeq}`,attrs:{"id":`${item.Contract}-${item.DisplaySeq}`},on:{"click":function($event){return _vm.openContextRow(
            {
              rtp: item,
            },
            $event
          )},"contextmenu":function($event){return _vm.openContextRow(item, $event)}}},_vm._l((props.headers),function(header){return _c('td',{key:header.value,staticClass:"text-left px-1 text-caption",attrs:{"id":item.Contract + '-' + header.value}},[(header.value == 'actions')?_c('v-btn',{staticClass:"float-right",attrs:{"icon":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.deleteRow(item)}}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-delete ")])],1):(header.value == 'Change')?_c('span',{staticClass:"text-no-wrap float-right",class:{
              pos: item[header.value] > 0 ? true : false,
              neg: item[header.value] < 0 ? true : false,
            }},[_vm._v(" "+_vm._s(item[header.value].toFixed(2)))]):(header.value == 'Bid')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(header.value == 'Offer')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.checkDelayed() == false ? item[header.value].toFixed(2) : "0.00")+" ")]):(header.value == 'Hi')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(header.value == 'Lo')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(header.value == 'Volume')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(header.value == 'OpenPrice')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(header.value == 'Last')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value].toFixed(2))+" ")]):(
              header.value == 'ContractDate' ||
              header.value == 'QtyOffer' ||
              header.value == 'QtyBid'
            )?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" "+_vm._s(header.value)+" ")])],1)}),0)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }