export class WeatherStationData {
  constructor(res: {
    avgReading: number;
    id: number;
    maxReading: number;
    minReading: number;
    timestamp: Date;
    unit: string;
  }) {
    this.avgReading = res.avgReading;
    this.id = res.id;
    this.maxReading = res.maxReading;
    this.minReading = res.minReading;
    this.timestamp = res.timestamp;
    this.unit = res.unit;
  }

  public avgReading: number;
  public id: number;
  public maxReading: number;
  public minReading: number;
  public timestamp: Date;
  public unit: string;
}
