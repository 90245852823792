var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"flatTable",attrs:{"dense":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.getPrecipitation == null ? [] : _vm.getPrecipitation.precipitationData,"calculate-widths":"","group-desc":false,"items-per-page":-1,"group-by":"province","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.text,staticClass:"white--text primary lighten-1 text-no-wrap py-2",class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"group",fn:function(props){return [_c('tr',{staticClass:"header-commodities primary rounded-0",on:{"click":function($event){return _vm.clickedHeader(props.group)}}},[_c('td',{staticClass:"text-left white--text",attrs:{"colspan":props.headers.length}},[_c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[(
                  _vm.expandedGroups.find((e) => e == props.group) == undefined
                )?_c('v-icon',{staticClass:"text-right",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{staticClass:"text-right",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Province: "+_vm._s(props.group)+" ")])],1)],1)],1)]),(
        _vm.expandedGroups.find((e) => e == props.group) == undefined
          ? false
          : true
      )?_vm._l((props.items),function(item){return _c('tr',{key:item.id},_vm._l((props.headers),function(header){return _c('td',{key:header.value,staticClass:"text-left px-1 text-caption",attrs:{"id":item.id + '-' + header.value}},[(header.value == 'name')?_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")]):(
              header.value == 'today' ||
              header.value == 'day4' ||
              header.value == 'day5' ||
              header.value == 'day14Total' ||
              header.value == 'dayTotal' ||
              header.value == 'day30Total' ||
              header.value == 'seasonTotal' ||
              header.value == 'prevSeasonTotal' ||
              header.value == 'prevSeasonTotalToDate'
            )?_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.checkWeatherData() == false || _vm.checkWeatherTier4() == true || _vm.checkWeatherTier2() == true || _vm.checkWeatherTier3() == true ? _vm.value : " ")+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }