
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Chart,
  ChartData,
  ChartOptions,
  ChartDataset,
  Tick,
  Point,
} from "chart.js";
import { CommodityDepth } from "@/models/RootObjects/CommodityDepth";

const buyDataset: ChartDataset = {
  label: "Buy",
  fill: true,
  // lineTension: 0,
  // cubicInterpolationMode: "monotone",
  backgroundColor: "rgba(0,255,0,0.4)",
  borderColor: "rgb(0,255,0)",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "white",
  pointBackgroundColor: "black",
  pointBorderWidth: 1,
  pointHoverRadius: 8,
  pointHoverBackgroundColor: "brown",
  pointHoverBorderColor: "yellow",
  pointHoverBorderWidth: 2,
  pointRadius: 4,
  pointHitRadius: 10,
  // notice the gap in the data and the spanGaps: false
  // eslint-disable-next-line no-sparse-arrays
  data: [],
  spanGaps: false,
};
const sellDataset: ChartDataset = {
  label: "Sell",
  fill: true,
  // cubicInterpolationMode: "monotone",
  // lineTension: 0,
  backgroundColor: "rgba(255,0,0,0.4)",
  borderColor: "rgb(167, 105, 0)",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "white",
  pointBackgroundColor: "black",
  pointBorderWidth: 1,
  pointHoverRadius: 8,
  pointHoverBackgroundColor: "brown",
  pointHoverBorderColor: "yellow",
  pointHoverBorderWidth: 2,
  pointRadius: 4,
  pointHitRadius: 10,
  // notice the gap in the data and the spanGaps: false
  // eslint-disable-next-line no-sparse-arrays
  data: [],
  spanGaps: false,
};

@Component
export default class CustomChart extends Vue {
  @Prop({ default: [] }) readonly labels!: Array<string>;
  @Prop({ default: [] }) readonly data!: Array<CommodityDepth>;

  xMin = 0;
  xMax = 0;
  yMin = 0;
  yMax = 0;

  // @Watch("data", {
  //   immediate: true,
  //   deep: true
  // })
  // dataChanged(newVal: Array<CommodityDepth>) {
  //   if (newVal != undefined && newVal.length > 0) {
  //     console.log("Create chart -", newVal, this.buildDataset, this.labels);
  //     // this.createChart({
  //     //   datasets: this.buildDataset,
  //     //   labels: this.labels
  //     // });
  //   }
  // }
  mounted(): void {
    this.createChart({
      datasets: this.buildDataset,
      labels: this.labels,
    });
  }
  // get bigSmallBuy() {

  // }
  // get bigSmallSell() {

  // }

  get buildDataset(): ChartDataset[] {
    const buyArr: Point[] = [];
    const sellArr: Point[] = [];
    this.data.forEach((element) => {
      if (element.buy != 0 && element.buyQtyCom != 0) {
        if (this.xMin > element.buy || this.xMin == 0) {
          this.xMin = Number(element.buy);
        }
        if (this.xMax < element.buy) {
          this.xMax = Number(element.buy);
        }
        if (this.yMin > element.buyQtyCom || this.yMin == 0) {
          this.yMin = Number(element.buyQtyCom);
        }
        if (this.yMax < element.buyQtyCom) {
          this.yMax = Number(element.buyQtyCom);
        }
        buyArr.push({
          x: Number(element.buy),
          y: Number(element.buyQtyCom),
        } as Point);
      }
      if (element.sell != 0 && element.sellQtyCom != 0) {
        if (this.xMin > element.sell || this.xMin == 0) {
          this.xMin = Number(element.sell);
        }
        if (this.xMax < element.sell) {
          this.xMax = Number(element.sell);
        }
        if (this.yMin > element.sellQtyCom || this.yMin == 0) {
          this.yMin = Number(element.sellQtyCom);
        }
        if (this.yMax < element.sellQtyCom) {
          this.yMax = Number(element.sellQtyCom);
        }
        sellArr.push({
          x: Number(element.sell),
          y: Number(element.sellQtyCom),
        } as Point);
      }
    });

    const buyData = buyDataset;
    const sellData = sellDataset;
    buyData.data = buyArr;
    sellData.data = sellArr;
    return [buyData, sellData];
  }

  createChart(chartData: ChartData): void {
    const canvas = document.getElementById("customChart") as HTMLCanvasElement;
    const test: ChartOptions = {
      responsive: true,
      scales: {
        y: {
          // gridLines: {
          //   zeroLineColor: "rgba(0,255,0,1)"
          // },
          // cubicInterpolationMode: "monotone",
          // scaleLabel: {
          //   display: true,
          //   labelString: "LABEL",
          // },
          min: this.yMin,
          max: this.yMax,
          ticks: {
            major: {
              enabled: true,
            },
            // min: this.yMin,
            // max: this.yMax,
            callback: function (
              tickValue: number | string,
              index: number,
              ticks: Tick[]
            ) {
              return Number(tickValue.toString());
            },
          },
          display: true,
        },
        x: {
          // gridLines: {
          //   zeroLineColor: "rgba(0,255,0,1)"
          // },
          // cubicInterpolationMode: "monotone",
          // scaleLabel: {
          //   display: true,
          //   labelString: "LABEL",
          // },
          ticks: {
            major: {
              enabled: true,
            },
            // min: this.xMin,
            // max: this.xMax,
            autoSkip: false,
            maxRotation: 90,
            minRotation: 45,
            maxTicksLimit: 100,
            stepSize: 1000,
            callback: function (
              tickValue: number | string,
              index: number,
              ticks: Tick[]
            ) {
              return Number(tickValue.toString());
            },
          },
          display: true,
        },
      },
      indexAxis: "x",
    };
    new Chart(canvas, {
      type: "line",
      data: chartData,
      options: test,
    });
  }
}
