
import { Component, Prop, Vue } from "vue-property-decorator";
import { RealTimePrice as information } from "@/models/RootObjects/RealTimePricesData";

@Component
export default class MoreInfo extends Vue {
  @Prop() readonly myTable!: information[];
  headers = [
    {
      text: "Contract",
      align: "start",
      value: "contract",
      groupable: false,
    },
    {
      text: "Open Price",
      align: "right",
      value: "openPrice",
      groupable: false,
    },
    {
      text: "Low",
      align: "right",
      value: "low",
      groupable: false,
    },
    {
      text: "High",
      align: "right",
      value: "high",
      groupable: false,
    },
    {
      text: "Volume",
      align: "right",
      value: "volume",
      groupable: false,
    },
    {
      text: "Open Interest",
      align: "right",
      value: "openInterest",
      groupable: false,
    },
  ];
}
