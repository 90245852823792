import { itacPositions } from "@/models/RootObjects/ItacPositions";
import { axiosInstanceITAC } from "@/plugins/axios";

export class ItacPositionsWs {
  public async GetItacPositions(
    date: string,
    type: string
  ): Promise<itacPositions[]> {
    const uri = "/api/rtc/positions?date=" + date + "&type=" + type;
    const base = process.env["VUE_APP_SERVER_ITAC"] + uri;
    const res = await axiosInstanceITAC.get(base).catch((err) => {
      console.log("Error on GetItacPositions", err);
      return Promise.reject(err);
    });
    const arr: itacPositions[] = [];
    res.data.forEach((element: any) => {
      arr.push(new itacPositions(element));
    });
    // console.log("Res for GetItacPositions", arr);
    return Promise.resolve(arr);
  }
}
