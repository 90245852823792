
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Dash from "@/store/modules/Dash";
import { goToPage } from "@/scripts/navigation";
import {
  ReportGroup,
  Report,
  ReportCategory,
} from "@/models/RootObjects/Reports";
import { ReportsWs } from "@/DataAccess/WebServices/AgriBase/ReportsWs";
import { filter } from "vue/types/umd";

const reportWs = new ReportsWs();

@Component
export default class NavDrawer extends Vue {
  // darkmode: boolean | undefined;
  flipDrawer(trust: boolean): void {
    // console.log("Nav flip me please ", trust, this.appDrawer);
    Dash.setAppDrawer(trust);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }
  get canPostTrade(): boolean {
    const filter = AuthModule.getUserRoles.findIndex((e) => e.name == "retail");
    return filter == -1 ? true : false;
  }
  get getReportGroups(): ReportGroup[] {
    const catList = Dash.getReport;
    const uniqueArr: number[] = [];
    catList.forEach((element) => {
      if (uniqueArr.findIndex((e) => e == element.category_id) == -1) {
        uniqueArr.push(element.category_id);
      }
    });
    const filteredGroups: ReportGroup[] = [];
    Dash.getReportGroups.forEach((group) => {
      const temp: ReportCategory[] = [];
      group.categories.forEach((element) => {
        if (uniqueArr.findIndex((e) => e == element.id) != -1) {
          temp.push(element);
        }
      });
      if (temp.length > 0) {
        group.categories = temp;
        filteredGroups.push(group);
      }
    });
    return filteredGroups;
    // return Dash.getReportGroups.filter((e) => e.categories.findIndex(a => a.id));
  }
  get getReport(): Report[] {
    return Dash.getReport;
  }
  async loadGroups(): Promise<void> {
    try {
      const res = await reportWs
        .GetGroups()
        .catch((err) => Promise.reject(err));
      Dash.setReportGroups(res);
    } catch (err) {
      console.error(err);
    }
  }
  async loadReports(): Promise<void> {
    try {
      const res = await reportWs
        .GetReports()
        .catch((err) => Promise.reject(err));
      Dash.setReport(res);
    } catch (err) {
      console.error(err);
    }
  }
  async mounted(): Promise<void> {
    await this.loadGroups();
    await this.loadReports();
  }
  selected = null;
  logout(): void {
    AuthModule.clearLoggedIn();
    goToPage("Login");
  }
  // toggleDarkTheme(): void {
  //   this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  //   localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
  // }
  // mounted(): void {
  //   const theme = localStorage.getItem("dark_theme");
  //   if (theme) {
  //     if (theme == "true") {
  //       this.$vuetify.theme.dark = true;
  //       this.darkmode = true;
  //     } else {
  //       this.$vuetify.theme.dark = false;
  //       this.darkmode = false;
  //     }
  //   }
  // }
}
