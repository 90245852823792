import store from "@/store";
import { OauthTokenPasswordRoot } from "@/models/OAuth/OauthTokenPasswordRoot";
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { UserRole } from "@/models/RootObjects/UserRole";
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import { LS } from "@/scripts/localStorage";
import { StorageUpdate, StoredAuthDetails } from "models";

const lsHook = LS();

@Module({ dynamic: true, namespaced: true, name: "Auth", store })
class Auth extends VuexModule {
  public loginDetails: OauthTokenPasswordRoot | null = null;
  public loginDetailsITAC: OauthTokenPasswordRoot | null = null;
  public loginResponse: OauthTokenResponseRoot | null = null;
  public loginResponseITAC: OauthTokenResponseRoot | null = null;
  public userAuthResponse: UserAuthentication | null = null;
  public userAuthResponseITAC: UserAuthentication | null = null;
  public userRoles: UserRole[] = [];
  public intialized = false;

  @Mutation
  setLoginDetails(res: OauthTokenPasswordRoot, shouldUpdate = true) {
    this.loginDetails = res;
    if (this.userAuthResponse == null) {
      return;
    }
    if (shouldUpdate == true) {
      const ls: StorageUpdate = {
        data: res,
        itac: false,
      };
      //set in localStorage PWA?
      lsHook.updateStorage(ls);
    } else {
      console.log("SetLogin shouldUpdate false");
    }
  }
  @Mutation
  setLoginResponse(res: OauthTokenResponseRoot, shouldUpdate = true) {
    // if (this.userAuthResponse == null) {
    //   return;
    // }
    this.loginResponse = res;
    if (shouldUpdate == true) {
      //set in localStorage PWA?
      const ls: StorageUpdate = {
        data: res,
        itac: false,
      };
      //set in localStorage PWA?
      lsHook.updateStorage(ls);
    } else {
      console.log("SetLoginResponse shouldUpdate false");
    }
  }
  @Mutation
  setLoginResponseItac(
    res: OauthTokenResponseRoot | null,
    shouldUpdate = true
  ) {
    // if (this.userAuthResponseITAC == null) {
    //   return;
    // }
    this.loginResponseITAC = res;
    //set in localStorage PWA?
    if (shouldUpdate == true) {
      const ls: StorageUpdate = {
        data: res,
        itac: true,
      };
      console.log("Set itac login response. Should add to ls");
      //set in localStorage PWA?
      lsHook.updateStorage(ls);
    } else {
      console.log("setLoginResponse shouldUpdate false");
    }
  }
  // @Mutation
  // setLoginDetailsItac(res: OauthTokenPasswordRoot | null) {
  //   this.loginDetailsITAC = res;
  //   //set in localStorage PWA?
  //   const ls: StorageUpdate = {
  //     data: res,
  //     itac: true,
  //   };
  //   //set in localStorage PWA?
  //   lsHook.updateStorage(ls);
  // }
  @Mutation
  setAuth(res: UserAuthentication | null, shouldUpdate = true) {
    this.userAuthResponse = res;
    if (shouldUpdate == true) {
      if (res != null) {
        const ls: StorageUpdate = {
          data: res,
          itac: false,
        };

        //set in localStorage PWA?
        lsHook.updateStorage(ls);
      }
    } else {
      console.log("setAuth shouldUpdate false ");
    }
  }
  @Mutation
  setAuthItac(res: UserAuthentication | null, shouldUpdate = true) {
    this.userAuthResponseITAC = res;
    if (shouldUpdate == true) {
      if (res != null) {
        const ls: StorageUpdate = {
          data: res,
          itac: true,
        };
        //set in localStorage PWA?
        lsHook.updateStorage(ls);
      }
    } else {
      console.log("setAuthItac shouldUpdate false");
    }
  }

  @Mutation
  setUserRoles(roles: UserRole[]): void {
    this.userRoles = roles;
  }
  @Action
  checkLocalStorage(
    user: UserAuthentication | null,
    userItac: UserAuthentication | null
  ) {
    if (user == null) {
      //check local storage and init
      console.log("Init from local storage here");
      const raw = lsHook.getLogin();
      if (raw != null) {
        const parsed = JSON.parse(raw) as StoredAuthDetails;
        this.setLoginDetails(
          new OauthTokenPasswordRoot(parsed.username, ""),
          false
        );
        if (parsed.loginResponse) {
          this.setLoginResponse(
            new OauthTokenResponseRoot(
              parsed.loginResponse.tokenType,
              parsed.loginResponse.expiresIn,
              parsed.loginResponse.accessToken,
              parsed.loginResponse.createdAt
            ),
            false
          );
        }
        if (parsed.loginResponseItac) {
          this.setLoginResponseItac(
            new OauthTokenResponseRoot(
              parsed.loginResponseItac.tokenType,
              parsed.loginResponseItac.expiresIn,
              parsed.loginResponseItac.accessToken,
              parsed.loginResponseItac.createdAt
            ),
            false
          );
        }
        if (parsed.authResponse) {
          this.setAuth(
            new UserAuthentication(
              parsed.authResponse.uniqueKey,
              parsed.authResponse.dealerCode,
              parsed.authResponse.branchCode,
              parsed.authResponse.user_settings
            ),
            false
          );
        }
        if (parsed.authResponseItac) {
          this.setAuthItac(
            new UserAuthentication(
              parsed.authResponseItac.uniqueKey,
              parsed.authResponseItac.dealerCode,
              parsed.authResponseItac.branchCode,
              parsed.authResponseItac.user_settings
            ),
            false
          );
        }
      } else {
        console.log("No LS found");
      }
      // else
      //clear LS
    } else {
      // validate against user unique key in localStorage
      const raw = lsHook.getLogin();
      if (raw != null) {
        const parsed = JSON.parse(raw) as StoredAuthDetails;
        if (parsed.authResponse.uniqueKey != user.uniqueKey) {
          console.log("Clearing ls: ", parsed.authResponse, user);
          this.clearLoggedIn();
        } else {
          if (userItac != null) {
            if (parsed.authResponseItac.uniqueKey != userItac.uniqueKey) {
              console.log("Clearing itac ls: ", parsed.authResponse, user);
              this.clearLoggedIn();
            }
          }
        }
      } else {
        // no local storage when signed in?? wrong and clear?
        console.error(
          "Clearing logged in because of no LS item - sign in again to restore"
        );
        this.clearLoggedIn();
      }
    }
  }
  // @Action
  // checkLocalStorage3(
  //   user: UserAuthentication | null,
  //   userItac: UserAuthentication | null
  // ) {
  //   let key: string | null = null;
  //   let keyItac: string | null = null;

  //   if (user == null) {
  //     const temp = localStorage.getItem("com.bvg.app.last.login");
  //     if (temp != null) {
  //       const parsed = JSON.parse(temp);
  //       // console.error("Parsing local storage: ", parsed);
  //       key = parsed.portal;
  //       keyItac = parsed.itac;
  //     } else {
  //       console.error("Checking local storage failed");
  //       return;
  //     }
  //   } else {
  //     key = user.uniqueKey;
  //     keyItac = userItac == null ? null : userItac.uniqueKey;
  //   }
  //   if (key != null) {
  //     const bvg = lsHook.getLogin();
  //     if (bvg == null) {
  //       console.log("PORTAL/ITAC is null");
  //       //clear storage
  //       lsHook.clearStorage(key, false);
  //       this.clearLoggedIn();
  //     } else {
  //       const tempBvg: {
  //         loginResponse: OauthTokenResponseRoot;
  //         authResponse: UserAuthentication;
  //         username: string;
  //       } = JSON.parse(bvg);

  //       const newLogin = new OauthTokenPasswordRoot(tempBvg.username, "");
  //       const newRes = new OauthTokenResponseRoot(
  //         tempBvg.loginResponse.tokenType,
  //         tempBvg.loginResponse.expiresIn,
  //         tempBvg.loginResponse.accessToken,
  //         tempBvg.loginResponse.createdAt
  //       );
  //       const newAuth = new UserAuthentication(
  //         tempBvg.authResponse.uniqueKey,
  //         tempBvg.authResponse.dealerCode,
  //         tempBvg.authResponse.branchCode,
  //         tempBvg.authResponse.user_settings
  //       );
  //       this.setAuth(newAuth);
  //       this.setLoginDetails(newLogin);
  //       this.setLoginResponse(newRes);
  //     }
  //   }
  //   if (keyItac != null) {
  //     const itac = lsHook.getLoginItac(keyItac);
  //     if (itac == null) {
  //       console.log("PORTAL/ITAC is null");
  //       //clear storage
  //       lsHook.clearStorage(keyItac, false);
  //       this.clearLoggedIn();
  //     } else {
  //       const tempItac = JSON.parse(itac);
  //       if (tempItac != null) {
  //         const newResItac = new OauthTokenResponseRoot(
  //           tempItac.loginResponse.tokenType,
  //           tempItac.loginResponse.expiresIn,
  //           tempItac.loginResponse.accessToken,
  //           tempItac.loginResponse.createdAt
  //         );
  //         const newAuthItac = new UserAuthentication(
  //           tempItac.authResponse.uniqueKey,
  //           tempItac.authResponse.dealerCode,
  //           tempItac.authResponse.branchCode,
  //           tempItac.authResponse.user_settings
  //         );
  //         this.setAuthItac(newAuthItac);
  //         this.setLoginResponseItac(newResItac);
  //       }
  //     }
  //   }

  //   return Promise.resolve();
  // }
  // @Action
  // checkLocalStorage2(
  //   user: UserAuthentication | null,
  //   userItac: UserAuthentication | null
  // ): void {
  //   let key = null;
  //   let keyItac = null;
  //   if (user == null) {
  //     const temp = localStorage.getItem("com.bvg.app.last.login");
  //     if (temp != null) {
  //       const parsed = JSON.parse(temp);
  //       // console.error("Parsing local storage: ", parsed);
  //       key = parsed.portal;
  //       keyItac = parsed.itac;
  //     } else {
  //       console.error("Checking local storage failed");
  //       return;
  //     }
  //   } else {
  //     key = user.uniqueKey;
  //     keyItac = userItac == null ? null : userItac.uniqueKey;
  //   }
  //   // console.error("Compare keys: ", key, key);
  //   const bvg = lsHook.getLogin(key);
  //   const itac = lsHook.getLoginItac(keyItac);
  //   // console.log("Checking local storage", bvg, itac);

  //   if (bvg == null && itac == null) {
  //     console.log("PORTAL/ITAC is null");
  //     //clear storage
  //     lsHook.clearStorage(key, false);
  //     lsHook.clearStorage(keyItac.uniqueKey, true);
  //     this.clearLoggedIn();
  //   } else if (bvg != null) {
  //     let tempItac: {
  //       loginResponse: OauthTokenResponseRoot;
  //       authResponse: UserAuthentication;
  //       username: string;
  //     } | null = null;
  //     let newResItac = null;
  //     let newAuthItac = null;
  //     if (itac != null) {
  //       tempItac = JSON.parse(itac);
  //       if (tempItac != null) {
  //         newResItac = new OauthTokenResponseRoot(
  //           tempItac.loginResponse.tokenType,
  //           tempItac.loginResponse.expiresIn,
  //           tempItac.loginResponse.accessToken,
  //           tempItac.loginResponse.createdAt
  //         );
  //         newAuthItac = new UserAuthentication(
  //           tempItac.authResponse.uniqueKey,
  //           tempItac.authResponse.dealerCode,
  //           tempItac.authResponse.branchCode,
  //           tempItac.authResponse.user_settings
  //         );
  //       }
  //     }
  //     const tempBvg: {
  //       loginResponse: OauthTokenResponseRoot;
  //       authResponse: UserAuthentication;
  //       username: string;
  //     } = JSON.parse(bvg);
  //     const newLogin = new OauthTokenPasswordRoot(tempBvg.username, "");
  //     const newRes = new OauthTokenResponseRoot(
  //       tempBvg.loginResponse.tokenType,
  //       tempBvg.loginResponse.expiresIn,
  //       tempBvg.loginResponse.accessToken,
  //       tempBvg.loginResponse.createdAt
  //     );
  //     const newAuth = new UserAuthentication(
  //       tempBvg.authResponse.uniqueKey,
  //       tempBvg.authResponse.dealerCode,
  //       tempBvg.authResponse.branchCode,
  //       tempBvg.authResponse.user_settings
  //     );
  //     this.setAuth(newAuth);
  //     this.setAuthItac(newAuthItac);
  //     this.setLoginDetails(newLogin);
  //     this.setLoginResponse(newRes);
  //     this.setLoginResponseItac(newResItac);

  //     // console.log("Local storage: ", tempBvg, tempItac);
  //   }
  // }
  @Mutation
  clearLoggedIn() {
    // const key = this.userAuthResponse?.uniqueKey;
    // const keyItac = this.userAuthResponseITAC?.uniqueKey;
    // if (key != null) lsHook.clearStorage(key, false);
    // if (keyItac != null) lsHook.clearStorage(keyItac, true);
    lsHook.clearStorage();
    this.userAuthResponse = null;
    this.loginDetails = null;
    this.loginResponse = null;
    this.loginResponseITAC = null;
  }
  get getValidLogin(): boolean {
    console.log(
      "Login Response is null so defaults to invalid login",
      this.getLoginResponse
    );
    if (this.getLoginResponse == null) {
      return false;
    }
    console.log(
      "Testing get valid login router check : ",
      this.getLoginResponse
    );
    const state = this.getLoginResponse.VerifyExpiryDate();
    if (state) {
      console.log("Valid login");
      return true;
    } else {
      console.error("Invalid login - clearing all");
      this.clearLoggedIn();
      // if (this.userAuthResponse != null)
      //   lsHook.clearStorage(this.userAuthResponse.uniqueKey, false);
      // if (this.userAuthResponseITAC != null)
      //   lsHook.clearStorage(this.userAuthResponseITAC.uniqueKey, true);
      return false;
    }
  }
  get getLoginResponse(): OauthTokenResponseRoot | null {
    return this.loginResponse;
  }
  get getLoginResponseITAC(): OauthTokenResponseRoot | null {
    return this.loginResponseITAC;
  }
  get getLoginDetails(): OauthTokenPasswordRoot | null {
    return this.loginDetails;
  }
  get getLoginDetailsITAC(): OauthTokenPasswordRoot | null {
    return this.loginDetailsITAC;
  }
  get getUserAuthResponse(): UserAuthentication | null {
    return this.userAuthResponse;
  }
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return this.userAuthResponseITAC;
  }
  get getUserRoles(): UserRole[] {
    return this.userRoles;
  }
}

export default getModule(Auth);
