
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import { Component, Vue, Prop } from "vue-property-decorator";
import Auth from "@/store/modules/Auth";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";

@Component
export default class Graph extends Vue {
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  @Prop() readonly instrument!: string;
  @Prop() readonly accessToken!: string;
  @Prop() readonly port!: string;
  @Prop() readonly date!: string;
}
