
import { Component, Prop, Vue } from "vue-property-decorator";
import { Deal as deal } from "@/models/RootObjects/Deal";

@Component
export default class DealsTable extends Vue {
  @Prop() readonly myTable!: deal[];
  expandedGroups: string[] = [];
  headers = [
    {
      text: "Instrument",
      align: "start",
      value: "instrument",
      groupable: false,
    },
    {
      text: "Expiry",
      align: "right",
      value: "contractDate",
      groupable: false,
    },
    {
      text: "Flag",
      align: "right",
      value: "flag",
      groupable: false,
    },
    {
      text: "Strike",
      align: "right",
      value: "strike",
      groupable: false,
    },
    {
      text: "Qty",
      align: "right",
      value: "quantity",
      groupable: false,
    },
    {
      text: "Price",
      align: "right",
      value: "price",
      groupable: true,
    },
    {
      text: "Time",
      align: "right",
      value: "time",
      groupable: true,
    },
  ];
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
}
