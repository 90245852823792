import Utils from "@/WebService/Rest/Utils";
import { axiosInstance } from "@/plugins/axios";

const utils = new Utils();

export class ResetPasswordWs {
  public async ResetPassword(email: string): Promise<any> {
    const base =
      process.env["VUE_APP_SERVER_BASE"] +
      "/api/user/password/email?email=" +
      email;
    const res = await axiosInstance.post(base).catch((err) => {
      console.log("Error on RestPassword", err);
      return Promise.reject(err);
    });
    console.log("Res for RestPassword", res);
    return Promise.resolve(res.data);
  }
}
