
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { PositionsWs } from "@/DataAccess/WebServices/AgriBase/PositionsWs";
import { Position } from "@/models/RootObjects/Position";
const positionsWs = new PositionsWs();

@Component
export default class Positions extends Vue {
  loading = false;
  expandedGroups: string[] = [];
  instrumentFilter = "";
  contractDateFilter = "";
  strikeFilter = "";
  get getItems(): Position[] {
    return this.items;
  }
  filterInstrument(pos: Position): boolean {
    return pos.instrument
      .toLowerCase()
      .includes(this.instrumentFilter.toLowerCase());
  }
  filterContractDate(pos: Position): boolean {
    return pos.contractDate
      .toLowerCase()
      .includes(this.contractDateFilter.toLowerCase());
  }
  filterStrike(pos: Position): boolean {
    const combined = pos.strike.toLowerCase() + " " + pos.flag.toLowerCase();
    return combined.includes(this.strikeFilter.toLowerCase());
  }
  get getFilteredItems(): Position[] {
    const conditions: ((pos: Position) => boolean)[] = [];
    if (this.instrumentFilter != "") {
      conditions.push(this.filterInstrument);
    }
    if (this.contractDateFilter != "") {
      conditions.push(this.filterContractDate);
    }
    if (this.strikeFilter != null) {
      conditions.push(this.filterStrike);
    }
    if (conditions.length > 0) {
      return this.getItems.filter((pos) => {
        return conditions.every((condition) => {
          return condition(pos);
        });
      });
    } else return this.getItems;
  }
  months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  get getSortBy(): string[] {
    return ["instrument", "contractDate"];
  }
  items: Position[] = [];
  headers: DataTableHeader[] = [
    {
      value: "principle",
      text: "Principle",
      align: "start",
    },
    {
      value: "instrument",
      text: "Instrument",
      align: "start",
      sortable: true,
    },
    {
      value: "contractDate",
      text: "Contract Date",
      align: "start",
      sortable: true,
    },
    {
      value: "strike",
      text: "Strike",
      align: "start",
      sortable: true,
    },
    {
      value: "open",
      text: "Open",
      align: "start",
    },
    {
      value: "bought",
      text: "Bought",
      align: "start",
    },
    {
      value: "sold",
      text: "Sold",
      align: "start",
    },
    {
      value: "close",
      text: "Close",
      align: "start",
    },
    {
      value: "phyPosition",
      text: "Physical Position",
      align: "start",
    },
    {
      value: "deliveries",
      text: "Deliveries",
      align: "start",
    },
  ];
  customFilter(value: any, search: string | null, item: any): boolean {
    console.log("Hello filter ", value, search);
    return true;
  }
  customGroups(
    items: Position[],
    groupBy: string[],
    groupDesc: boolean[]
  ): {
    items: Position[];
    name: string;
  }[] {
    let predefinedGroups: {
      items: Position[];
      name: string;
    }[] = [];
    items.forEach((pos: Position) => {
      const group = predefinedGroups.find((el) => el.name === pos.principle);
      if (group != undefined) {
        // console.log("Object is contained");
        group.items.push(pos);
      } else {
        predefinedGroups.push({
          name: pos.principle,
          items: [pos],
        });
      }
    });

    predefinedGroups.forEach((element) => {
      element.items.sort((a: Position, b: Position) => {
        const byInstrument = this.sortByInstrument(a, b);
        const byStrike = this.sortByStrike(a, b);
        const byMonth = this.sortByMonth(a, b);

        if (byInstrument == 0) {
          if (byMonth == 0) {
            return byStrike;
          } else return byMonth;
        } else return byInstrument;
      });
    });
    return predefinedGroups;
  }
  // customSort(
  //   items: Position[],
  //   sortBy: string[],
  //   sortDesc: boolean[]
  // ): Position[] {
  //   return items.sort((a: Position, b: Position) => {
  //     const byInstrument = this.sortByInstrument(a, b);
  //     const byStrike = this.sortByStrike(a, b);
  //     const byMonth = this.sortByMonth(a, b);

  //     if (byInstrument == 0) {
  //       if (byMonth == 0) {
  //         return byStrike;
  //       } else return byMonth;
  //     } else return byInstrument;
  //   });
  // }
  sortByInstrument(a: Position, b: Position): number {
    if (a.instrument.substr(0, 1) > b.instrument.substr(0, 1)) return 1;
    else if (a.instrument.substr(0, 1) < b.instrument.substr(0, 1)) return -1;
    else return 0;
  }
  sortByMonth(a: Position, b: Position): number {
    const aMonth = a.contractDate.substring(0, 3);
    const bMonth = b.contractDate.substring(0, 3);
    const aYear = Number(a.contractDate.substring(3, 5));
    const bYear = Number(b.contractDate.substring(3, 5));
    // console.log("Date a ", aMonth, aYear);
    // console.log("Date b ", bMonth, bYear);
    if (aYear > bYear) {
      return +1;
    } else if (aYear < bYear) {
      return -1;
    } else {
      if (
        this.months.findIndex((e) => e == aMonth) >
        this.months.findIndex((e) => e == bMonth)
      ) {
        return 1;
      } else if (
        this.months.findIndex((e) => e == aMonth) <
        this.months.findIndex((e) => e == bMonth)
      ) {
        return -1;
      } else {
        return 0;
      }
    }
  }
  sortByStrike(a: Position, b: Position): number {
    const numA = Number(a.strike);
    const numB = Number(b.strike);
    if (Number.isNaN(numA) == true) {
      console.log("A NAN");
      return 1;
    }
    if (Number.isNaN(numB) == true) {
      console.log("B NAN");
      return -1;
    }

    const state = numA > numB ? -1 : numA < numB ? 1 : 0;
    // console.log(
    //   "Num compare: ",
    //   state,
    //   a.contractDate,
    //   a.strike,
    //   b.contractDate,
    //   b.strike
    // );
    return state;
  }

  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  async mounted(): Promise<void> {
    try {
      await this.loadPositions();
    } catch (err) {
      console.error("Error in positions mounted: ", err);
    }
  }
  async loadPositions(): Promise<void> {
    try {
      this.loading = true;
      const res = await positionsWs
        .GetPositions()
        .catch((err) => Promise.reject(err));

      this.items = res;

      console.log("Response: ", res);
    } catch (err) {
      console.log("Error: ", err);
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
