import { axiosInstance } from "@/plugins/axios";
import Utils from "@/WebService/Rest/Utils";
import { OptionPrice } from "@/models/RootObjects/OptionPrice";

const utils = new Utils();
export class OptionPriceWs {
  public async GetOptionPrice(
    instrument: string,
    contract: string
  ): Promise<OptionPrice[]> {
    const uri = utils.BuildEndPoint("/api/option/calculator/price", [
      { key: "instrument", value: instrument },
      { key: "contract", value: contract },
    ]);
    const base = process.env["VUE_APP_SERVER_BASE"] + uri;

    const res = await axiosInstance.get(base).catch((err) => {
      console.log("Error on GetOptionPrice", err);
      return Promise.reject(err);
    });
    // console.log("Res for GetOptionPrice", res);
    return Promise.resolve(res.data);
  }
}
