
import { Component, Prop, Vue } from "vue-property-decorator";
// import { RealTimePrice as commodity } from "@/models/RootObjects/RealTimePricesData";
import Dash from "@/store/modules/Dash";
import { UserRole } from "@/models/RootObjects/UserRole";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";
import { MarketData as commodity } from "./NewCommodityPricing.vue";
@Component({})
export default class PricingTable extends Vue {
  @Prop() readonly myTable!: commodity[];
  @Prop() readonly loading!: commodity[];
  @Prop() readonly myHeaders!: Record<string, string | boolean>[];

  currData: commodity[] = [];
  expandedGroups: string[] = [];
  isSelected = null;
  userRole: UserRole[] = [];

  async mounted(): Promise<void> {
    const userRoleWs = new UserRoleWs();
    const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
      return Promise.reject(err);
    });
    this.userRole = userRoleResp;
  }

  get tableData(): commodity[] {
    return this.myTable;
  }

  editRow(e: commodity): void {
    this.$emit("editRow", e);
    console.log("Edit row ", e);
  }
  deleteRow(e: commodity): void {
    console.log("Test ", e);

    this.$emit("deleteRow", e.Contract);
    // console.log("Delete row ", e);
    // Dash.addDeletedExpiryInstrument(e);
  }
  get getDeletedGroupsLength(): number {
    let temp = 0;
    // Dash.getCommodityDeletedGroups.forEach((e) => {
    //   temp += e.items.length;
    // });
    return temp;
  }
  deleteGroup(e: string): void {
    // Dash.addDeletedPricingGroup(e);
    this.$emit("deleteGroup", e);
  }
  clickedRow(e: commodity): void {
    console.log("Clicked item ", e);
    this.$emit("openContextMenu", e);
  }

  get getUserRole(): UserRole[] {
    return this.userRole;
  }
  checkDelayed(): boolean {
    const temp = this.getUserRole.findIndex((elem) => elem.name == "delayed");
    if (temp != -1) {
      return true;
    } else {
      return false;
    }
  }

  customGroupFunctionSelector(
    items: commodity[],
    groupBy: string[],
    groupDesc: boolean[]
  ): {
    items: commodity[];
    name: string;
  }[] {
    if (this.checkDelayed() == true) {
      return this.customGroupFunctionDelayed(items, groupBy, groupDesc);
    } else {
      return this.customGroupFunction(items, groupBy, groupDesc);
    }
  }
  customSortForDate(data: { name: string; items: commodity[] }[]) {
    data.forEach((unsortedItem) => {
      unsortedItem.items.sort((a, b) => {
        const month = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        const montha = a.ContractDate.substring(0, 3);
        const yearA = Number(a.ContractDate.substring(3));
        const monthb = b.ContractDate.substring(0, 3);
        const yearB = Number(b.ContractDate.substring(3));
        if (yearA < yearB) {
          return -1;
        } else if (yearA > yearB) {
          return 1;
        }
        const monthOrdera = month.findIndex((e) => e == montha);
        const monthOrderb = month.findIndex((e) => e == monthb);
        if (monthOrdera > monthOrderb) {
          return 1;
        }
        if (monthOrdera < monthOrderb) {
          return -1;
        }
        return 0;
      });
    });
  }
  customGroupFunction(
    items: commodity[],
    groupBy: string[],
    groupDesc: boolean[]
  ): {
    items: commodity[];
    name: string;
  }[] {
    console.log("Grouping Function: ", groupBy, groupDesc);
    let predefinedGroups: {
      items: commodity[];
      name: string;
    }[] = [
      {
        name: "WMAZ",
        items: [],
      },
      {
        name: "YMAZ",
        items: [],
      },
      {
        name: "WEAT",
        items: [],
      },
      {
        name: "SUNS",
        items: [],
      },
      {
        name: "SOYA",
        items: [],
      },
      {
        name: "CORN",
        items: [],
      },
    ];
    items.forEach((com: commodity) => {
      const group = predefinedGroups.find((el) => el.name === com.Instrument);
      if (group != undefined) {
        console.log("Object is contained");
        group.items.push(com);
      } else {
        predefinedGroups.push({
          name: com.Instrument,
          items: [com],
        });
      }
    });
    const deletedRows = Dash.getCommodityDeletedGroups;
    deletedRows.forEach((row) => {
      const temp = predefinedGroups.find((val) => val.name == row);
      if (temp != undefined) {
        console.log("Add group ", temp, predefinedGroups);
        // Dash.addDeletedPricingGroupItems(temp);
        predefinedGroups = predefinedGroups.filter((e) => e.name != row);
      }
    });
    console.log("Predefined after: ", predefinedGroups);
    // for (let i = 0; i < items.length; i++) {}
    this.customSortForDate(predefinedGroups);
    // group-by="InstrumentName"
    return predefinedGroups;
  }

  customGroupFunctionDelayed(
    items: commodity[],
    groupBy: string[],
    groupDesc: boolean[]
  ): {
    items: commodity[];
    name: string;
  }[] {
    console.log("Grouping Function: ", groupBy, groupDesc);
    let predefinedGroups: {
      items: commodity[];
      name: string;
    }[] = [
      {
        name: "WMAZ Delayed",
        items: [],
      },
      {
        name: "YMAZ Delayed",
        items: [],
      },
      {
        name: "WEAT Delayed",
        items: [],
      },
      {
        name: "SUNS Delayed",
        items: [],
      },
      {
        name: "SOYA Delayed",
        items: [],
      },
    ];
    items.forEach((com: commodity) => {
      const group = predefinedGroups.find((el) => el.name === com.Instrument);
      if (group != undefined) {
        console.log("Object is contained");
        group.items.push(com);
      } else {
        predefinedGroups.push({
          name: com.Instrument,
          items: [com],
        });
      }
    });
    const deletedRows = Dash.getCommodityDeletedGroups;
    deletedRows.forEach((row) => {
      const temp = predefinedGroups.find((val) => val.name == row);
      if (temp != undefined) {
        console.log("Add group ", temp, predefinedGroups);
        // Dash.addDeletedPricingGroupItems(temp);
        predefinedGroups = predefinedGroups.filter((e) => e.name != row);
      }
    });
    console.log("Predefined after: ", predefinedGroups);
    this.customSortForDate(predefinedGroups);
    return predefinedGroups;
  }

  openContextRow(i: { rtp: commodity }, e: Event): void {
    e.preventDefault();
    this.$emit("openContextMenu", i);
  }
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
}
