
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";
import { OptionCalculatorResponse } from "@/models/RootObjects/OptionCalculatorResponse";
import { OptionCalculatorRequest } from "@/models/RootObjects/OptionCalculatorRequest";
import { OptionCalculatorWs } from "@/DataAccess/WebServices/AgriBase/OptionCalculatorWs";
import { InstrumentWs } from "@/DataAccess/WebServices/AgriBase/InstrumentWs";
import { Instrument } from "@/models/RootObjects/Instrument";
import { Contract } from "@/models/RootObjects/Contract";
import { OptionPriceWs } from "@/DataAccess/WebServices/AgriBase/OptionPriceWs";
import { CommodityPricingWs } from "@/DataAccess/WebServices/AgriBase/CommodityPricingWs";
import { MtmClosingPricesWs } from "@/DataAccess/WebServices/AgriBase/MtmClosingPricesWs";
import { MtmRootObject } from "@/models/RootObjects/MtmRootObject";

const instrumentWs = new InstrumentWs();
const optionCalcWs = new OptionCalculatorWs();
const optionPriceWs = new OptionPriceWs();
const commdPricingWs = new CommodityPricingWs();
const mtmWs = new MtmClosingPricesWs();

@Component
export default class OptionCalculator extends Vue {
  userRole = new UserRoleWs();
  async mounted(): Promise<void> {
    //Pure testing purposes
    const temp = await this.userRole.GetUserRoles().catch((err) => {
      return Promise.reject();
    });
    this.instruments = await instrumentWs.GetInstruments();
    console.log("User Roles : ", temp);
    this.sortContracts();
  }

  loading = false;
  valid = false;
  premium: number | null = 0;
  instruments: Instrument[] = [];
  contracts: Contract[] = [];
  selectedInstrument: Instrument | null = null;
  selectedInstruName: string[] = [];
  mtmClosingPrices: MtmRootObject[] = [];
  expiry: string | null = null;
  strike = 0;
  futurePrice = 0;
  volatilityPerc = 0;
  quantity = 1;
  buySell: string | null = null;
  putCall: string | null = null;
  buySellItems = ["Buy", "Sell"];
  putCallItems = ["Put", "Call"];

  optionResponse: OptionCalculatorResponse | null = null;
  sortContracts() {
    const month = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    this.instruments.forEach((instr) => {
      instr.contracts.sort((a, b): number => {
        const aMonth = a.contractDate.substring(0, 3);
        const bMonth = b.contractDate.substring(0, 3);
        const aYear = Number(a.contractDate.substring(3, 5));
        const bYear = Number(b.contractDate.substring(3, 5));
        // console.log("Date a ", aMonth, aYear);
        // console.log("Date b ", bMonth, bYear);
        if (aYear > bYear) {
          return +1;
        } else if (aYear < bYear) {
          return -1;
        } else {
          if (
            month.findIndex((e) => e == aMonth) >
            month.findIndex((e) => e == bMonth)
          ) {
            return 1;
          } else if (
            month.findIndex((e) => e == aMonth) <
            month.findIndex((e) => e == bMonth)
          ) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    });
  }
  async changedInstruments(instrument: Instrument): Promise<void> {
    this.setInstrumentContracts(instrument);
    if (this.contracts.length > 0) {
      this.expiry = this.contracts[0].contractDate;
      await this.loadOptionPrice().catch((err) => {
        return Promise.reject(err);
      });
    }
    return Promise.resolve();
  }

  setInstrumentContracts(instrument: Instrument): void {
    console.log(instrument);
    if (instrument == null) {
      return;
    }
    this.contracts = instrument.contracts;
  }

  get getVolatility(): number {
    if (this.mtmClosingPrices.length <= 0) {
      return 0;
    }
    if (this.selectedInstrument == null) {
      return 0;
    }

    const temp = this.mtmClosingPrices.find(
      (val) =>
        val.contract_date == this.expiry &&
        val.instrument == this.selectedInstrument?.name
    );

    if (temp == null) {
      return 0;
    }
    return temp.vol;
  }

  strikeCalc(): void {
    const temp = Math.round(this.strike / 20);
    if (temp > 0) {
      this.strike = temp * 20;
    }
  }

  // getPreviousWorkday(): string {
  //   // Based on the current day, handle accordingly
  //   switch (dayjs().day()) {
  //     // If it is Monday (1),Saturday(6), or Sunday (0), Get the previous Friday (5)
  //     // and ensure we are on the previous week
  //     case 0:
  //       return
  //     case 1:
  //     case 6:
  //       return dayjs().subtract(6, "days").day(4).toString();
  //     // If it any other weekend, just return the previous day
  //     default:
  //       return dayjs().subtract(1, "day").toString();
  //   }
  // }

  async loadOptionPrice(): Promise<void> {
    try {
      this.loading = true;

      if (this.selectedInstrument == null || this.expiry == null) {
        return Promise.reject(
          new Error("Expiry or selectedInstrument is null!")
        );
      }
      const res = await commdPricingWs
        .GetRealTimePrice(this.selectedInstrument?.name, this.expiry)
        .catch((err) => {
          return Promise.reject(err);
        });

      this.futurePrice = res;
      this.strike = this.futurePrice;
      this.strikeCalc();

      // empty the array
      this.selectedInstruName = [];
      // added selected instrument name to array
      this.selectedInstruName.push(this.selectedInstrument.name);

      // get yesterdays closing prices for instrument
      this.mtmClosingPrices = await mtmWs.GetMTMClosingPrices(
        this.selectedInstruName
      );

      this.volatilityPerc = this.getVolatility;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async submitOptionValues(): Promise<void> {
    try {
      this.loading = true;

      let instName = "";
      let exp = "";
      let p = "";
      let b = "";

      if (this.selectedInstrument !== null) {
        instName = this.selectedInstrument.name;
      }
      if (this.expiry !== null) {
        exp = this.expiry;
      }
      if (this.putCall !== null) {
        p = this.putCall.toLowerCase();
      }
      if (this.buySell !== null) {
        b = this.buySell.toLowerCase();
      }
      const optionRequest = new OptionCalculatorRequest(
        this.futurePrice,
        this.strike,
        this.quantity,
        this.volatilityPerc,
        instName,
        exp,
        p,
        b
      );

      this.optionResponse = await optionCalcWs.GetCalculatedOption(
        optionRequest
      );

      console.log(this.optionResponse);

      this.premium = this.optionResponse.premium;
    } catch (err) {
      return Promise.resolve();
    } finally {
      this.loading = false;
    }
  }
}
