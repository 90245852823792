var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.myTable,"sort-by":"instrument","group-by":"principle","dense":"","items-per-page":-1,"calculate-widths":"","mobile-breakpoint":"0","height":"70vh","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',[_c('tr',_vm._l((headers),function(h){return _c('th',{key:h.value,staticClass:"white--text primary font-weight-bold lighten-1 py-4 px-1 text-center",class:h.class},[_c('strong',[_vm._v(_vm._s(h.text))])])}),0)])]}},{key:"group",fn:function(props){return [_c('tr',{staticClass:"primary",on:{"click":function($event){return _vm.clickedHeader(props.group)}}},[_c('td',{staticClass:"white--text text-left",attrs:{"colspan":"12"}},[(_vm.expandedGroups.find((e) => e == props.group) == undefined)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-down ")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-up")]),_vm._v(" Principle: "+_vm._s(props.group)+" "),_c('v-spacer')],1)]),(
        _vm.expandedGroups.find((e) => e == props.group) == undefined
          ? false
          : true
      )?_vm._l((props.items),function(item){return _c('tr',{key:'row-' +
          item.principle +
          '-' +
          item.instrument +
          '-' +
          item.contractDate +
          '-' +
          item.dealSeq},_vm._l((props.headers),function(header){return _c('td',{key:header.value,staticClass:"text-left px-1 text-caption",attrs:{"id":item + '-' + header.value}},[(header.value == 'strike')?_c('span',{staticClass:"text-no-wrap float-right"},[_vm._v(" "+_vm._s(item[header.value] > 0 ? item[header.value] : " ")+" ")]):(header.value == 'flag')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value])+" ")]):(header.value == 'quantity')?_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(item[header.value])+" ")]):(header.value == 'price')?_c('span',{staticClass:"text-no-wrap float-right"},[_vm._v(" "+_vm._s(parseFloat(item[header.value]).toFixed(4))+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),0)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }