
import { WeatherWs } from "@/DataAccess/WebServices/AgriBase/WeatherWs";
import { Component, Vue } from "vue-property-decorator";
import { Station } from "@/models/RootObjects/WeatherDataOverview";
import { sortBy } from "lodash";
import { DataTableHeader } from "vuetify";

const weather = new WeatherWs();

@Component
export default class Weather extends Vue {
  loading = false;
  weather: Station[] = [];
  provinceGroup: {
    name: string;
    items: Station[];
  }[] = [];
  headers: DataTableHeader[] = [
    {
      text: "Name",
      align: "start",
      value: "name",
    },
    {
      text: "Type",
      align: "start",
      value: "type",
    },
    {
      text: "mdi-water-percent",
      align: "start",
      value: "humidityAvg",
    },
    // {
    //   text: "Temp Avgx̄",
    //   align: "start",
    //   value: "tempAvg",
    // },
    {
      text: "mdi-thermometer-chevron-down",
      align: "start",
      value: "tempMin",
    },
    {
      text: "mdi-thermometer-chevron-up",
      align: "start",
      value: "tempMax",
    },
    {
      text: "mdi-weather-pouring",
      align: "start",
      value: "rainAvg",
    },
    // {
    //   text: "",
    //   align: "start",
    //   value: "actions",
    // },
  ];
  expandedGroups: string[] = [];
  clickedHeader(e: string): void {
    console.log("Clicked header ", e);
    const exists = this.expandedGroups.find((obj) => obj == e);
    if (exists != undefined) {
      this.expandedGroups.splice(this.expandedGroups.indexOf(exists));
    } else {
      this.expandedGroups.push(e);
    }
  }
  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const weatherResp = await weather.GetWeather().catch((err) => {
        return Promise.reject(err);
      });
      // Weather
      this.weather = weatherResp;
      console.log("Weather Resp ", weatherResp);
    } catch (err) {
      console.log("Mounted weather error ", err);
    } finally {
      this.loading = false;
    }
  }
  get getWeather(): Station[] {
    return this.weather;
  }

  get getFilteredDataWeather(): {
    name: string;
    items: Station[];
  }[] {
    this.getWeather.sort(function (a, b) {
      var nameA = a.info.name;
      var nameB = b.info.name;
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
    this.getWeather.forEach((elem) => {
      const arr = this.provinceGroup.find(
        (item) => item.name == elem.info.province.name
      );
      if (arr) {
        arr.items.push(elem);
      } else {
        this.provinceGroup.push({
          name: elem.info.province.name,
          items: [elem],
        });
      }
    });

    return this.provinceGroup;
  }
}
