
import { Component, Vue } from "vue-property-decorator";
import { CurrencyWs } from "@/DataAccess/WebServices/AgriBase/CurrencyWs";
import { Currency as currency } from "@/models/RootObjects/Currency";
import CurrencyList from "@/components/Currencies/CurrencyList.vue";
import CurrencyAlerts from "@/components/Currencies/CurrencyAlerts.vue";
const curr = new CurrencyWs();

@Component({
  components: {
    CurrencyList,
    CurrencyAlerts,
  },
})
export default class Currencies extends Vue {
  currencies: currency[] = [];
  tab = "";
  loading = false;

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const currForUser = await curr.GetCurrencyForUser().catch((err) => {
        return Promise.reject(err);
      });
      this.currencies = currForUser;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }
}
