var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.currentNotif != null)?_c('v-snackbar',{attrs:{"width":_vm.$vuetify.breakpoint.sm == true
      ? '50vw'
      : _vm.$vuetify.breakpoint.md == true
      ? '40vw'
      : _vm.$vuetify.breakpoint.lgAndUp == true
      ? '30vw'
      : '100vw',"id":_vm.currentNotif.id,"color":_vm.currentNotif.color,"timeout":_vm.currentNotif.timeout,"value":_vm.displayAnyNotifs,"left":"","app":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.closeNotif(_vm.currentNotif)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,4197599045)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"width":"30px"}},[_vm._v(_vm._s(_vm.currentNotif.icon))])],1),_c('v-col',{staticClass:"text-break text-left",attrs:{"cols":"9"}},[_vm._v(_vm._s(String(_vm.currentNotif.message).slice(0, 50)))])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }