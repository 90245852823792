
import { Component, Vue, Prop } from "vue-property-decorator";
import ItacPositionsLive from "@/components/Itac/express/positions/live.vue";
import ItacDealsLive from "@/components/Itac/express/deals/live.vue";

import Auth from "@/store/modules/Auth";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";
import { itacDeals, dealReason } from "@/models/RootObjects/ItacDeals";
import { itacPositions } from "@/models/RootObjects/ItacPositions";

@Component({
  components: {
    ItacDealsLive,
    ItacPositionsLive,
  },
})
export default class ItacExpress extends Vue {
  @Prop() deals!: itacDeals[];
  @Prop() dealReasons!: dealReason[];
  @Prop() positions!: itacPositions[];
  get tabVal(): string {
    console.log("Route get", this.$route.query.tab);
    const temp = this.$route.query.tab as string;
    return temp;
  }
  set tabVal(tab: string) {
    console.log("Route set: ", this.$route.query, tab);
    this.$router.replace({ query: { ...this.$route.query, tab } });
  }
  get getUserAuthResponseITAC(): UserAuthentication | null {
    return Auth.getUserAuthResponseITAC;
  }
  get getUserAuthResponse(): UserAuthentication | null {
    return Auth.getUserAuthResponse;
  }
}
