
import { WeatherWs } from "@/DataAccess/WebServices/AgriBase/WeatherWs";
import { Component, Vue } from "vue-property-decorator";
import { Precipitation } from "@/models/RootObjects/Precipitation";
import PDataTable from "@/components/Weather/PDataTable.vue";
import P2DataTable from "@/components/Weather/P2DataTable.vue";
import HailDataTable from "@/components/Weather/HailDataTable.vue";
import TempDataTable from "@/components/Weather/TempDataTable.vue";
import { UserRole } from "@/models/RootObjects/UserRole";
import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";

const weather = new WeatherWs();

@Component({
  components: {
    PDataTable,
    P2DataTable,
    HailDataTable,
    TempDataTable,
  },
})
export default class Weather extends Vue {
  loading = false;
  tab = null;
  precipitation: Precipitation | null = null;
  static userRole: UserRole[] = [];

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const precipitationResp = await weather
        .GetPrecipitation()
        .catch((err) => {
          return Promise.reject(err);
        });
      const userRoleWs = new UserRoleWs();
      const userRoleResp = await userRoleWs.GetUserRoles().catch((err) => {
        return Promise.reject(err);
      });
      // User Roles
      Weather.userRole = userRoleResp;
      console.log("User Role Resp ", userRoleResp);
      // Precipitation
      this.precipitation = precipitationResp;
      console.log("Precipitation Resp ", precipitationResp);
    } catch (err) {
      console.log("Mounted Precipitation error ", err);
    } finally {
      this.loading = false;
    }
  }
  get getPrecipitation(): Precipitation | null {
    return this.precipitation;
  }
  static get getUserRole(): UserRole[] {
    return this.userRole;
  }
  static checkWeatherData(): boolean {
    var arr: UserRole | undefined;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if ((arr = this.getUserRole.find((elem) => elem.name == "weather"))) {
      return true;
    } else {
      return false;
    }
  }
  static checkWeatherTier4(): boolean {
    var arr: UserRole | undefined;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (arr = this.getUserRole.find(
        (elem) => elem.name == "weathertier4traders"
      ))
    ) {
      return true;
    } else {
      return false;
    }
  }
  static checkWeatherTier2(): boolean {
    var arr: UserRole | undefined;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (arr = this.getUserRole.find(
        (elem) => elem.name == "weathertier2payindividualclients"
      ))
    ) {
      return true;
    } else {
      return false;
    }
  }
  static checkWeatherTier3(): boolean {
    var arr: UserRole | undefined;
    if (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (arr = this.getUserRole.find(
        (elem) => elem.name == "weathertier3payingcorporates"
      ))
    ) {
      return true;
    } else {
      return false;
    }
  }
}
