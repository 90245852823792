
import { Component, Vue } from "vue-property-decorator";
import updateNotif from "@/components/pwa/updateAvailNotif.vue";
import { useLogin } from "@/scripts/login";
import { OauthTokenResponseRoot } from "@/models/OAuth/OauthTokenResponseRoot";
import { OauthTokenPasswordRoot } from "@/models/OAuth/OauthTokenPasswordRoot";
import Auth from "@/store/modules/Auth";
import { goToPage } from "@/scripts/navigation";
/* import Register from "@/views/Register.vue"; */
import { NotificationWs } from "@/DataAccess/WebServices/AgriBase/NotificationWs";
import RegisterModal from "@/components/Login/RegisterModal.vue";
// import { FCM } from "@capacitor-community/fcm";
import ResetPassModal from "@/components/Login/ResetPassModal.vue";
import NotifModule from "@/store/modules/NotificationSystem";
const loginHook = useLogin();
import axios, { AxiosError } from "axios";

import { UserRoleWs } from "@/DataAccess/WebServices/AgriBase/UserRoleWs";
const userRoleWs = new UserRoleWs();
const notificationsWs = new NotificationWs();

@Component({
  components: {
    updateNotif,
    RegisterModal,
    ResetPassModal,
  },
})
export default class Login extends Vue {
  valid = false;
  visible = {
    bvg: false,
    itac: false,
  };
  ircTooltip = false;
  networkAlert = false;
  loading = false;
  sameAsITAC = false;
  alert = false;
  openModalRegister = false;
  openModalReset = false;
  allowItacLogin = false;
  credentials: {
    username: string | null;
    password: string | null;
    rememberMe: boolean;
    passwordITAC: string | null;
  } = {
    username: null,
    password: null,
    rememberMe: false,
    passwordITAC: null,
  };
  openRegister(): void {
    this.openModalRegister = true;
  }
  openReset(): void {
    this.openModalReset = true;
  }
  // mounted() {
  //   const checkLastLogin = localStorage.getItem("bvg-last-login");
  //   if (checkLastLogin != null) {
  //     this.credentials.username = checkLastLogin;
  //   }
  // }
  async submitItac(): Promise<OauthTokenResponseRoot> {
    let itacLogin: OauthTokenResponseRoot | null = null;
    if (
      this.credentials.username == null ||
      this.credentials.password == null
    ) {
      return Promise.reject("Credentials can't be null");
    }
    if (this.sameAsITAC == true) {
      itacLogin = await loginHook
        .login(
          this.credentials.username,
          this.credentials.password,
          this.credentials.rememberMe,
          true
        )
        .catch((err) => {
          return Promise.reject(err);
        });
    } else {
      if (this.credentials.passwordITAC == null) {
        console.log("Null itac password");
        return Promise.reject("ITAC Password Null");
      }
      itacLogin = await loginHook
        .login(
          this.credentials.username,
          this.credentials.passwordITAC,
          this.credentials.rememberMe,
          true
        )
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    if (itacLogin == null) {
      return Promise.reject("HANDLE LOGIN BETTER - ITAC");
    }
    return Promise.resolve(itacLogin);
  }
  async login(): Promise<void> {
    try {
      this.loading = true;
      await this.loginPortal();
      if (
        (this.credentials.passwordITAC != null && this.sameAsITAC == false) ||
        (this.credentials.password != null && this.sameAsITAC == true)
      ) {
        console.log("------------ Logging into ITAC ----------");
        await this.loginIRC();
      } else {
        console.error("Not same or null password");
        Auth.setAuthItac(null);
        Auth.setLoginResponseItac(null);
      }
      const roles = await userRoleWs.GetUserRoles();
      Auth.setUserRoles(roles);
      await this.doPushNotifications();
      goToPage("dash.home");
      Promise.resolve();
    } catch (err) {
      // console.log("Unexpected error", err);
      if (typeof err === "string") {
        console.log("string error : ", err);
      } else if (axios.isAxiosError(err)) {
        // if(err.)
        if (err.response) {
          if (err.response.status === 401) {
            this.alert = true;
          }
        } else if (err.message == "Network Error") {
          this.networkAlert = true;
        }
        console.log("axios error : ", err.message);
      } else if (err instanceof Error) {
        if (err) console.log("Instance error : ", err);
      } else {
        console.log("Other error : ", err);
      }
      Auth.clearLoggedIn();
    } finally {
      this.loading = false;
    }
  }
  async submitPortal(): Promise<OauthTokenResponseRoot> {
    //non itac login
    if (
      this.credentials.username == null ||
      this.credentials.password == null
    ) {
      return Promise.reject("Credentials can't be null");
    }
    const portalLogin = await loginHook
      .login(
        this.credentials.username,
        this.credentials.password,
        this.credentials.rememberMe,
        false
      )
      .catch((err) => {
        return Promise.reject(err);
      });
    if (portalLogin == null) {
      return Promise.reject("PORTAL login null");
    }

    return Promise.resolve(portalLogin);
  }
  async loginIRC(): Promise<void> {
    if (
      this.credentials.username == null ||
      (this.credentials.password == null && this.sameAsITAC == true) ||
      (this.credentials.passwordITAC == null && this.sameAsITAC == false)
    ) {
      return Promise.reject("Credentials can't be null");
    }
    const itacLogin = await this.submitItac();
    const foundUserITAC = await loginHook.userAuth(
      this.credentials.username,
      itacLogin.accessToken,
      true
    );
    console.log("Verrify irc expirtay: ", itacLogin.VerifyExpiryDate());
    Auth.setAuthItac(foundUserITAC);
    Auth.setLoginResponseItac(itacLogin);
    if (itacLogin == null || foundUserITAC == null) {
      return Promise.reject("ITAC login | foundUser == null");
    }
    return Promise.resolve();
  }
  async loginPortal(): Promise<void> {
    if (
      this.credentials.username == null ||
      this.credentials.password == null
    ) {
      return Promise.reject("Credentials can't be null");
    }
    const portalLogin = await this.submitPortal();

    const foundUser = await loginHook.userAuth(
      this.credentials.username,
      portalLogin.accessToken,
      false
    );
    Auth.setLoginDetails(
      new OauthTokenPasswordRoot(this.credentials.username, "")
    );
    Auth.setAuth(foundUser);
    Auth.setLoginResponse(portalLogin);
    if (foundUser != null) {
      localStorage.setItem(
        "com.bvg.app.last.login",
        JSON.stringify({
          user: this.credentials.username,
          portal: foundUser.uniqueKey,
          itac:
            Auth.getUserAuthResponseITAC == null
              ? null
              : Auth.getUserAuthResponseITAC.uniqueKey,
        })
      );
    }

    return Promise.resolve();
  }
  async submitCreds(): Promise<void> {
    try {
      this.loading = true;
      if (
        this.credentials.username == null ||
        this.credentials.password == null
      ) {
        return Promise.reject("Credentials can't be null");
      }
      const portalLogin = await this.submitPortal();
      const itacLogin = await this.submitItac();

      if (portalLogin == null) {
        throw new Error("Undefined Portal");
      }
      let foundUserITAC = null;
      if (itacLogin != null) {
        foundUserITAC = await loginHook.userAuth(
          this.credentials.username,
          itacLogin.accessToken,
          true
        );
        if (foundUserITAC == null) {
          return Promise.reject("IRC Market User null with password");
        }
      } else {
        console.error("itacLogin is Null");
      }
      const foundUser = await loginHook.userAuth(
        this.credentials.username,
        portalLogin.accessToken,
        false
      );

      if (foundUser == null) {
        return Promise.reject("Portal didnt find user");
      }
      Auth.setLoginDetails(
        new OauthTokenPasswordRoot(this.credentials.username, "")
      );
      // console.log("Users found");
      Auth.setAuthItac(foundUserITAC);
      Auth.setAuth(foundUser);
      Auth.setLoginResponse(portalLogin);
      Auth.setLoginResponseItac(itacLogin);
      if (Auth.getUserAuthResponse != null) {
        // console.log("Set last logged in");
        localStorage.setItem(
          "com.bvg.app.last.login",
          JSON.stringify({
            user: this.credentials.username,
            portal: Auth.getUserAuthResponse.uniqueKey,
            itac:
              Auth.getUserAuthResponseITAC == null
                ? null
                : Auth.getUserAuthResponseITAC.uniqueKey,
          })
        );
      }
      const roles = await userRoleWs.GetUserRoles();
      Auth.setUserRoles(roles);
      await this.doPushNotifications();
      goToPage("dash.home");
      return Promise.resolve();
    } catch (err) {
      // console.log("Unexpected error", err);
      if (typeof err === "string") {
        console.log("string error : ", err);
      } else if (axios.isAxiosError(err)) {
        // if(err.)
        if (err.response) {
          if (err.response.status === 401) {
            this.alert = true;
          }
        }
        if (err.message == "Network Error") {
          this.networkAlert = true;
        }
        console.log("axios error : ", err.message);
      } else if (err instanceof Error) {
        if (err) console.log("Instance error : ", err);
      } else {
        console.log("Other error : ", err);
      }
    } finally {
      this.loading = false;
    }
  }

  async doPushNotifications(): Promise<void> {
    const devInfo = NotifModule.deviceInfo;
    if (devInfo == null)
      return Promise.reject("device info is null for firebase registration...");
    if (devInfo.platform == "android" || devInfo.platform == "ios") {
      console.log(
        "Mobile Notif",
        devInfo.platform,
        NotifModule.getFirebaseToken
      );
      if (NotifModule.getFirebaseToken != null) {
        const tokenReg = await notificationsWs
          .RegisterNotificationTag(
            NotifModule.getFirebaseToken,
            devInfo.platform
          )
          .catch((err) => {
            console.error("Couldnt register token on portal: ", err);
            return Promise.reject(err);
          });
        console.log(
          "Successfully registered with server: ",
          tokenReg,
          NotifModule.getFirebaseToken
        );
      } else {
        console.error(
          "Firebase token null when registering with portal",
          NotifModule.getFirebaseToken
        );
      }
    }
  }
  // async submitCredentials(): Promise<void> {
  //   try {
  //     this.loading = true;
  //     try {
  //       let itacLogin: OauthTokenResponseRoot | null = null;
  //       //non itac login
  //       const nonItacLogin = await loginHook
  //         .login(
  //           this.credentials.username,
  //           this.credentials.password,
  //           this.credentials.rememberMe,
  //           false
  //         )
  //         .catch((err) => {
  //           return Promise.reject(err);
  //         });
  //       if (nonItacLogin == null) {
  //         return Promise.reject("HANDLE LOGIN BETTER");
  //       }

  //       if (this.sameAsITAC == true) {
  //         itacLogin = await loginHook
  //           .login(
  //             this.credentials.username,
  //             this.credentials.password,
  //             this.credentials.rememberMe,
  //             true
  //           )
  //           .catch((err) => {
  //             return Promise.reject(err);
  //           });
  //       } else {
  //         itacLogin = await loginHook
  //           .login(
  //             this.credentials.username,
  //             this.credentials.passwordITAC,
  //             this.credentials.rememberMe,
  //             true
  //           )
  //           .catch((err) => {
  //             // return Promise.resolve(nonItacLogin);
  //             // console.log("Itac error: ", Object.keys(err));
  //             // if(err.data){
  //             //   return Promise.reject(err.data);
  //             // }
  //             return Promise.reject(err);
  //           });
  //       }
  //       if (itacLogin == null) {
  //         return Promise.reject("HANDLE LOGIN BETTER - ITAC");
  //       }

  //       const login = new OauthTokenPasswordRoot(this.credentials.username, "");

  //       const foundUser = await loginHook
  //         .userAuth(this.credentials.username, nonItacLogin.accessToken, false)
  //         .catch((err) => {
  //           return Promise.reject(err);
  //         });
  //       const foundUserITAC = await loginHook
  //         .userAuth(this.credentials.username, itacLogin.accessToken, true)
  //         .catch((err) => {
  //           return Promise.reject(err);
  //         });
  //       if (foundUserITAC == null || foundUser == null) {
  //         return Promise.reject("Either found user is null");
  //       }
  //       Auth.setAuthItac(foundUserITAC);
  //       Auth.setAuth(foundUser);
  //       Auth.setLoginResponse(nonItacLogin);
  //       Auth.setLoginResponseItac(itacLogin);
  //       Auth.setLoginDetails(login);
  //     } catch (err) {
  //       console.error("Error logging in: ", err);
  //       this.alert = true;
  //       return Promise.reject(err);
  //     }
  //     if (
  //       Auth.getUserAuthResponse != null &&
  //       Auth.getUserAuthResponseITAC != null
  //     ) {
  //       localStorage.setItem(
  //         "com.bvg.app.last.login",
  //         JSON.stringify({
  //           user: this.credentials.username,
  //           portal: Auth.getUserAuthResponse.uniqueKey,
  //           itac: Auth.getUserAuthResponseITAC.uniqueKey,
  //         })
  //       );
  //     }

  //     try {
  //       // }
  //       const devInfo = NotifModule.deviceInfo;
  //       if (devInfo == null)
  //         return Promise.reject(
  //           "device info is null for firebase registration..."
  //         );
  //       if (devInfo.platform == "android" || devInfo.platform == "ios") {
  //         console.log(
  //           "Mobile Notif",
  //           devInfo.platform,
  //           NotifModule.getFirebaseToken
  //         );
  //         if (NotifModule.getFirebaseToken != null) {
  //           const tokenReg = await notificationsWs
  //             .RegisterNotificationTag(
  //               NotifModule.getFirebaseToken,
  //               devInfo.platform
  //             )
  //             .catch((err) => {
  //               console.error("Couldnt register token on portal: ", err);
  //               return Promise.reject(err);
  //             });
  //           console.log(
  //             "Successfully registered with server: ",
  //             tokenReg,
  //             NotifModule.getFirebaseToken
  //           );
  //         } else {
  //           console.error(
  //             "Firebase token null when registering with portal",
  //             NotifModule.getFirebaseToken
  //           );
  //         }
  //       }
  //     } catch (err) {
  //       console.error("Error with push notifications: ", err);
  //       return Promise.reject(err);
  //     }

  //     goToPage("dash.home");
  //     return Promise.resolve();
  //   } catch (err) {
  //     this.alert = true;
  //     console.error("Error keys: ", err);
  //     // if (err.isAxiosError) {
  //     //   console.error("Error: ", err.toJSON());
  //     // } else {
  //     //   console.log("Error logging in: Main catch ", err);
  //     // }

  //     return Promise.reject(err);
  //   } finally {
  //     this.loading = false;
  //   }
  // }
}
