export class itacPositions {
  constructor({
    AccountId,
    AccountSubType,
    AccountType,
    ClientAccountDescription,
    ClientCode,
    ClientId,
    CloseNominalQuantity,
    ContractCode,
    ExternalAccountId,
    ExternalInstrumentId,
    InstrumentBase,
    InstrumentDisplayName,
    InstrumentExpiry,
    InstrumentType,
    IsPositionAccount,
    LongNominalQuantity,
    Market,
    OpenNominalQuantity,
    OptionType,
    PositionTimeStamp,
    PositionType,
    SequenceNumber,
    SettlementDate,
    ShortNominalQuantity,
    Strike,
    SubscriptionGroup,
    TradingMember,
    TradingMemberBranch,
    created_at,
    deleted_at,
    id,
    updated_at,
  }: {
    AccountId: number;
    AccountSubType: string;
    AccountType: string;
    ClientAccountDescription: string;
    ClientCode: string;
    ClientId: string;
    CloseNominalQuantity: string;
    ContractCode: string;
    ExternalAccountId: string;
    ExternalInstrumentId: string;
    InstrumentBase: string;
    InstrumentDisplayName: string;
    InstrumentExpiry: Date;
    InstrumentType: string;
    IsPositionAccount: boolean;
    LongNominalQuantity: string;
    Market: string;
    OpenNominalQuantity: string;
    OptionType: string;
    PositionTimeStamp: Date;
    PositionType: string;
    SequenceNumber: number;
    SettlementDate: Date;
    ShortNominalQuantity: string;
    Strike: string;
    SubscriptionGroup: number;
    TradingMember: string;
    TradingMemberBranch: string;
    created_at: Date;
    deleted_at: Date;
    id: number;
    updated_at: Date;
  }) {
    this.accountId = AccountId;
    this.accountSubType = AccountSubType;
    this.accountType = AccountType;
    this.clientAccountDescription = ClientAccountDescription;
    this.clientCode = ClientCode;
    this.clientId = ClientId;
    this.closeNominalQuantity = CloseNominalQuantity;
    this.contractCode = ContractCode;
    this.externalAccountId = ExternalAccountId;
    this.externalInstrumentId = ExternalInstrumentId;
    this.instrumentBase = InstrumentBase;
    this.instrumentDisplayName = InstrumentDisplayName;
    this.instrumentExpiry = InstrumentExpiry;
    this.instrumentType = InstrumentType;
    this.isPositionAccount = IsPositionAccount;
    this.longNominalQuantity = LongNominalQuantity;
    this.market = Market;
    this.openNominalQuantity = OpenNominalQuantity;
    this.optionType = OptionType;
    this.positionTimeStamp = PositionTimeStamp;
    this.positionType = PositionType;
    this.sequenceNumber = SequenceNumber;
    this.settlementDate = SettlementDate;
    this.shortNominalQuantity = ShortNominalQuantity;
    this.strike = Strike;
    this.subscriptionGroup = SubscriptionGroup;
    this.tradingMember = TradingMember;
    this.tradingMemberBranch = TradingMemberBranch;
    this.created_at = created_at;
    this.deleted_at = deleted_at;
    this.id = id;
    this.updated_at = updated_at;
  }
  public accountId: number;
  public accountSubType: string;
  public accountType: string;
  public clientAccountDescription: string;
  public clientCode: string;
  public clientId: string;
  public closeNominalQuantity: string;
  public contractCode: string;
  public externalAccountId: string;
  public externalInstrumentId: string;
  public instrumentBase: string;
  public instrumentDisplayName: string;
  public instrumentExpiry: Date;
  public instrumentType: string;
  public isPositionAccount: boolean;
  public longNominalQuantity: string;
  public market: string;
  public openNominalQuantity: string;
  public optionType: string;
  public positionTimeStamp: Date;
  public positionType: string;
  public sequenceNumber: number;
  public settlementDate: Date;
  public shortNominalQuantity: string;
  public strike: string;
  public subscriptionGroup: number;
  public tradingMember: string;
  public tradingMemberBranch: string;
  public created_at: Date;
  public deleted_at: Date;
  public id: number;
  public updated_at: Date;
}
