
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import { goToPage } from "@/scripts/navigation";
import Dash from "@/store/modules/Dash";
import { useLogin } from "@/scripts/login";
import NotificationSystem from "@/store/modules/NotificationSystem";
const loginHook = useLogin();

@Component
export default class Appbar extends Vue {
  promptDeleteAccount = false;
  flipDrawer(): void {
    // console.log("Flip drawer  to: ", !this.appDrawer);
    Dash.setAppDrawer(!this.appDrawer);
  }
  get appDrawer(): boolean {
    return Dash.getAppDrawer;
  }
  async deleteAccount() {
    try {
      const res = await loginHook.deleteAccount();
      this.$notifCreator.createSuccessNotification(
        "Successfully deleted account."
      );
      this.logout();
      console.log("Delete Res: ", res);
    } catch (err) {
      console.log("Deleting issue: ", err);
    }
    this.promptDeleteAccount = false;
  }
  title = "";
  username: string | undefined;
  get userDetails(): string | undefined {
    this.username = AuthModule.loginDetails?.userName;
    return this.username;
  }
  logout(): void {
    AuthModule.clearLoggedIn();
    goToPage("Login");
  }
  @Watch("$route.name", { immediate: true })
  routeChanged(newTitle: string): void {
    switch (newTitle) {
      case "dash.positions":
        this.title = "Positions";
        break;
      case "dash.home":
        this.title = "Home";
        break;
      case "dash.express":
        this.title = "Express";
        break;
      case "dash.express-new":
        this.title = "Express (NEW)";
        break;
      case "dash.currencies":
        this.title = "Currencies";
        break;
      case "dash.market-index":
        this.title = "Market Index";
        break;
      case "dash.weather":
        this.title = "Weather Stations Overview";
        break;
      case "dash.precipitation":
        this.title = "Precipitation";
        break;
      case "dash.mtm":
        this.title = "Mark to Market";
        break;
      case "dash.calculator":
        this.title = "Option Calculator";
        break;
      case "dash.notifications":
        this.title = "Notifications";
        break;
      case "dash.contact-us":
        this.title = "Contact Us";
        break;
      case "dash.commodity":
        this.title = "Commodities";
        break;
      case "dash.itac":
        this.title = "ITAC";
        break;
      case "dash.reports":
        this.title = "Reports";
        break;
      case "itac.express":
        this.title = "Express";
        break;
      case "itac.dlr":
        this.title = "Post-Trade";
        break;
      case "itac.express-hist":
        this.title = "Express Historical";
        break;
      case "itac.dlr-hist":
        this.title = "Post-Trade Historical";
        break;
      case "itac.instruments":
        this.title = "Instruments";
        break;
      default:
        this.title = newTitle;
        break;
    }
  }
}
