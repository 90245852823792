
import { Component, Vue, Watch } from "vue-property-decorator";
import { BreakdownWs } from "@/DataAccess/WebServices/AgriBase/BreakdownWs";
import { Breakdown as breakdown } from "@/models/RootObjects/Breakdown";
import BreakdownTable from "@/components/Agris/Express-New/BreakdownTable.vue";
import { ClearingWs } from "@/DataAccess/WebServices/AgriBase/ClearingWs";
import { Clearing as clearing } from "@/models/RootObjects/Clearing";
import Clearing from "@/components/Agris/Express-New/Clearing.vue";
import { DealsWs } from "@/DataAccess/WebServices/AgriBase/DealsWs";
import { Deal as deal } from "@/models/RootObjects/Deal";
import Deal from "@/components/Agris/Express-New/Deals.vue";
import { UserSubScriptionsWs } from "@/DataAccess/WebServices/AgriBase/UserSubscriptionsWs";
import ClientPicker from "@/components/Agris/Express-New/ClientPicker.vue";

const bd = new BreakdownWs();
const cl = new ClearingWs();
const dl = new DealsWs();
const us = new UserSubScriptionsWs();

@Component({ components: { ClientPicker, BreakdownTable, Clearing, Deal } })
export default class Express_New extends Vue {
  public tab = "";
  loading = false;

  handleSelectionUpdated() {
    console.log("Handle selection update in breakdown and clearing");
    if (this.$refs.breakdownComponent)
      (this.$refs.breakdownComponent as any).fetchData();
    if (this.$refs.clearingComponent)
      (this.$refs.clearingComponent as any).fetchData();
    if (this.$refs.dealsComponent)
      (this.$refs.dealsComponent as any).fetchData();
  }
}
