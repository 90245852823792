import { axiosInstance, axiosInstanceITAC } from "@/plugins/axios";
import Utils from "@/WebService/Rest/Utils";
import { UserAuthentication } from "@/models/RootObjects/UserAuthentication";

const utils = new Utils();

export class UserAuthenticationWs {
  public async FindUser(
    userName: string,
    accessToken: string,
    itac = false
  ): Promise<UserAuthentication | null> {
    // const uri = utils.BuildEndPoint("/api/user/get", [{ key: "email", value: userName }]);
    const uri = "/api/user/get";
    const base =
      (itac == false
        ? process.env["VUE_APP_SERVER_BASE"]
        : process.env["VUE_APP_SERVER_ITAC"]) + uri;
    console.log("My base ", base);

    const res = await (itac == false ? axiosInstance : axiosInstanceITAC)
      .get(base, {
        headers: {
          email: userName,
          Authorization: "Bearer " + accessToken,
        },
      })
      .catch((err) => {
        console.log("Error on FindUser", err);
        return Promise.reject(err);
      });
    console.log("Res for FindUser", res);
    const user = new UserAuthentication(
      res.data.uniqueKey,
      res.data.dealerCode,
      res.data.branchCode,
      res.data.user_settings
    );
    return Promise.resolve(user);
  }

  // public async ReAuthenticateUser() {
  //   const base = process.env["VUE_APP_SERVER_BASE"] + "/api/testAuthorisation";
  //   const res = await axiosInstance.get(base).catch((err) => {
  //     console.log("Error on ReauthenticateUser", err);
  //     return Promise.reject(err);
  //   });

  //   console.log("Res for reauthenticateUser", res);
  //   return Promise.resolve(res.data);
  // }
}
