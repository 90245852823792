export class UserAuthentication {
  constructor(
    uniqueKey: string,
    dealerCode: string,
    branchCode: string,
    userSettings: Record<string, boolean | string | number>
  ) {
    this.uniqueKey = uniqueKey;
    this.dealerCode = dealerCode;
    this.branchCode = branchCode;
    this.user_settings = userSettings;
  }

  public uniqueKey: string;
  public dealerCode: string;
  public branchCode: string;
  public user_settings: Record<string, boolean | string | number>;
}
